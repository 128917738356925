import React, { useEffect, useState } from 'react';
import {browserHistory, Link} from 'react-router'
import Button from "metabase/core/components/Button";
import LoadingAndErrorWrapper from "metabase/components/LoadingAndErrorWrapper";
import {t} from "ttag";

const errorText = t`Error of deleting table. `;
const successText = t`Removed successfully. `;

const defaultText = t`Removed with unknown error. `;

const checkMessage = (response: { message: string; status: string;}) => {
  switch (response.status) {
    case 'ERROR':
      return errorText + response.message;
    case 'OK':
      return successText + response.message;
    default:
      return defaultText + JSON.stringify(response?.status ? response.status : 'server error:' + ' ' + response.message);
  }
}

type TProps = {
  onShowToast: any;
}
const TableRemover = (
  { onShowToast }: TProps
) => {
  const history = browserHistory.getCurrentLocation()
  const [response, setResponse] = useState();
  const [error, setError] = useState();
  const [tableName, setTableName ] = useState([...history.pathname.split('/')].pop());
  const refButton = React.useRef<HTMLButtonElement>(null);

  const handleRemoveTable = async () => {
    const url =
      "api/database/table/" + tableName;

    await fetch(url, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json  '}
    })
      .then(res => res.json())
      .then(data => {
        setResponse(data);
      })
      .catch(error => {
        setError(error);
      });
  }

  useEffect(()=> {
    handleRemoveTable();
  }, []);

  useEffect(()=> {
    if (error || response) {
      if (refButton.current) {
        refButton.current.click();
        setTableName(undefined);
      }
      if (response) onShowToast({ message: checkMessage(response) })
      if (error) onShowToast({ message: JSON.stringify(error) })
    }
  }, [error, response])

  const handleLink = () => {
    browserHistory.push(history.pathname.split('/').splice(0,history.pathname.split('/').length - 1).join('/'));
  }
  return (
    <div>
      <Link
      to={history.pathname.split('/').splice(0,history.pathname.split('/').length - 1).join('/')}>
        <Button
          style={{display: 'none'}}
          onChange={handleLink}
          ref={refButton}
        >
          Удаление
        </Button>
      </Link>
      <LoadingAndErrorWrapper loading={true} error={error} noWrapper/>
    </div>
  );
}

export default TableRemover;
