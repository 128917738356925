import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
`;

export const InputWrapper = styled.div`
  flex: 1 1 auto;
  text-align: left;
`;

export const ToggleInputWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const SelectInputWrapper = styled.div`
  display: "flex";
  flex-basis: 0;
`;

export const LabelAutoSize = styled.span`
  font-weight: 700;
`;
