import type { MouseEvent, HTMLAttributes, Ref } from "react";
import { forwardRef, useCallback, useMemo } from "react";
import _ from "underscore";

import { getColorScale } from "metabase/lib/colors/scales";

import { ColorRangeItem, ColorRangeRoot } from "./ColorRange.styled";

export type ColorRangeAttributes = Omit<
  HTMLAttributes<HTMLDivElement>,
  "onSelect"
>;

export interface ColorRangeProps extends ColorRangeAttributes {
  colors: string[];
  sections?: number;
  isQuantile?: boolean;
  onSelect?: (newColors: string[]) => void;
  activeSection?: number;
  setActiveSection?: (section: number) => void;
  allowChooseColor?: boolean;
}

const ColorRange = forwardRef(function ColorRange(
  {
    colors,
    sections = 5,
    isQuantile = false,
    onClick,
    onSelect,
    activeSection,
    allowChooseColor,
    setActiveSection,
    ...props
  }: ColorRangeProps,
  ref: Ref<HTMLDivElement>,
) {
  const scale = useMemo(() => {
    return getColorScale([0, sections - 1], colors, isQuantile);
  }, [colors, sections, isQuantile]);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      onClick?.(event);
      onSelect?.(colors);
    },
    [colors, onClick, onSelect],
  );

  return (
    <ColorRangeRoot {...props} ref={ref} onClick={handleClick}>
      {_.range(0, sections).map(section => {
        if (!allowChooseColor) {
          return (
            <ColorRangeItem
              key={section}
              style={{
                backgroundColor: scale(section),
              }}
            />
          );
        }

        const gradientColorPosition = (section: number) =>
          section === 0 ? 0 : section / 2;
        const gradientColorPositionKey =
          section % 2 === 0 ? gradientColorPosition(section) : null;
        const isActive = gradientColorPositionKey === activeSection;

        return (
          <ColorRangeItem
            key={section}
            isSelected={isActive}
            style={{
              backgroundColor: scale(section),
              cursor: gradientColorPositionKey ? "pointer" : "default",
            }}
            onClick={() => {
              if (gradientColorPositionKey || gradientColorPositionKey === 0) {
                setActiveSection && setActiveSection(gradientColorPositionKey);
              }
            }}
          />
        );
      })}
    </ColorRangeRoot>
  );
});

// eslint-disable-next-line import/no-default-export -- deprecated usage
export default ColorRange;
