function formatDateStringWithRemoveUnusedDimensionsAndRounding(
  inputString: string,
) {
  const regex =
    /^(\d+ years )?(\d+ mons )?(\d+ days )?(\d+ hours )?(\d+ mins )?(\d+([.,]\d+)? secs)?$/;

  if (!regex.test(inputString)) {
    return inputString;
  }

  let removeNextDimension = false;
  let resultString = "";
  const inputStringValues = inputString.split(" ");

  for (let i = 0; i <= inputStringValues.length - 1; i++) {
    const string = inputStringValues[i];
    const number = Number(string);
    const isStringCanBeConvertedToNumber = Number.isNaN(number);

    if (removeNextDimension) {
      removeNextDimension = false;
      continue;
    } else if (Number(string) !== 0) {
      if (!isStringCanBeConvertedToNumber) {
        resultString += Math.round(number) + " ";
      } else {
        resultString += string + " ";
      }
    } else {
      removeNextDimension = true;
    }
  }

  return resultString.trim();
}

export { formatDateStringWithRemoveUnusedDimensionsAndRounding };
