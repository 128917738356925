import { t } from "ttag";

import type { WidgetSettings } from "metabase-types/api/widgets/widget";

type GraphDataCommonSettingsKeys = "legend.colors" | "graph.legend_position";

type GraphDataCommonSettings = WidgetSettings<GraphDataCommonSettingsKeys>;

export const GRAPH_DATA_COMMON_SETTINGS: GraphDataCommonSettings = {
  "legend.colors": {
    section: t`Display`,
    title: t`Colored legend`,
    widget: "toggle",
    default: false,
    inline: true,
  },

  "graph.legend_position": {
    section: t`Display`,
    title: t`Legend position`,
    widget: "segmentedControl",
    props: {
      options: [
        { name: t`Top`, value: "top" },
        { name: t`Bottom`, value: "bottom" },
        { name: t`Right`, value: "right" },
        { name: t`Left`, value: "left" },
      ],
    },
    default: "top",
  },
};

type GraphAxisCommonSettingsKeys =
  | "graph.x_axis.axis_enabled"
  | "graph.y_axis.scale"
  | "graph.y_axis.auto_range"
  | "graph.y_axis.labels_enabled"
  | "graph.y_axis.axis_enabled"
  | "graph.x_axis.labels_enabled";

export type GraphAxisCommonSettings =
  WidgetSettings<GraphAxisCommonSettingsKeys>;

export const GRAPH_AXIS_COMMON_SETTINGS: GraphAxisCommonSettings = {
  "graph.x_axis.axis_enabled": {
    section: t`Axes`,
    group: t`X-axis`,
    title: t`Show lines and marks`,
    index: 3,
    widget: "select",
    props: {
      options: [
        { name: t`Hide`, value: false },
        { name: t`Show`, value: true },
        { name: t`Compact`, value: "compact" },
        { name: t`Rotate 45°`, value: "rotate-45" },
        { name: t`Rotate 90°`, value: "rotate-90" },
      ],
    },
    default: true,
  },
  "graph.y_axis.scale": {
    section: t`Axes`,
    title: t`Scale`,
    index: 7,
    group: t`Y-axis`,
    widget: "select",
    default: "linear",
    props: {
      options: [
        { name: t`Linear`, value: "linear" },
        { name: t`Power`, value: "pow" },
        { name: t`Log`, value: "log" },
      ],
    },
  },

  "graph.y_axis.axis_enabled": {
    section: t`Axes`,
    title: t`Show lines and marks`,
    index: 8,
    group: t`Y-axis`,
    widget: "select",
    props: {
      options: [
        { name: t`Hide`, value: false },
        { name: t`Show`, value: true },
      ],
    },
    default: true,
  },

  "graph.y_axis.auto_range": {
    section: t`Axes`,
    group: t`Y-axis`,
    index: 4,
    title: t`Auto y-axis range`,
    inline: true,
    widget: "toggle",
    default: true,
  },

  "graph.x_axis.labels_enabled": {
    section: t`Axes`,
    group: t`X-axis`,
    index: 1,
    title: t`Show label`,
    inline: true,
    widget: "toggle",
    default: true,
  },

  "graph.y_axis.labels_enabled": {
    section: t`Axes`,
    title: t`Show label`,
    index: 1,
    group: t`Y-axis`,
    widget: "toggle",
    inline: true,
    default: true,
  },
};

type GraphDisplayValuesCommonSettingsKeys =
  | "graph.label_value_frequency"
  | "graph.label_value_formatting"
  | "graph.show_values";

export type GraphDisplayValuesCommonSettings =
  WidgetSettings<GraphDisplayValuesCommonSettingsKeys>;

export const GRAPH_DISPLAY_VALUES_COMMON_SETTINGS: GraphDisplayValuesCommonSettings =
  {
    "graph.label_value_frequency": {
      section: t`Display`,
      title: t`Values to show`,
      widget: "segmentedControl",
      getHidden: (series, vizSettings) =>
        vizSettings["graph.show_values"] !== true ||
        vizSettings["stackable.stack_type"] === "normalized",
      props: {
        options: [
          { name: t`Some`, value: "fit" },
          { name: t`All`, value: "all" },
        ],
      },
      default: "fit",
      readDependencies: ["graph.show_values"],
    },
    "graph.label_value_formatting": {
      section: t`Display`,
      title: t`Auto formatting`,
      widget: "segmentedControl",
      getHidden: (series, vizSettings) =>
        vizSettings["graph.show_values"] !== true ||
        vizSettings["stackable.stack_type"] === "normalized",
      props: {
        options: [
          { name: t`Auto`, value: "auto" },
          { name: t`Compact`, value: "compact" },
          { name: t`Full`, value: "full" },
        ],
      },
      default: "auto",
      readDependencies: ["graph.show_values"],
    },

    "graph.show_values": {
      section: t`Display`,
      title: t`Show values on data points`,
      widget: "toggle",
      getHidden: (series, vizSettings) =>
        vizSettings["stackable.stack_type"] === "normalized",
      default: false,
      inline: true,
      marginBottom: "1rem",
    },
  };

type GraphGoalSettingsKeys =
  | "graph.show_goal"
  | "graph.goal_value"
  | "graph.goal_label";

export type GraphGoalSettings = WidgetSettings<GraphGoalSettingsKeys>;

export const GRAPH_GOAL_SETTINGS: GraphGoalSettings = {
  "graph.show_goal": {
    section: t`Display`,
    title: t`Goal line`,
    widget: "toggle",
    default: false,
    inline: true,
    marginBottom: "1rem",
  },
  "graph.goal_value": {
    section: t`Display`,
    title: t`Goal value`,
    widget: "number",
    default: 0,
    getHidden: (_series, vizSettings) =>
      vizSettings["graph.show_goal"] !== true,
    readDependencies: ["graph.show_goal"],
  },
  "graph.goal_label": {
    section: t`Display`,
    title: t`Goal label`,
    widget: "input",
    default: t`Goal`,
    getHidden: (_series, vizSettings) =>
      vizSettings["graph.show_goal"] !== true,
    readDependencies: ["graph.show_goal"],
  },
};

export type GraphSettings =
  | GraphAxisCommonSettings
  | GraphDisplayValuesCommonSettings
  | GraphDataCommonSettings
  | GraphGoalSettings;

export const GRAPH_SETTINGS = {
  ...GRAPH_DATA_COMMON_SETTINGS,
  ...GRAPH_AXIS_COMMON_SETTINGS,
  ...GRAPH_GOAL_SETTINGS,
  ...GRAPH_DISPLAY_VALUES_COMMON_SETTINGS,
};
