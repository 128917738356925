import styled from "@emotion/styled";

import { Form } from "metabase/forms";
import { color } from "metabase/lib/colors";

export const OpenIdForm = styled(Form)({
  margin: "0 1rem",
  maxWidth: "32.5rem",
});

export const OpenIdFormHeader = styled("h2")({
  marginTop: "1rem",
});

export const OpenIdFormCaption = styled("p")({
  color: color("text-medium"),
});
