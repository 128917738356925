import React, { useState, useRef, DragEvent, ChangeEvent } from "react";
import {
  DeleteButton,
  ImageInputContainer,
  PreviewImage,
  UploadButton,
  Wrapper,
} from "./ChartSettingsImageInput.styled";

interface Props {
  value: string;
  onChange: (val: string | null) => void;
}

const ChartSettingsImageInput: React.FC<Props> = ({ value, onChange }) => {
  const [isDragging, setIsDragging] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isImageUploaded, setIsImageUploaded] = useState(value || false);

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    const file = e.dataTransfer.files[0];

    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        onChange(reader.result as string);
        setIsImageUploaded(true);
        setIsDragging(false);
      };
      reader.readAsDataURL(file);
    } else {
      setIsDragging(false);
    }
  };

  const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        onChange(reader.result as string);
        setIsImageUploaded(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    onChange(null);
    setIsImageUploaded(false);
  };

  return (
    <Wrapper>
      <ImageInputContainer
        className={`${isDragging ? "dragging" : ""}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {isImageUploaded && (
          <PreviewImage style={{ backgroundImage: `url(${value})` }}>
            <DeleteButton onClick={handleRemoveImage}>X</DeleteButton>
          </PreviewImage>
        )}
        <>
          {!isImageUploaded && (
            <span>Перетащите изображение в эту область</span>
          )}
          <input
            type="file"
            accept="image/*"
            onChange={handleFileInputChange}
            ref={inputRef}
            style={{ display: "none" }}
          />
        </>
      </ImageInputContainer>
      <UploadButton onClick={() => inputRef.current?.click()}>
        Загрузить изображение
      </UploadButton>
    </Wrapper>
  );
};

// eslint-disable-next-line import/no-default-export
export default ChartSettingsImageInput;
