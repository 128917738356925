/* eslint-disable react/prop-types */
import cx from "classnames";
import PropTypes from "prop-types";
import { createRef, forwardRef, Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { Grid, ScrollSync } from "react-virtualized";
import { t } from "ttag";
import _ from "underscore";

import "./TableInteractive.css";

import ExplicitSize from "metabase/components/ExplicitSize";
import FieldInfoPopover from "metabase/components/MetadataInfo/FieldInfoPopover";
import Button from "metabase/core/components/Button";
import { Ellipsified } from "metabase/core/components/Ellipsified";
import ExternalLink from "metabase/core/components/ExternalLink";
import Tooltip from "metabase/core/components/Tooltip";
import { getScrollBarSize } from "metabase/lib/dom";
import { formatValue } from "metabase/lib/formatting";
import { zoomInRow } from "metabase/query_builder/actions";
import {
  getRowIndexToPKMap,
  getQueryBuilderMode,
} from "metabase/query_builder/selectors";
import { EmotionCacheProvider } from "metabase/styled-components/components/EmotionCacheProvider";
import { Icon, DelayGroup } from "metabase/ui";
import {
  getTableCellClickedObject,
  getTableHeaderClickedObject,
  getTableClickedObjectRowData,
  isColumnRightAligned,
} from "metabase/visualizations/lib/table";
import { getColumnExtent } from "metabase/visualizations/lib/utils";
import { isAdHocModelQuestionCard } from "metabase-lib/metadata/utils/models";
import { isID, isPK, isFK } from "metabase-lib/types/utils/isa";
import { memoizeClass } from "metabase-lib/utils";
import { formatDateStringWithRemoveUnusedDimensionsAndRounding } from "metabase-lib/utils/functions";

import MiniBar from "../MiniBar";

import {
  TableDraggable,
  ExpandButton,
  HeaderCell,
  ResizeHandle,
  CellBodyWrapper,
  TableCellWrapper,
  TableInteractiveRoot,
} from "./TableInteractive.styled";

// approximately 120 chars
const TRUNCATE_WIDTH = 780;

const ROW_HEIGHT = 36;
const SIDEBAR_WIDTH = 38;
export const CELL_DEFAULT_INDENT = 10;
export const TITILE_DEFAULT_INDENT = 7;

const MIN_COLUMN_WIDTH = ROW_HEIGHT;
const RESIZE_HANDLE_WIDTH = 5;
// if header is dragged fewer than than this number of pixels we consider it a click instead of a drag
const HEADER_DRAG_THRESHOLD = 5;

// HACK: used to get react-draggable to reset after a drag
let DRAG_COUNTER = 0;

function pickRowsToMeasure(rows, columnIndex, count = 10) {
  const rowIndexes = [];
  // measure up to 10 non-nil cells
  for (
    let rowIndex = 0;
    rowIndex < rows.length && rowIndexes.length < count;
    rowIndex++
  ) {
    if (rows[rowIndex][columnIndex] != null) {
      rowIndexes.push(rowIndex);
    }
  }
  return rowIndexes;
}

const mapStateToProps = state => ({
  queryBuilderMode: getQueryBuilderMode(state),
  rowIndexToPkMap: getRowIndexToPKMap(state),
});

const mapDispatchToProps = dispatch => ({
  onZoomRow: objectId => dispatch(zoomInRow({ objectId })),
});

class TableInteractive extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnIsExpanded: [],
      columnWidths: [],
      contentWidths: null,
      showDetailShortcut: true,
      selectedRowIndex: null,
    };
    this.columnHasResized = {};
    this.headerRefs = [];
    this.detailShortcutRef = createRef();
    window.METABASE_TABLE = this;
  }

  static propTypes = {
    data: PropTypes.object.isRequired,
    isPivoted: PropTypes.bool.isRequired,
    sort: PropTypes.array,
  };

  static defaultProps = {
    isPivoted: false,
    hasMetadataPopovers: true,
    renderTableHeaderWrapper: (children, style) => (
      <div style={style} className="cellData">
        {children}
      </div>
    ),
    renderTableCellWrapper: (children, style) => {
      return (
        <TableCellWrapper
          style={{ ...style, width: "100%" }}
          linkColor={style.color}
          className={cx({ cellData: children != null && children !== "" })}
        >
          {children}
        </TableCellWrapper>
      );
    },
  };

  UNSAFE_componentWillMount() {
    // for measuring cells:
    this._div = document.createElement("div");
    this._div.className = "TableInteractive";
    this._div.style.display = "inline-block";
    this._div.style.position = "absolute";
    this._div.style.visibility = "hidden";
    this._div.style.zIndex = "-1";
    document.body.appendChild(this._div);

    this._measure();
    this._findIDColumn(this.props.data, this.props.isPivoted);
    this._showDetailShortcut(this.props.data, this.props.isPivoted);
  }

  componentWillUnmount() {
    if (this._div && this._div.parentNode) {
      this._div.parentNode.removeChild(this._div);
    }
    document.removeEventListener("keydown", this.onKeyDown);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { card, data } = this.props;
    const { card: nextCard, data: nextData } = newProps;

    const isDataChange =
      data && nextData && !_.isEqual(data.cols, nextData.cols);
    const isDatasetStatusChange =
      isAdHocModelQuestionCard(nextCard, card) ||
      isAdHocModelQuestionCard(card, nextCard);

    if (isDataChange && !isDatasetStatusChange) {
      this.resetColumnWidths();
    }

    // remeasure columns if the column settings change, e.x. turning on/off mini bar charts
    const oldColSettings = this._getColumnSettings(this.props);
    const newColSettings = this._getColumnSettings(newProps);
    if (!_.isEqual(oldColSettings, newColSettings)) {
      this.remeasureColumnWidths();
    }

    if (isDataChange) {
      this._findIDColumn(nextData, newProps.isPivoted);
      this._showDetailShortcut(this.props.data, this.props.isPivoted);
    }
  }

  _findIDColumn = (data, isPivoted = false) => {
    const hasManyPKColumns = data.cols.filter(isPK).length > 1;

    const pkIndex =
      isPivoted || hasManyPKColumns ? -1 : data.cols.findIndex(isPK);

    this.setState({
      IDColumnIndex: pkIndex === -1 ? null : pkIndex,
      IDColumn: pkIndex === -1 ? null : data.cols[pkIndex],
    });
    document.addEventListener("keydown", this.onKeyDown);
  };

  _showDetailShortcut = (data, isPivoted) => {
    const hasAggregation = data.cols.some(
      column => column.source === "aggregation",
    );
    const isNotebookPreview = this.props.queryBuilderMode === "notebook";
    const newShowDetailState = !(
      isPivoted ||
      hasAggregation ||
      isNotebookPreview
    );

    if (newShowDetailState !== this.state.showDetailShortcut) {
      this.setState({
        showDetailShortcut: newShowDetailState,
      });
      this.recomputeColumnSizes();
    }
  };

  _getColumnSettings(props) {
    return props.data && props.data.cols.map(col => props.settings.column(col));
  }

  shouldComponentUpdate(nextProps, nextState) {
    const PROP_KEYS = [
      "width",
      "height",
      "settings",
      "data",
      "clicked",
      "renderTableHeaderWrapper",
      "scrollToColumn",
    ];
    // compare specific props and state to determine if we should re-render
    return (
      !_.isEqual(
        _.pick(this.props, ...PROP_KEYS),
        _.pick(nextProps, ...PROP_KEYS),
      ) || !_.isEqual(this.state, nextState)
    );
  }

  componentDidUpdate(prevProps) {
    if (
      !this.state.contentWidths ||
      prevProps.renderTableHeaderWrapper !== this.props.renderTableHeaderWrapper
    ) {
      this._measure();
    } else if (this.props.onContentWidthChange) {
      const total = this.state.columnWidths.reduce((sum, width) => sum + width);
      if (this._totalContentWidth !== total) {
        this.props.onContentWidthChange(total, this.state.columnWidths);
        this._totalContentWidth = total;
      }
    }
  }

  remeasureColumnWidths() {
    this.setState({
      columnWidths: [],
      contentWidths: null,
      columnIsExpanded: [],
    });
    this.columnHasResized = {};
  }

  resetColumnWidths() {
    this.remeasureColumnWidths();
    this.props.onUpdateVisualizationSettings({
      "table.column_widths": undefined,
    });
  }

  _measure() {
    const {
      data: { cols, rows },
    } = this.props;

    ReactDOM.render(
      <EmotionCacheProvider>
        <div style={{ display: "flex" }}>
          {cols.map((column, columnIndex) => (
            <div className="fake-column" key={"column-" + columnIndex}>
              {this.tableHeaderRenderer({
                columnIndex,
                rowIndex: 0,
                key: "header",
                style: {},
                isVirtual: true,
              })}
              {pickRowsToMeasure(rows, columnIndex).map(rowIndex =>
                this.cellRenderer({
                  rowIndex,
                  columnIndex,
                  key: "row-" + rowIndex,
                  style: {},
                }),
              )}
            </div>
          ))}
        </div>
      </EmotionCacheProvider>,
      this._div,
      () => {
        const contentWidths = [].map.call(
          this._div.getElementsByClassName("fake-column"),
          columnElement => columnElement.offsetWidth,
        );

        const columnWidths = cols.map((col, index) => {
          if (this.columnNeedsResize) {
            if (
              this.columnNeedsResize[index] &&
              !this.columnHasResized[index]
            ) {
              this.columnHasResized[index] = true;
              return contentWidths[index] + 1; // + 1 to make sure it doen't wrap?
            } else if (this.state.columnWidths[index]) {
              return this.state.columnWidths[index];
            } else {
              return 0;
            }
          } else {
            return contentWidths[index] + 1;
          }
        });

        // Doing this on next tick makes sure it actually gets removed on initial measure
        setTimeout(() => {
          ReactDOM.unmountComponentAtNode(this._div);
        }, 0);

        delete this.columnNeedsResize;

        this.setState({ contentWidths, columnWidths }, this.recomputeGridSize);
      },
    );
  }

  recomputeGridSize = () => {
    if (this.header && this.grid) {
      this.header.recomputeGridSize();
      this.grid.recomputeGridSize();
      if (this.pinnedGrid) {
        this.pinnedGrid.recomputeGridSize();
      }
    }
  };

  recomputeColumnSizes = _.debounce(() => {
    this.setState({ contentWidths: null });
  }, 100);

  onCellResize(columnIndex) {
    this.columnNeedsResize = this.columnNeedsResize || {};
    this.columnNeedsResize[columnIndex] = true;
    this.recomputeColumnSizes();
  }

  onColumnResize(columnIndex, width) {
    const { settings } = this.props;
    const columnWidthsSetting = settings["table.column_widths"]
      ? settings["table.column_widths"].slice()
      : [];
    columnWidthsSetting[columnIndex] = Math.max(MIN_COLUMN_WIDTH, width);
    this.props.onUpdateVisualizationSettings({
      "table.column_widths": columnWidthsSetting,
    });
    setTimeout(() => this.recomputeGridSize(), 1);
  }

  onColumnReorder(columnIndex, newColumnIndex) {
    const { settings, onUpdateVisualizationSettings } = this.props;
    const columns = settings["table.columns"].slice(); // copy since splice mutates

    const enabledColumns = columns
      .map((c, index) => ({ ...c, index }))
      .filter(c => c.enabled);

    const adjustedColumnIndex = enabledColumns[columnIndex].index;
    const adjustedNewColumnIndex = enabledColumns[newColumnIndex].index;

    columns.splice(
      adjustedNewColumnIndex,
      0,
      columns.splice(adjustedColumnIndex, 1)[0],
    );
    onUpdateVisualizationSettings({
      "table.columns": columns,
    });
  }

  onVisualizationClick(clicked, element, rowIndex) {
    if (rowIndex || rowIndex === 0) {
      this.setState({ selectedRowIndex: rowIndex });
    }
    const { onVisualizationClick } = this.props;
    if (this.visualizationIsClickable(clicked)) {
      onVisualizationClick({ ...clicked, element });
    }
  }

  getCellClickedObject(rowIndex, columnIndex) {
    try {
      return this._getCellClickedObjectCached(
        this.props.data,
        this.props.settings,
        rowIndex,
        columnIndex,
        this.props.isPivoted,
        this.props.series,
      );
    } catch (e) {
      console.error(e);
    }
  }
  // NOTE: all arguments must be passed to the memoized method, not taken from this.props etc
  _getCellClickedObjectCached(
    data,
    settings,
    rowIndex,
    columnIndex,
    isPivoted,
    series,
  ) {
    const clickedRowData = getTableClickedObjectRowData(
      series,
      rowIndex,
      columnIndex,
      isPivoted,
      data,
    );

    return getTableCellClickedObject(
      data,
      settings,
      rowIndex,
      columnIndex,
      isPivoted,
      clickedRowData,
    );
  }

  getHeaderClickedObject(data, columnIndex, isPivoted) {
    try {
      return getTableHeaderClickedObject(data, columnIndex, isPivoted);
    } catch (e) {
      console.error(e);
    }
  }

  visualizationIsClickable(clicked) {
    try {
      const { onVisualizationClick, visualizationIsClickable } = this.props;
      const { dragColIndex } = this.state;
      if (
        // don't bother calling if we're dragging, but do it for headers to show isSortable
        (dragColIndex == null || (clicked && clicked.value === undefined)) &&
        onVisualizationClick &&
        visualizationIsClickable &&
        clicked
      ) {
        return this._visualizationIsClickableCached(
          visualizationIsClickable,
          clicked,
        );
      }
    } catch (e) {
      console.error(e);
    }
  }
  // NOTE: all arguments must be passed to the memoized method, not taken from this.props etc
  _visualizationIsClickableCached(visualizationIsClickable, clicked) {
    return visualizationIsClickable(clicked);
  }

  // NOTE: all arguments must be passed to the memoized method, not taken from this.props etc
  getCellBackgroundColor(settings, value, rowIndex, columnName) {
    try {
      return settings["table._cell_color_getter"](value, rowIndex, columnName);
    } catch (e) {
      console.error(e);
    }
  }

  getCellTextColor(settings, value, rowIndex, columnName) {
    try {
      return settings["table._text_color_getter"](value, rowIndex, columnName);
    } catch (e) {
      console.error(e);
    }
  }

  getCellFontStyle(settings, value, rowIndex, columnName) {
    try {
      return settings["table._cell_font_style_getter"](
        value,
        rowIndex,
        columnName,
      );
    } catch (e) {
      console.error(e);
    }
  }

  // NOTE: all arguments must be passed to the memoized method, not taken from this.props etc
  getCellFormattedValue({
    value,
    columnSettings,
    clicked,
    cellHeight,
    cellImageSize,
  }) {
    try {
      return formatValue(value, {
        ...columnSettings,
        type: "cell",
        jsx: true,
        rich: true,
        clicked: clicked,
        cellHeight,
        cellImageSize,
      });
    } catch (e) {
      console.error(e);
    }
  }

  pkClick = rowIndex => () => {
    let objectId;

    if (this.state.IDColumn) {
      objectId = this.props.data.rows[rowIndex][this.state.IDColumnIndex];
    } else {
      objectId = this.props.rowIndexToPkMap[rowIndex] ?? rowIndex;
    }

    this.props.onZoomRow(objectId);
  };

  onKeyDown = event => {
    const detailEl = this.detailShortcutRef.current;
    const visibleDetailButton =
      !!detailEl && Array.from(detailEl.classList).includes("show") && detailEl;
    const canViewRowDetail = !this.props.isPivoted && !!visibleDetailButton;

    if (event.key === "Enter" && canViewRowDetail) {
      const hoveredRowIndex = Number(detailEl.dataset.showDetailRowindex);
      this.pkClick(hoveredRowIndex)(event);
    }
  };

  cellRenderer = ({ key, style, rowIndex, columnIndex, cellHeight, isScrolling}) => {
    const { data, settings } = this.props;
    const { dragColIndex, showDetailShortcut } = this.state;
    const { rows, cols } = data;

    const column = cols[columnIndex];
    const row = rows[rowIndex];
    const value = row[columnIndex];

    const columnSettings = settings.column(column);
    const clicked = this.getCellClickedObject(rowIndex, columnIndex);

    const cellIndentVertical = settings["table.cell_auto_indent"]
      ? CELL_DEFAULT_INDENT
      : settings["table.cell_indent_vertical"];

    const cellData = columnSettings["show_mini_bar"] ? (
      <MiniBar
        value={value}
        options={columnSettings}
        extent={getColumnExtent(data.cols, data.rows, columnIndex)}
        cellHeight={cellHeight}
      />
    ) : (
      this.getCellFormattedValue({
        value,
        columnSettings,
        clicked,
        cellHeight: cellHeight - cellIndentVertical,
        cellImageSize: settings["table.cell_image_size"],
      })
      /* using formatValue instead of <Value> here for performance. The later wraps in an extra <span> */
    );

    const isLink = cellData && cellData.type === ExternalLink;
    const isClickable = !isLink && !isScrolling;
    const backgroundColor = this.getCellBackgroundColor(
      settings,
      value,
      rowIndex,
      column.name,
    );
    const isCollapsed = this.isColumnWidthTruncated(columnIndex);

    const isCellBackgroundTransparent = settings["table.cell_transparent"];

    const isOddRow = rowIndex % 2 === 0;
    const oddRowsBackgroundColor = settings["table.cell_odd_background_color"];
    const eventRowsBackgroundColor =
      settings["table.cell_even_background_color"];

    const linkColor = settings["table.cell_link_color"];
    const conditionalTextColor = this.getCellTextColor(
      settings,
      value,
      rowIndex,
      column.name,
    );

    const conditionalFontStyle = this.getCellFontStyle(
      settings,
      value,
      rowIndex,
      column.name,
    );

    const settingsTextColor = isLink
      ? linkColor
      : settings["table.cell_text_color"];
    const textColor = conditionalTextColor || settingsTextColor;

    const cellFontSize = settings["table.cell_font_size"];

    const cellFontStyle = conditionalFontStyle?.["font_italic"]
      ? "italic"
      : settings["table.cell_font_italic"]
      ? "italic"
      : "normal";

    const cellFontWeight = conditionalFontStyle?.["font_bold"]
      ? "bold"
      : settings["table.cell_font_bold"]
      ? "bold"
      : "normal";

    const fontStyle = {
      fontSize: cellFontSize,
      fontStyle: cellFontStyle,
      fontWeight: cellFontWeight,
    };

    const cellIndentHorizontal = settings["table.cell_auto_indent"]
      ? CELL_DEFAULT_INDENT
      : settings["table.cell_indent_left"];

    const backgroundColorOnHover =
      settings["table.cell_background_color_hover"];
    const isHighlightClickedRow = settings["table.toggle_clicked_row_higlight"];
    const clickedRowColor = settings["table.row_clicked_color"];

    const cellHorizontalAligment = settings["table.cell_horizontal_alignment"];
    const cellVerticalAligment = settings["table.cell_vertical_alignment"];

    const cellBorderColor = settings["table.grid_color"];

    const isCellAutoHeiht = settings["table.cell_auto_height"];

    let rowBackgroundColor;
    if (rowIndex === this.state.selectedRowIndex && isHighlightClickedRow) {
      rowBackgroundColor = clickedRowColor;
    } else if (backgroundColor) {
      rowBackgroundColor = backgroundColor;
    } else if (isCellBackgroundTransparent) {
      rowBackgroundColor = "transparent";
    } else {
      rowBackgroundColor = isOddRow
        ? oddRowsBackgroundColor
        : eventRowsBackgroundColor;
    }

    const handleClick = e => {
      if (!isClickable || !this.visualizationIsClickable(clicked)) {
        return;
      }
      this.onVisualizationClick(clicked, e.currentTarget, rowIndex);
    };

    const handleKeyUp = e => {
      if (!isClickable || !this.visualizationIsClickable(clicked)) {
        return;
      }
      if (e.key === "Enter") {
        this.onVisualizationClick(clicked, e.currentTarget);
      }
    };

    return (
      <CellBodyWrapper
        $backgroundColor={rowBackgroundColor}
        $backgroundHoverColor={backgroundColorOnHover}
        key={key}
        role="gridcell"
        style={{
          ...style,
          height: cellHeight,
          // use computed left if dragging
          left: this.getColumnLeft(style, columnIndex),
          // add a transition while dragging column
          transition: dragColIndex != null ? "left 200ms" : null,
          alignItems: cellVerticalAligment,
          justifyContent: cellHorizontalAligment,
          paddingLeft: cellIndentHorizontal,
          paddingTop: isCellAutoHeiht ? 0 : cellIndentVertical,
          pointerEvents: "all",
          borderBottom: `1px solid ${cellBorderColor}`,
        }}
        className={cx(
          "TableInteractive-cellWrapper text-dark hover-parent hover--visibility",
          {
            "TableInteractive-cellWrapper--firstColumn": columnIndex === 0,
            padLeft: columnIndex === 0 && !showDetailShortcut,
            "TableInteractive-cellWrapper--lastColumn":
              columnIndex === cols.length - 1,
            "TableInteractive-emptyCell": value == null,
            "cursor-pointer": isClickable,
            "justify-end": isColumnRightAligned(column),
            "Table-ID": value != null && isID(column),
            "Table-FK": value != null && isFK(column),
            link: isClickable && isID(column),
          },
        )}
        onClick={handleClick}
        onKeyUp={handleKeyUp}
        onMouseEnter={
          showDetailShortcut ? e => this.handleHoverRow(e, rowIndex) : undefined
        }
        onMouseLeave={
          showDetailShortcut ? e => this.handleLeaveRow() : undefined
        }
        tabIndex="0"
      >
        {this.props.renderTableCellWrapper(
          typeof cellData === "string"
            ? formatDateStringWithRemoveUnusedDimensionsAndRounding(cellData)
            : cellData,
          {
            ...fontStyle,
            color: textColor,
            // margin: `${cellIndentVertical}px 0 0 ${cellIndentHorizontal}px`,
          },
        )}
        {isCollapsed && (
          <ExpandButton
            data-testid="expand-column"
            className="hover-child"
            small
            borderless
            iconSize={10}
            icon="ellipsis"
            onlyIcon
            onClick={e => this.handleExpandButtonClick(e, columnIndex)}
          />
        )}
      </CellBodyWrapper>
    );
  };

  handleExpandButtonClick = (e, columnIndex) => {
    e.stopPropagation();
    this.handleExpandColumn(columnIndex);
  };

  getDragColNewIndex(data) {
    const { columnPositions, dragColNewIndex, dragColStyle } = this.state;
    if (dragColStyle) {
      if (data.x < 0) {
        const left = dragColStyle.left + data.x;
        const index = _.findIndex(columnPositions, p => left < p.center);
        if (index >= 0) {
          return index;
        }
      } else if (data.x > 0) {
        const right = dragColStyle.left + dragColStyle.width + data.x;
        const index = _.findLastIndex(columnPositions, p => right > p.center);
        if (index >= 0) {
          return index;
        }
      }
    }
    return dragColNewIndex;
  }

  getColumnPositions = () => {
    let left = this.state.showDetailShortcut ? SIDEBAR_WIDTH : 0;
    return this.props.data.cols.map((col, index) => {
      const width = this.getColumnWidth({ index });
      const pos = {
        left,
        right: left + width,
        center: left + width / 2,
        width,
      };
      left += width;
      return pos;
    });
  };

  getNewColumnLefts = dragColNewIndex => {
    const { dragColIndex, columnPositions } = this.state;
    const { cols } = this.props.data;
    const indexes = cols.map((col, index) => index);
    indexes.splice(dragColNewIndex, 0, indexes.splice(dragColIndex, 1)[0]);
    let left = this.state.showDetailShortcut ? SIDEBAR_WIDTH : 0;
    const lefts = indexes.map(index => {
      const thisLeft = left;
      left += columnPositions[index].width;
      return { index, left: thisLeft };
    });
    lefts.sort((a, b) => a.index - b.index);
    return lefts.map(p => p.left);
  };

  getColumnLeft(style, index) {
    const { dragColNewIndex, dragColNewLefts } = this.state;
    if (dragColNewIndex != null && dragColNewLefts) {
      return dragColNewLefts[index];
    }
    return style.left;
  }

  // TableInteractive renders invisible columns to remeasure the layout (see the _measure method)
  // After the measurements are done, invisible columns get unmounted.
  // Because table headers are wrapped into react-draggable, it can trigger
  // https://github.com/react-grid-layout/react-draggable/issues/315
  // (inputs loosing focus when draggable components unmount)
  // We need to prevent that by passing `enableUserSelectHack={false}`
  // to draggable components used in measurements
  // We should maybe rethink the approach to measurements or render a very basic table header, without react-draggable
  tableHeaderRenderer = ({
    key,
    style,
    columnIndex,
    titleHeight,
    isVirtual = false,
  }) => {
    const {
      data,
      isPivoted,
      hasMetadataPopovers,
      getColumnTitle,
      getColumnSortDirection,
      renderTableHeaderWrapper,
      settings,
    } = this.props;
    const { dragColIndex, showDetailShortcut } = this.state;
    const { cols } = data;
    const column = cols[columnIndex];
    const columnTitle = getColumnTitle(columnIndex);
    const clicked = this.getHeaderClickedObject(data, columnIndex, isPivoted);
    const isDraggable = !isPivoted;
    const isDragging = dragColIndex === columnIndex;
    const isClickable = this.visualizationIsClickable(clicked);
    const isSortable = isClickable && column.source && !isPivoted;
    const isRightAligned = isColumnRightAligned(column);

    const sortDirection = getColumnSortDirection(columnIndex);
    const isSorted = sortDirection != null;
    const isAscending = sortDirection === "asc";

    const fieldInfoPopoverTestId = "field-info-popover";
    // TODO MBQL: use query lib to get the sort field
    // const fieldRef = fieldRefForColumn(column);
    // const sortIndex = _.findIndex(
    //   sort,
    //   sort => sort[1] && Dimension.isEqual(sort[1], fieldRef),
    // );
    // const isSorted = sortIndex >= 0;
    // const isAscending = isSorted && sort[sortIndex][0] === "asc";
    const fontSize = settings["table.title_font_size"];
    const fontStyle = settings["table.title_font_italic"] ? "italic" : "normal";
    const fontWeight = settings["table.title_font_bold"] ? "bold" : "normal";

    const titleIndentVertical = settings["table.title_auto_indent"]
      ? TITILE_DEFAULT_INDENT
      : settings["table.titile_indent_vertical"];
    const titleIndentHorizontal = settings["table.title_auto_indent"]
      ? 0
      : settings["table.title_indent_left"];

    const isSortIconHidden = settings["table.header_sort_icon_hidden"];
    const sortIconColor = settings["table.header_sort_icon_color"];

    const headerTextColor = settings["table.header_text_color"];
    const headerBackgroundColor = settings["table.header_background_color"];
    const isHeaderBackgroundTransparent = settings["table.header_transparent"];
    const headerSortedTextColor = settings["table.header_sorted_text_color"];

    const titleHorizontalAligment =
      settings["table.title_horizontal_alignment"];
    const titleVerticalAligment = settings["table.title_vertical_alignment"];

    const headerBorderColor = settings["table.grid_color"];

    return (
      <TableDraggable
        /* needs to be index+name+counter so Draggable resets after each drag */
        enableUserSelectHack={false}
        enableCustomUserSelectHack={!isVirtual}
        key={columnIndex + column.name + DRAG_COUNTER}
        axis="x"
        disabled={!isDraggable}
        onStart={(e, d) => {
          this.setState({
            columnPositions: this.getColumnPositions(),
            dragColIndex: columnIndex,
            dragColStyle: style,
            dragColNewIndex: columnIndex,
          });
        }}
        onDrag={(e, data) => {
          const newIndex = this.getDragColNewIndex(data);
          if (newIndex != null && newIndex !== this.state.dragColNewIndex) {
            this.setState({
              dragColNewIndex: newIndex,
              dragColNewLefts: this.getNewColumnLefts(newIndex),
            });
          }
        }}
        onStop={(e, d) => {
          const { dragColIndex, dragColNewIndex } = this.state;
          DRAG_COUNTER++;
          if (
            dragColIndex != null &&
            dragColNewIndex != null &&
            dragColIndex !== dragColNewIndex
          ) {
            this.onColumnReorder(dragColIndex, dragColNewIndex);
          } else if (Math.abs(d.x) + Math.abs(d.y) < HEADER_DRAG_THRESHOLD) {
            // in setTimeout since headers will be rerendered due to DRAG_COUNTER changing
            setTimeout(() => {
              this.onVisualizationClick(clicked, this.headerRefs[columnIndex]);
            });
          }
          this.setState({
            columnPositions: null,
            dragColIndex: null,
            dragColStyle: null,
            dragColNewIndex: null,
            dragColNewLefts: null,
          });
        }}
      >
        <HeaderCell
          data-testid={isVirtual ? undefined : "header-cell"}
          ref={e => (this.headerRefs[columnIndex] = e)}
          style={{
            ...style,
            overflow: "visible" /* ensure resize handle is visible */,
            // use computed left if dragging, except for the dragged header
            alignItems: titleVerticalAligment,
            justifyContent: titleHorizontalAligment,
            height: titleHeight,
            left: isDragging
              ? style.left
              : this.getColumnLeft(style, columnIndex),
            paddingLeft: titleIndentHorizontal,
            paddingTop: titleIndentVertical,
            // compensation of titile button border
            backgroundColor: isHeaderBackgroundTransparent
              ? "transparent"
              : headerBackgroundColor,
            borderTop: `1px solid ${headerBorderColor}`,
          }}
          className={cx(
            "TableInteractive-cellWrapper TableInteractive-headerCellData",
            {
              "TableInteractive-cellWrapper--firstColumn": columnIndex === 0,
              padLeft: columnIndex === 0 && !showDetailShortcut,
              "TableInteractive-cellWrapper--lastColumn":
                columnIndex === cols.length - 1,
              "TableInteractive-cellWrapper--active": isDragging,
              "TableInteractive-headerCellData--sorted": isSorted,
              "cursor-pointer": isClickable,
              "justify-end": isRightAligned,
            },
          )}
          role="columnheader"
          aria-label={columnTitle}
          onClick={
            // only use the onClick if not draggable since it's also handled in Draggable's onStop
            isClickable && !isDraggable
              ? e => {
                  this.onVisualizationClick(clicked, e.currentTarget);
                }
              : undefined
          }
        >
          <FieldInfoPopover
            placement="bottom-start"
            field={column}
            timezone={data.results_timezone}
            disabled={this.props.clicked != null || !hasMetadataPopovers}
            showFingerprintInfo
          >
            {renderTableHeaderWrapper(
              <Ellipsified tooltip={columnTitle} style={{ lineHeight: 1 }}>
                {isSortable && isRightAligned && !isSortIconHidden && (
                  <Icon
                    className="Icon mr1"
                    name={isAscending ? "chevronup" : "chevrondown"}
                    size={10}
                    data-testid={fieldInfoPopoverTestId}
                    color={sortIconColor}
                  />
                )}
                <span
                  style={{
                    fontSize,
                    fontStyle,
                    fontWeight,
                    color: isSorted ? headerSortedTextColor : headerTextColor,
                  }}
                >
                  {columnTitle}
                </span>
                {isSortable && !isRightAligned && !isSortIconHidden && (
                  <Icon
                    className="Icon ml1"
                    name={isAscending ? "chevronup" : "chevrondown"}
                    size={10}
                    data-testid={fieldInfoPopoverTestId}
                    color={sortIconColor}
                  />
                )}
              </Ellipsified>,
              {},
              column,
              columnIndex,
            )}
          </FieldInfoPopover>
          <TableDraggable
            enableUserSelectHack={false}
            enableCustomUserSelectHack={!isVirtual}
            axis="x"
            bounds={{ left: RESIZE_HANDLE_WIDTH }}
            position={{
              x: this.getColumnWidth({ index: columnIndex }),
              y: 0,
            }}
            onStart={e => {
              e.stopPropagation();
              this.setState({ dragColIndex: columnIndex });
            }}
            onStop={(e, { x }) => {
              // prevent onVisualizationClick from being fired
              e.stopPropagation();
              this.onColumnResize(columnIndex, x);
              this.setState({ dragColIndex: null });
            }}
          >
            <ResizeHandle
              style={{
                zIndex: 99,
                position: "absolute",
                width: RESIZE_HANDLE_WIDTH,
                top: 0,
                bottom: 0,
                left: -RESIZE_HANDLE_WIDTH - 1,
                cursor: "ew-resize",
              }}
            />
          </TableDraggable>
        </HeaderCell>
      </TableDraggable>
    );
  };


  // also look pivotal table (as main), simple table (it used at dashboard)

  // https://github.com/bvaughn/react-virtualized/blob/master/source/ScrollSync/ScrollSync.example.js
  // https://bvaughn.github.io/react-virtualized/#/components/ScrollSync
  // https://github.com/bvaughn/react-virtualized/blob/master/docs/ScrollSync.md
  // https://github.com/bvaughn/react-virtualized/blob/master/docs/Grid.md
  _renderLeftSideCell({ columnIndex, key, rowIndex, style }) {
    return (
      <div key={key} style={style}>
        {`R${rowIndex}, C${columnIndex}`}
      </div>
    );
  }

  getDisplayColumnWidth = ({ index: displayIndex }) => {
    if (this.state.showDetailShortcut && displayIndex === 0) {
      return SIDEBAR_WIDTH;
    }

    // if the detail shortcut is visible, we've added a column of empty cells and need to shift
    // the display index to get the data index
    const dataIndex = this.state.showDetailShortcut
      ? displayIndex - 1
      : displayIndex;

    return this.getColumnWidth({ index: dataIndex });
  };

  _getColumnFullWidth = index => {
    const { settings } = this.props;
    const { columnWidths } = this.state;
    const columnWidthsSetting = settings["table.column_widths"] || [];

    const explicitWidth = columnWidthsSetting[index];
    const calculatedWidth = columnWidths[index] || MIN_COLUMN_WIDTH;

    return explicitWidth || calculatedWidth;
  };

  handleExpandColumn = index =>
    this.setState(
      prevState => {
        const columnIsExpanded = prevState.columnIsExpanded.slice();
        columnIsExpanded[index] = true;
        return { columnIsExpanded };
      },
      () => this.recomputeGridSize(),
    );

  isColumnWidthTruncated = index => {
    const { columnIsExpanded } = this.state;

    return (
      !columnIsExpanded[index] &&
      this._getColumnFullWidth(index) > TRUNCATE_WIDTH
    );
  };

  getColumnWidth = ({ index }) => {
    const { columnIsExpanded } = this.state;
    const fullWidth = this._getColumnFullWidth(index);

    return columnIsExpanded[index]
      ? fullWidth
      : Math.min(fullWidth, TRUNCATE_WIDTH);
  };

  getColumnsWidthByIndexes(startIndex, endIndex) {
    if (!this.state.columnWidths) {
      return 0;
    }
    return this.state.columnWidths
      .slice(startIndex, endIndex)
      .reduce((sum, width) => sum + width, 0);
  }

  handleHoverRow = (event, rowIndex) => {
    const hoverDetailEl = this.detailShortcutRef.current;
    if (!hoverDetailEl) {
      return;
    }

    const { settings } = this.props;
    const cellIndentVertical = settings["table.cell_auto_indent"]
      ? CELL_DEFAULT_INDENT
      : settings["table.cell_indent_vertical"];

    const rowHeight = settings["table.cell_auto_height"]
      ? settings["table.cell_font_size"] + cellIndentVertical * 2
      : settings["table.cell_height"];

    const scrollOffset = ReactDOM.findDOMNode(this.grid)?.scrollTop || 0;

    // infer row index from mouse position when we hover the gutter column
    if (event?.currentTarget?.id === "gutter-column") {
      const gutterTop = event.currentTarget?.getBoundingClientRect()?.top;
      const fromTop = event.clientY - gutterTop;

      const newIndex = Math.floor((fromTop + scrollOffset) / rowHeight);

      if (newIndex >= this.props.data.rows.length) {
        return;
      }
      hoverDetailEl.classList.add("show");
      hoverDetailEl.style.top = `${newIndex * rowHeight - scrollOffset}px`;
      hoverDetailEl.dataset.showDetailRowindex = newIndex;
      hoverDetailEl.onclick = this.pkClick(newIndex);
      return;
    }

    const targetOffset = event?.currentTarget?.offsetTop;
    hoverDetailEl.classList.add("show");
    hoverDetailEl.style.top = `${targetOffset - scrollOffset}px`;
    hoverDetailEl.dataset.showDetailRowindex = rowIndex;
    hoverDetailEl.onclick = this.pkClick(rowIndex);
  };

  handleLeaveRow = () => {
    this.detailShortcutRef.current.classList.remove("show");
  };

  handleOnMouseEnter = () => {
    // prevent touchpad gestures from navigating forward/back if you're expecting to just scroll the table
    // https://stackoverflow.com/a/50846937
    this._previousOverscrollBehaviorX = document.body.style.overscrollBehaviorX;
    document.body.style.overscrollBehaviorX = "none";
  };
  handleOnMouseLeave = () => {
    document.body.style.overscrollBehaviorX = this._previousOverscrollBehaviorX;
  };

  render() {
    const {
      width,
      height,
      data: { cols, rows },
      className,
      scrollToColumn,
      settings,
    } = this.props;

    if (!width || !height) {
      return <div className={className} />;
    }

    const gutterColumn = this.state.showDetailShortcut ? 1 : 0;

    const titleIndentVertical = settings["table.title_auto_indent"]
      ? TITILE_DEFAULT_INDENT
      : settings["table.titile_indent_vertical"];

    const titleFontSize = settings["table.title_font_size"];
    const isTitleAutoHeight = settings["table.title_auto_height"];

    const titleHeightFontSize =
      titleFontSize * 1.2 + 8.25 + titleIndentVertical * 2;
    const titleHeightSettings = isTitleAutoHeight
      ? titleHeightFontSize
      : settings["table.title_height"];
    const titleHeight =
      titleHeightSettings >= titleHeightFontSize
        ? titleHeightSettings
        : titleHeightFontSize;

    const titleBorderColor = settings["table.grid_color"];

    const cellIndentVertical = settings["table.cell_auto_indent"]
      ? CELL_DEFAULT_INDENT
      : settings["table.cell_indent_vertical"];

    const cellHeight = settings["table.cell_auto_height"]
      ? settings["table.cell_font_size"] + cellIndentVertical * 2
      : settings["table.cell_height"];

    const isPinMode = settings["table.pin_mode"];
    const pinnedRowsCountSettings = settings["table.pinned_rows_count"];
    const pinnedColumnsCountSettings = settings["table.pinned_columns_count"];

    const pinnedRowsCount =
      pinnedRowsCountSettings > rows.length
        ? rows.length
        : pinnedRowsCountSettings < 1
        ? 1
        : pinnedRowsCountSettings;
    const pinnedColumnsCount =
      pinnedColumnsCountSettings > cols.length + gutterColumn
        ? cols.length + gutterColumn
        : pinnedColumnsCountSettings < 1
        ? 1
        : pinnedColumnsCountSettings;

    const maxPinnedColumnsLeftScroll =
      this.getColumnsWidthByIndexes(
        0,
        pinnedColumnsCountSettings - gutterColumn,
      ) - width;

    const pinnedTableHeight = height - cellHeight * 2;
    const maxPinnedRowsTopScroll =
      cellHeight * pinnedRowsCount - pinnedTableHeight;

    return (
      <DelayGroup>
        <ScrollSync>
          {({ onScroll, scrollLeft, scrollTop }) => {
            // Grid's doc says scrollToColumn takes precedence over scrollLeft
            // (https://github.com/bvaughn/react-virtualized/blob/master/docs/Grid.md#prop-types)
            // For some reason, for TableInteractive's main grid scrollLeft appears to be more prior
            const mainGridProps = {};
            if (scrollToColumn >= 0) {
              mainGridProps.scrollToColumn = scrollToColumn;
            } else {
              mainGridProps.scrollLeft = scrollLeft;
            }
            return (
              <TableInteractiveRoot
                className={cx(className, "TableInteractive relative", {
                  "TableInteractive--pivot": this.props.isPivoted,
                  "TableInteractive--ready": this.state.contentWidths,
                  // no hover if we're dragging a column
                  "TableInteractive--noHover": this.state.dragColIndex != null,
                })}
                onMouseEnter={this.handleOnMouseEnter}
                onMouseLeave={this.handleOnMouseLeave}
                data-testid="TableInteractive-root"
              >
                <canvas
                  className="spread"
                  style={{ pointerEvents: "none", zIndex: 999 }}
                  width={width}
                  height={height}
                />
                {!!gutterColumn && (
                  <>
                    <div
                      className="TableInteractive-header TableInteractive--noHover"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: SIDEBAR_WIDTH,
                        height: titleHeight,
                        zIndex: 4,
                      }}
                    />
                    <div
                      id="gutter-column"
                      className="TableInteractive-gutter"
                      style={{
                        position: "absolute",
                        top: titleHeight,
                        left: 0,
                        height: height - titleHeight - getScrollBarSize(),
                        width: SIDEBAR_WIDTH,
                        zIndex: 3,
                      }}
                      onMouseMove={this.handleHoverRow}
                      onMouseLeave={this.handleLeaveRow}
                    >
                      <DetailShortcut ref={this.detailShortcutRef}
                    height={cellHeight}
                    /></div>
                  </>
                )}
                <Grid
                ref={ref => (this.header = ref)}
                style={{
                  top: 0,
                  left: 0,
                  right: 0,
                  height: titleHeight,
                  position: "absolute",
                  overflow: "hidden",
                  paddingRight: getScrollBarSize(),
                  borderBottom: `1px solid ${titleBorderColor}`,
                }}
                className="TableInteractive-header scroll-hide-all"
                width={width || 0}
                height={titleHeight}
                rowCount={1}
                rowHeight={titleHeight}
                columnCount={cols.length + gutterColumn}
                columnWidth={this.getDisplayColumnWidth}
                cellRenderer={props =>
                  gutterColumn && props.columnIndex === 0
                    ? () => null // we need a phantom cell to properly offset columns
                    : this.tableHeaderRenderer({
                        ...props,
                        columnIndex: props.columnIndex - gutterColumn,
                        titleHeight: titleHeight,
                      })
                }
                onScroll={({ scrollLeft }) => onScroll({ scrollLeft })}
                scrollLeft={scrollLeft}
                tabIndex={null}
                scrollToColumn={scrollToColumn}
              />

              <>
                {isPinMode && (
                  <Grid
                    ref={ref => (this.pinnedGrid = ref)}
                    className="scroll-hide-all"
                    id="pinnded-data-grid"
                    style={{
                      top: titleHeight,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      position: "absolute",
                      zIndex: 2,
                      pointerEvents: "none",
                      overflow: "hidden",
                    }}
                    width={width - getScrollBarSize()}
                    height={pinnedTableHeight}
                    columnCount={pinnedColumnsCount + gutterColumn}
                    columnWidth={this.getDisplayColumnWidth}
                    rowCount={pinnedRowsCount}
                    overscanRowCount={20}
                    rowHeight={cellHeight}
                    cellRenderer={props =>
                      gutterColumn && props.columnIndex === 0
                        ? () => null // we need a phantom cell to properly offset columns
                        : this.cellRenderer({
                            ...props,
                            cellHeight: cellHeight,
                            columnIndex: props.columnIndex - gutterColumn,
                          })
                    }
                    scrollTop={
                      scrollTop > maxPinnedRowsTopScroll
                        ? maxPinnedRowsTopScroll
                        : scrollTop
                    }
                    scrollLeft={
                      scrollLeft > maxPinnedColumnsLeftScroll
                        ? maxPinnedColumnsLeftScroll
                        : scrollLeft
                    }
                    tabIndex={null}
                  />
                )}
                <Grid
                  id="main-data-grid"// look here
                  ref={ref => (this.grid = ref)}
                  style={{
                    top: titleHeight,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    position: "absolute",
                  zIndex: 1,}}
                  width={width}
                  height={height - cellHeight}
                  columnCount={cols.length + gutterColumn}
                  columnWidth={this.getDisplayColumnWidth}
                  rowCount={rows.length}
                  rowHeight={cellHeight}
                  cellRenderer={props =>
                    gutterColumn && props.columnIndex === 0
                      ? () => null // we need a phantom cell to properly offset columns
                      : this.cellRenderer({
                          ...props,cellHeight: cellHeight,
                          columnIndex: props.columnIndex - gutterColumn,
                        })
                  }
                  scrollToRow={this.state.selectedRowIndex || 0}scrollTop={scrollTop}
                  onScroll={({ scrollLeft, scrollTop }) => {
                    this.props.onActionDismissal();
                    return onScroll({ scrollLeft, scrollTop });
                  }}
                  {...mainGridProps}
                  tabIndex={null}
                  overscanRowCount={20}/>
                </>
              </TableInteractiveRoot>
            );
          }}
        </ScrollSync>
      </DelayGroup>
    );
  }

  _benchmark() {
    const grid = ReactDOM.findDOMNode(this.grid);
    const height = grid.scrollHeight;
    let top = 0;
    let start = Date.now();
    // console.profile();
    function next() {
      grid.scrollTop = top;

      setTimeout(() => {
        const end = Date.now();
        // eslint-disable-next-line no-console
        console.log(end - start);
        start = end;

        top += height / 10;
        if (top < height - height / 10) {
          next();
        } else {
          // console.profileEnd();
        }
      }, 40);
    }
    next();
  }
}

export default _.compose(
  ExplicitSize({
    refreshMode: props => (props.isDashboard ? "debounce" : "throttle"),
  }),
  connect(mapStateToProps, mapDispatchToProps),
  memoizeClass(
    "_getCellClickedObjectCached",
    "_visualizationIsClickableCached",
    "getCellBackgroundColor",
    "getCellFormattedValue",
    "getHeaderClickedObject",
  ),
)(TableInteractive);

const DetailShortcut = forwardRef((_props, ref) => (
  <div
    className="TableInteractive-cellWrapper cursor-pointer"
    ref={ref}
    style={{
      position: "absolute",
      left: 0,
      top: 0,
      height: _props.height,
      width: SIDEBAR_WIDTH,
      zIndex: 3,
    }}
    data-testid="detail-shortcut"
  >
    <Tooltip tooltip={t`View Details`}>
      <Button
        iconOnly
        iconSize={_props.height > SIDEBAR_WIDTH ? 10 : _props.height / 4}
        icon="expand"
        className="TableInteractive-detailButton"
      />
    </Tooltip>
  </div>
));

DetailShortcut.displayName = "DetailShortcut";
