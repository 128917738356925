import { useMemo } from "react";
import { connect } from "react-redux";
import { jt, t } from "ttag";

import { updateOpenIdSettings } from "metabase/admin/settings/settings";
import Breadcrumbs from "metabase/components/Breadcrumbs";
import FormErrorMessage from "metabase/core/components/FormErrorMessage";
import FormInput from "metabase/core/components/FormInput";
import FormSubmitButton from "metabase/core/components/FormSubmitButton";
import FormToggle from "metabase/core/components/FormToggle/FormToggle";
import { FormProvider } from "metabase/forms";
import type { Settings } from "metabase-types/api";

import { OPENID_SCHEMA } from "../../constants";

import {
  OpenIdForm,
  OpenIdFormCaption,
  OpenIdFormHeader,
} from "./OpenIdAuthForm.styled";

const ENABLED_KEY = "openid-auth-enabled";
const SERVER_KEY = "openid-auth-server";
const CLIENT_ID = "openid-auth-client-id";
const AUTH_ENDPOINT = "openid-auth-auth-endpoint";
const TOKEN_ENDPOINT = "openid-auth-token-endpoint";
const USERINFO_ENDPOINT = "openid-auth-userinfo-endpoint";
const BREADCRUMBS = [
  [t`Authentication`, "/admin/settings/authentication"],
  [t`OpenID`],
];

interface IProps {
  isEnabled: boolean;
  settingValues: Partial<Settings>;
  onSubmit: (settingValues: Partial<Settings>) => void;
}

const SettingsOpenIdAuthForm = ({
  settingValues = {},
  onSubmit,
  isEnabled,
}: IProps) => {
  const initialValues = useMemo(() => {
    const values = OPENID_SCHEMA.cast(settingValues, { stripUnknown: true });
    return { ...values, [ENABLED_KEY]: values[ENABLED_KEY] || false };
  }, [settingValues]);

  return (
    <FormProvider
      initialValues={initialValues}
      enableReinitialize
      validationSchema={OPENID_SCHEMA}
      onSubmit={onSubmit}
    >
      {({ dirty }) => (
        <OpenIdForm disabled={!dirty}>
          <Breadcrumbs crumbs={BREADCRUMBS} />
          <OpenIdFormHeader>{t`Sign in with OpenID`}</OpenIdFormHeader>
          <OpenIdFormCaption>
            {t`Allows users to login with a OpenID account. If user haven't an account in GlarusBI it will be created.`}
          </OpenIdFormCaption>
          <OpenIdFormCaption>{jt``}</OpenIdFormCaption>
          <FormToggle
            name={ENABLED_KEY}
            title={t`Enabled`}
            description={t`Switch to on/off OpenID`}
          />
          <FormInput
            name={SERVER_KEY}
            title={t`OpenID provider address`}
            description={t`The base URL of OpenID service is running. All endpoint addresses will be added to this URI. (https://open-id-server/openid-handler/)`}
            placeholder={"url"}
            nullable
          />
          <FormInput
            name={CLIENT_ID}
            title={t`OpenID client ID`}
            description={t`Client ID for application.`}
            placeholder={"id"}
            nullable
          />
          <FormInput
            name={AUTH_ENDPOINT}
            title={t`Auth endpoint`}
            description={t`Address of authorization endpoint. Default is 'auth'`}
            placeholder={"auth"}
          />
          <FormInput
            name={TOKEN_ENDPOINT}
            title={t`Token endpoint`}
            description={t`Address of token endpoint. Default is 'token'`}
            placeholder={"token"}
          />
          <FormInput
            name={USERINFO_ENDPOINT}
            title={t`Userinfo endpoint`}
            description={t`Address of userinfo endpoint. Default is 'userinfo'`}
            placeholder={"userinfo"}
          />
          <FormSubmitButton
            title={isEnabled ? t`Save changes` : t`Save and enable`}
            primary
            disabled={!dirty}
          />
          <FormErrorMessage />
        </OpenIdForm>
      )}
    </FormProvider>
  );
};

const mapDispatchToProps = {
  onSubmit: updateOpenIdSettings,
};

export default connect(null, mapDispatchToProps)(SettingsOpenIdAuthForm);
