import { t } from "ttag";
import _ from "underscore";

import { color } from "metabase/lib/colors";
import { hasLatitudeAndLongitudeColumns } from "metabase-lib/types/utils/isa";
import type { WidgetSettings } from "metabase-types/api/widgets/widget";

type MapSettingsKeys =
  | "map.type"
  | "map.show_circle"
  | "map.color_circle"
  | "map.min_circle_step_radius"
  | "map.max_circle_step_radius";

export type MapSettings = WidgetSettings<MapSettingsKeys>;

export const MAP_SETTINGS: MapSettings = {
  "map.type": {
    title: t`Map type`,
    widget: "select",
    section: t`Display`,
    props: {
      options: [
        { name: t`Region map`, value: "region" },
        { name: t`Pin map`, value: "pin" },
        { name: "Grid map", value: "grid" },
      ],
    },
    getDefault: ([{ card, data }], settings) => {
      switch (card.display) {
        case "state":
        case "country":
          return "region";
        case "pin_map":
          return "pin";
        default:
          if (hasLatitudeAndLongitudeColumns(data.cols)) {
            const latitudeColumn = _.findWhere(data.cols, {
              name: settings["map.latitude_column"],
            });
            const longitudeColumn = _.findWhere(data.cols, {
              name: settings["map.longitude_column"],
            });
            if (
              latitudeColumn &&
              longitudeColumn &&
              latitudeColumn.binning_info &&
              longitudeColumn.binning_info
            ) {
              return "grid";
            } else {
              return "pin";
            }
          } else {
            return "region";
          }
      }
    },
    readDependencies: [
      "map.latitude_column",
      "map.longitude_column",
      "map.metric_column",
    ],
  },

  "map.show_circle": {
    section: t`Display`,
    title: t`Show circle points`,
    widget: "toggle",
    default: false,
  },
  "map.color_circle": {
    section: t`Display`,
    title: `Choose color for circle`,
    widget: "color",
    getHidden: (series, vizSettings) => !vizSettings["map.show_circle"],
    default: color("accent1"),
  },

  "map.min_circle_step_radius": {
    section: t`Display`,
    title: t`Min circle step radius`,
    widget: "number",
    getDefault: (_, vizSettings) => {
      return vizSettings["map.type"] === "pin" ? 10 : 20000;
    },
    getHidden: (series, vizSettings) => {
      return !vizSettings["map.show_circle"];
    },
  },
  "map.max_circle_step_radius": {
    section: t`Display`,
    title: t`Max circle step radius`,
    widget: "number",
    getDefault: (_, vizSettings) => {
      return vizSettings["map.type"] === "pin" ? 20 : 80000;
    },
    getHidden: (series, vizSettings) => {
      return !vizSettings["map.show_circle"];
    },
  },
};
