type TSettingValue = string | number | boolean;
type TSettings = Record<string, TSettingValue>;

export function getBackgroundImageSettings(settings: TSettings) {
  if (!settings) {
    return {};
  }

  const backgroundImageSettings = settings["card.background_image"];

  if (!backgroundImageSettings) {
    return {};
  }
  const backgroundImage = `url(${backgroundImageSettings})`;

  const backgroundImageAttachment =
    settings["card.background_image_attachment"] || "scroll";
  const backgroundImageRepeat =
    settings["card.background_image_repeat"] || "no-repeat";

  //   position
  let backgroundPosition: TSettingValue = "center";
  const isCustomPosition = settings["card.background_image_custom_position"];
  const backgroundImageTwoAxisPosition =
    settings["card.background_image_two_axis_position"] || "50% 50%";
  const backgrounImagePositionSettings =
    settings["card.background_image_position"] || "center";
  backgroundPosition = isCustomPosition
    ? backgroundImageTwoAxisPosition
    : backgrounImagePositionSettings;
  // size
  let backgroundSize: TSettingValue = "contain";
  const isBackgroundImageCustomSize =
    settings["card.background_image_custom_size"];
  const backgroundImageTwoAxisSize =
    settings["card.background_image_two_axis_size"] || "auto auto";

  const backgroundImageSizeSettings =
    settings["card.background_image_size"] || "contain";
  backgroundSize = isBackgroundImageCustomSize
    ? backgroundImageTwoAxisSize
    : backgroundImageSizeSettings;

  return {
    backgroundImage: backgroundImage,
    backgroundAttachment: backgroundImageAttachment,
    backgroundPosition: backgroundPosition,
    backgroundRepeat: backgroundImageRepeat,
    backgroundSize: backgroundSize,
  };
}

export function getBackgroundImageSettingsForDragAndDrop(settings: TSettings) {
  if (!settings) {
    return {};
  }

  const backgroundImageSettings = settings["card.background_image"];

  if (!backgroundImageSettings) {
    return {};
  }

  const heightPercent = settings["card.background_image_drag_and_drop_height"];
  const widthPercent = settings["card.background_image_drag_and_drop_width"];

  const leftPositionPercent =
    settings["card.background_image_drag_and_drop_position_left"];
  const topPositionPercent =
    settings["card.background_image_drag_and_drop_position_top"];

  return {
    backgroundImage: backgroundImageSettings,
    heightPercent,
    widthPercent,
    leftPositionPercent,
    topPositionPercent,
  };
}
