// NOTE: this file is used on the frontend and backend and there are some
// limitations. See frontend/src/metabase-shared/color_selector for details

import { alpha } from "metabase/lib/colors";
import { getColorScale, getSafeColor } from "metabase/lib/colors/scales";

const CELL_ALPHA = 0.65;
const ROW_ALPHA = 0.2;
const GRADIENT_ALPHA = 0.75;

// for simplicity wheb typing assume all values are numbers, since you can only pick numeric columns

export function makeCellStyleGetter(rows, cols, formattingSettings) {
  let formatters = {};
  let rowFormatters = [];
  const colIndexes = getColumnIndexesByName(cols);

  try {
    const columnExtents = computeColumnExtents(
      formattingSettings,
      rows,
      colIndexes,
    );
    formatters = compileFormatters(
      formattingSettings,
      columnExtents,
      colIndexes,
    );
    rowFormatters = compileRowFormatters(
      formattingSettings,
      columnExtents,
      colIndexes,
    );
  } catch (e) {
    console.error("Unexpected error compiling column formatters: ", e);
  }
  if (Object.keys(formatters).length === 0 && rowFormatters.length === 0) {
    return () => null;
  } else {
    return function (value, rowIndex, colName) {
      try {
        if (formatters[colName]) {
          for (let i = 0; i < formatters[colName].length; i++) {
            const formatter = formatters[colName][i];
            const formatterValue = formatter(value, rows[rowIndex]);
            if (formatterValue != null) {
              return formatterValue;
            }
          }
        }

        for (let i = 0; i < rowFormatters.length; i++) {
          const rowFormatter = rowFormatters[i];
          const color = rowFormatter(
            rows[rowIndex],
            colIndexes,
            rows[rowIndex],
          );
          if (color != null) {
            return color;
          }
        }
      } catch (error) {
        console.error("Get color ERROR\n", error);
      }
      return null;
    };
  }
}

export function getColumnIndexesByName(cols) {
  const colIndexes = {};
  for (let i = 0; i < cols.length; i++) {
    colIndexes[cols[i].name] = i;
  }
  return colIndexes;
}

export const canCompareSubstrings = (a, b) =>
  typeof a === "string" && typeof b === "string" && !!a.length && !!b.length;

export const isEmptyString = val => typeof val === "string" && !val.length;

const getAccuracy = value =>
  value.toString().includes(".") ? value.toString().split(".").pop().length : 0;

const getValueWithAccuracy = (value, accuracy) =>
  Math.round(value * Math.pow(10, accuracy)) / Math.pow(10, accuracy);

export const OPERATOR_FORMATTER_FACTORIES = {
  "<": value => v => typeof value === "number" && v < value,
  "<=": value => v => {
    if (typeof value === "number" && typeof v === "number") {
      const accuracy = getAccuracy(value);
      const cellValueWithAccuracy = getValueWithAccuracy(v, accuracy);
      return cellValueWithAccuracy <= value;
    }
  },
  ">=": value => v => {
    if (typeof value === "number" && typeof v === "number") {
      const accuracy = getAccuracy(value);
      const cellValueWithAccuracy = getValueWithAccuracy(v, accuracy);
      return cellValueWithAccuracy >= value;
    }
  },
  ">": value => v => typeof value === "number" && v > value,
  "=": value => v => {
    if (typeof v === "number" && value !== null) {
      const accuracy = getAccuracy(value);
      const cellValueWithAccuracy = getValueWithAccuracy(v, accuracy);
      return cellValueWithAccuracy === value;
    } else {
      return v === value;
    }
  },
  "!=": value => v => !isEmptyString(value) && v !== value,
  "is-null": _value => v => v === null,
  "not-null": _value => v => v !== null,
  contains: value => v =>
    canCompareSubstrings(value, v) && v.indexOf(value) >= 0,
  "does-not-contain": value => v =>
    canCompareSubstrings(value, v) && v.indexOf(value) < 0,
  "starts-with": value => v =>
    canCompareSubstrings(value, v) && v.startsWith(value),
  "ends-with": value => v =>
    canCompareSubstrings(value, v) && v.endsWith(value),
};

export function compileCellStyleFormatter(
  format,
  columnName,
  columnExtents,
  colIndexes,
  isRowFormatter = false,
) {
  if (format.type === "single") {
    let {
      operator,
      value,
      color,
      targetColumn,
      format_relative_to_cell,
      font_bold,
      font_italic,
    } = format;
    const targetColIndexToCompare = colIndexes?.[targetColumn];

    color = alpha(color, isRowFormatter ? ROW_ALPHA : CELL_ALPHA);

    const formatterFactory = OPERATOR_FORMATTER_FACTORIES[operator];
    if (formatterFactory) {
      return (rowValue, row) => {
        const isTargetIndexToCompare =
          targetColIndexToCompare !== null &&
          targetColIndexToCompare !== undefined;

        if (isTargetIndexToCompare && row && format_relative_to_cell) {
          rowValue = row[targetColIndexToCompare];
        }
        const isRowValueMatchesCondition = formatterFactory(value)(rowValue);
        if (format.target === "font") {
          return isRowValueMatchesCondition
            ? {
                font_bold,
                font_italic,
              }
            : null;
        }
        return isRowValueMatchesCondition ? color : null;
      };
    }

    console.error("Unsupported formatting operator:", operator);
    return () => null;
  } else if (format.type === "range") {
    const columnMin = name =>
      columnExtents && columnExtents[name] && columnExtents[name][0];
    const columnMax = name =>
      columnExtents && columnExtents[name] && columnExtents[name][1];

    const min =
      format.min_type === "custom"
        ? parseFloat(format.min_value)
        : format.min_type === "all"
        ? Math.min(...format.columns.map(columnMin))
        : columnMin(columnName);
    const max =
      format.max_type === "custom"
        ? parseFloat(format.max_value)
        : format.max_type === "all"
        ? Math.max(...format.columns.map(columnMax))
        : columnMax(columnName);

    if (typeof max !== "number" || typeof min !== "number") {
      console.warn("Invalid range min/max", min, max);
      return () => null;
    }

    const scale = getColorScale(
      [min, max],
      format.colors.map(c => alpha(c, GRADIENT_ALPHA)),
    ).clamp(true);
    return value => {
      const colorValue = scale(value);
      if (!colorValue) {
        return null;
      }
      return getSafeColor(colorValue);
    };
  } else {
    console.warn("Unknown format type", format.type);
    return () => null;
  }
}

// NOTE: implement `extent` like this rather than using d3.extent since rows may
// be a Java `List` rather than a JavaScript Array when used in Pulse formatting
export function extent(rows, colIndex) {
  let min = Infinity;
  let max = -Infinity;
  const length = rows.length;
  for (let i = 0; i < length; i++) {
    const value = rows[i][colIndex];
    if (value != null && value < min) {
      min = value;
    }
    if (value != null && value > max) {
      max = value;
    }
  }
  return [min, max];
}

function computeColumnExtents(formats, rows, colIndexes) {
  const columnExtents = {};
  formats.forEach(format => {
    format.columns.forEach(columnName => {
      if (!columnExtents[columnName]) {
        const colIndex = colIndexes[columnName];
        columnExtents[columnName] = extent(rows, colIndex);
      }
    });
  });
  return columnExtents;
}

function compileFormatters(formats, columnExtents, colIndexes) {
  const formatters = {};
  formats.forEach(format => {
    format.columns.forEach(columnName => {
      formatters[columnName] = formatters[columnName] || [];
      formatters[columnName].push(
        compileCellStyleFormatter(
          format,
          columnName,
          columnExtents,
          colIndexes,
          false,
        ),
      );
    });
  });
  return formatters;
}

function compileRowFormatters(formats, _, colIndexes) {
  const rowFormatters = [];
  formats
    .filter(format => format.type === "single" && format.highlight_row)
    .forEach(format => {
      const formatter = compileCellStyleFormatter(
        format,
        null,
        null,
        colIndexes,
        true,
      );
      if (formatter) {
        format.columns.forEach(columnName => {
          rowFormatters.push((row, colIndexes) =>
            formatter(row[colIndexes[columnName]], row),
          );
        });
      }
    });
  return rowFormatters;
}
