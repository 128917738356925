import { connect } from "react-redux";

import FileUploadLayout from "metabase/components/uploadFile/fileUpload/components/FileUploadLayout";
import type { State } from "metabase-types/store";

const mapStateToProps = (state: State) => ({
  showIllustration: state.settings.values["show-lighthouse-illustration"],
  currentUser: state.currentUser,
});

export default connect(mapStateToProps)(FileUploadLayout);
