import type * as XLSX from "xlsx-js-style";

type HorizontalAlignment = "left" | "center" | "right";
type VerticalAlignment = "top" | "center" | "bottom";
type Alignment = "flex-start" | "center" | "flex-end";

export type XlsxCellObject = Omit<XLSX.CellObject, "s"> & {
  s?: XLSX.CellStyle;
};

interface XlsxStyles {
  fontSize?: number;
  isFontBold?: boolean;
  fontColor?: string;
  fontName?: string;
  isFontUnderline?: boolean;
  isFontStrike?: boolean;
  isFontItalic?: boolean;

  horizontalAlignment?: Alignment;
  verticalAlignment?: Alignment;

  backgroundColor?: string;
  borderColor?: string;
}

export function createXlsxCellStyle({
  fontSize = 11,
  isFontBold = false,
  fontColor = "000000",
  fontName = "Calibri",
  isFontUnderline = false,
  isFontStrike = false,
  isFontItalic = false,
  horizontalAlignment = "center",
  verticalAlignment = "center",
  borderColor = "000000",
  backgroundColor = "ffffff",
}: XlsxStyles): XLSX.CellStyle {
  const fontColorCleared = getCleanColorString(fontColor);
  const borderColorCleared = getCleanColorString(borderColor);
  const backgroundColorCleared = getCleanColorString(backgroundColor);

  const xlsxHorizontalAlignment =
    convertCssHorizontalAlignmentToXlsx(horizontalAlignment);
  const xlsxVerticalAlignment =
    convertCssVerticalAlignmentToXlsx(verticalAlignment);

  return {
    font: {
      sz: fontSize,
      bold: isFontBold,
      color: { rgb: fontColorCleared },
      name: fontName,
      underline: isFontUnderline,
      strike: isFontStrike,
      italic: isFontItalic,
    },

    fill: {
      patternType: "solid",
      fgColor: { rgb: backgroundColorCleared },
    },

    alignment: {
      horizontal: xlsxHorizontalAlignment,
      vertical: xlsxVerticalAlignment,
    },

    border: {
      top: { color: { rgb: borderColorCleared } },
      bottom: { color: { rgb: borderColorCleared } },
      left: { color: { rgb: borderColorCleared } },
      right: { color: { rgb: borderColorCleared } },
    },
  };
}

function getCleanColorString(color: string) {
  if (isValidHexColor(color)) {
    return color.replace("#", "");
  }
  return convertColorToHex(color);
}

export function convertColorToHex(color: string) {
  const rgbRegex = /^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/;
  const rgbaRegex =
    /^rgba\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*(0|1|0?\.\d+)\)$/;

  const rgbMatch = color.match(rgbRegex);
  if (rgbMatch) {
    const [r, g, b] = rgbMatch.slice(1, 4).map(Number);
    return rgbToHex(r, g, b);
  }

  const rgbaMatch = color.match(rgbaRegex);
  if (rgbaMatch) {
    const [r, g, b, a] = rgbaMatch
      .slice(1, 5)
      .map((value, index) => (index === 3 ? parseFloat(value) : Number(value)));
    return rgbaToHexOnWhite(r, g, b, a);
  }

  return;
}

function isValidRgb(r: number, g: number, b: number) {
  return r >= 0 && r <= 255 && g >= 0 && g <= 255 && b >= 0 && b <= 255;
}

function rgbToHex(r: number, g: number, b: number) {
  if (!isValidRgb(r, g, b)) {
    return;
  }
  return ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1);
}

function rgbaToHexOnWhite(r: number, g: number, b: number, a: number) {
  if (!isValidRgb(r, g, b) || a < 0 || a > 1) {
    throw new Error("Invalid RGBA color values");
  }
  const newR = Math.round((1 - a) * 255 + a * r);
  const newG = Math.round((1 - a) * 255 + a * g);
  const newB = Math.round((1 - a) * 255 + a * b);
  return rgbToHex(newR, newG, newB);
}

function isValidHexColor(color: string): boolean {
  const hexRegex = /^#?([a-fA-F0-9]{3}|[a-fA-F0-9]{6})$/;
  return hexRegex.test(color);
}

function convertCssHorizontalAlignmentToXlsx(alignment: Alignment) {
  const alignmentMap: Record<Alignment, HorizontalAlignment> = {
    ["flex-start"]: "left",
    ["center"]: "center",
    ["flex-end"]: "right",
  };

  return alignmentMap[alignment];
}

function convertCssVerticalAlignmentToXlsx(alignment: Alignment) {
  const alignmentMap: Record<Alignment, VerticalAlignment> = {
    ["flex-start"]: "top",
    ["center"]: "center",
    ["flex-end"]: "bottom",
  };

  return alignmentMap[alignment];
}

export function getTextWidth(text: string, fontSize: number = 12) {
  const context = document.createElement("canvas").getContext("2d");
  if (context) {
    context.font = `${fontSize}px arial`;
    const width = context.measureText(text).width * 1.2;
    return width;
  }
  return 40;
}
