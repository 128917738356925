import { connect } from "react-redux";
import { withRouter } from "react-router";
import _ from "underscore";

import { getSetting } from "metabase/selectors/settings";
import type { State } from "metabase-types/store";

import { loginOpenID } from "../../actions";
import OpenIDButton from "../../components/OpenIDButton";

const mapStateToProps = (state: State) => ({
  clientID: getSetting(state, "openid-auth-client-id"),
  authorizationEndpoint: "" + getSetting(state, "openid-auth-server") + getSetting(state, "openid-auth-auth-endpoint"),
  enable: getSetting(state, "openid-auth-enabled"),
  locale: getSetting(state, "site-locale"),
});

const mapDispatchToProps = {
  onLogin: loginOpenID,
};

export default _.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(OpenIDButton);
