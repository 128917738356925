import { t } from "ttag";

import { color } from "metabase/lib/colors";
import type { WidgetSettings } from "metabase-types/api/widgets/widget";

type PieCommonSettingsKeys =
  | "pie.show_legend"
  | "pie.legend_position"
  | "pie.show_total"
  | "pie.percent_visibility"
  | "pie.slice_threshold"
  | "pie.text_color";

export type PieSettings = WidgetSettings<PieCommonSettingsKeys>;

const SLICE_THRESHOLD = 0.025;

export const PIE_SETTINGS: PieSettings = {
  "pie.show_legend": {
    section: t`Display`,
    title: t`Show legend`,
    widget: "toggle",
    default: true,
    inline: true,
    marginBottom: "1rem",
  },
  "pie.legend_position": {
    section: t`Display`,
    title: t`Legend position`,
    widget: "segmentedControl",
    props: {
      options: [
        { name: t`Top`, value: "top" },
        { name: t`Bottom`, value: "bottom" },
        { name: t`Right`, value: "right" },
        { name: t`Left`, value: "left" },
      ],
    },
    default: "left",
  },
  "pie.show_total": {
    section: t`Display`,
    title: t`Show total`,
    widget: "toggle",
    default: true,
    inline: true,
  },

  "pie.percent_visibility": {
    section: t`Display`,
    title: t`Show percentages`,
    widget: "radio",
    default: "legend",
    props: {
      options: [
        { name: t`Off`, value: "off" },
        { name: t`In legend`, value: "legend" },
        { name: t`On the chart`, value: "inside" },
      ],
    },
  },
  "pie.slice_threshold": {
    section: t`Display`,
    title: t`Minimum slice percentage`,
    widget: "number",
    default: SLICE_THRESHOLD * 100,
  },
  "pie.text_color": {
    section: t`Display`,
    title: t`Choose legend color`,
    widget: "color",
    default: color("bg-black"),
  },
};
