import styled from "@emotion/styled";
import { color } from "metabase/lib/colors";

export const ImageInputContainer = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  border: 2px dashed ${color("border")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.dragging {
    border-color: ${color("success")};
  }
`;

const BaseButton = styled.button`
  padding: 0.5em 1em;
  color: ${color("text-white")};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }
`;

export const UploadButton = styled(BaseButton)`
  background-color: ${color("brand")};
`;

export const DeleteButton = styled(BaseButton)`
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${color("danger")};
`;

export const PreviewImage = styled.div`
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
