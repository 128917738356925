import PropTypes from "prop-types";
import _ from "underscore";

import ExplicitSize from "metabase/components/ExplicitSize";

import Legend from "./Legend";
import LegendActions from "./LegendActions";
import {
  ChartContainer,
  LegendContainer,
  LegendLayoutRoot,
  MainContainer,
} from "./LegendLayout.styled";

const MIN_ITEM_WIDTH = 100;
const MIN_ITEM_HEIGHT = 25;
const MIN_ITEM_HEIGHT_LARGE = 31;
const MIN_LEGEND_WIDTH = 400;

const propTypes = {
  className: PropTypes.string,
  labels: PropTypes.array.isRequired,
  colors: PropTypes.array.isRequired,
  textColors: PropTypes.array,
  hovered: PropTypes.object,
  showed: PropTypes.array,
  width: PropTypes.number,
  height: PropTypes.number,
  hasLegend: PropTypes.bool,
  actionButtons: PropTypes.node,
  isFullscreen: PropTypes.bool,
  isQueryBuilder: PropTypes.bool,
  children: PropTypes.node,
  onHoverChange: PropTypes.func,
  onSelectSeries: PropTypes.func,
  onRemoveSeries: PropTypes.func,
  isReversed: PropTypes.bool,
  canRemoveSeries: PropTypes.func,
  position: PropTypes.oneOf(["top", "right", "bottom", "left"]),
};

const LegendLayout = ({
  className,
  labels,
  colors,
  textColors = [],
  hovered,
  showed,
  width = 0,
  height = 0,
  hasLegend,
  actionButtons,
  isFullscreen,
  isQueryBuilder,
  children,
  onHoverChange,
  onSelectSeries,
  onRemoveSeries,
  isReversed,
  canRemoveSeries,
  position,
}) => {
  const itemHeight = !isFullscreen ? MIN_ITEM_HEIGHT : MIN_ITEM_HEIGHT_LARGE;
  const maxXItems = Math.floor(width / MIN_ITEM_WIDTH);
  const maxYItems = Math.floor(height / itemHeight);
  const maxYLabels = Math.max(maxYItems - 1, 0);
  const minYLabels = labels.length > maxYItems ? maxYLabels : labels.length;

  const isNarrow = width < MIN_LEGEND_WIDTH;
  const isVertical =
    maxXItems < labels.length || position === "left" || position === "right";
  const isVisible = hasLegend && !(isVertical && isNarrow);
  const visibleLength = isVertical ? minYLabels : labels.length;

  return (
    <LegendLayoutRoot
      className={className}
      isVertical={isVertical}
      position={position}
    >
      {isVisible && (
        <LegendContainer
          isVertical={isVertical}
          isQueryBuilder={isQueryBuilder}
          position={position}
        >
          <Legend
            labels={labels}
            colors={colors}
            textColors={textColors}
            hovered={hovered}
            showed={showed}
            visibleLength={visibleLength}
            isVertical={isVertical}
            onHoverChange={onHoverChange}
            onSelectSeries={onSelectSeries}
            onRemoveSeries={onRemoveSeries}
            isReversed={isReversed}
          />
          {!isVertical && actionButtons && (
            <LegendActions>{actionButtons}</LegendActions>
          )}
        </LegendContainer>
      )}
      <MainContainer>
        {isVertical && actionButtons && (
          <LegendActions>{actionButtons}</LegendActions>
        )}
        <ChartContainer>{children}</ChartContainer>
      </MainContainer>
    </LegendLayoutRoot>
  );
};

LegendLayout.propTypes = propTypes;

export default _.compose(ExplicitSize())(LegendLayout);
