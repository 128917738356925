var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.convert.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.query.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.malli.js");
'use strict';$CLJS.i1=function(a,b){var c=$CLJS.FV(b);return $CLJS.n(c)?$CLJS.f_(a,c):$CLJS.d_(a,b)};$CLJS.j1=function(a){var b=$CLJS.WA(a);b=b instanceof $CLJS.M?b.S:null;switch(b){case "dispatch-type/nil":return null;case "field":return $CLJS.tO.h($CLJS.DD(a));case "metadata/column":return $CLJS.eZ.h(a);case "mbql/join":return $CLJS.UD.h(a);default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}};
$CLJS.k1=function(a,b){return $CLJS.ha.j?$CLJS.ha.j("%s__%s",a,b):$CLJS.ha.call(null,"%s__%s",a,b)};$CLJS.l1=function(a,b){var c=$CLJS.j1(b);if($CLJS.n(c))a=c;else a:{var d=$CLJS.O(b);c=$CLJS.J.g(d,$CLJS.WJ);d=$CLJS.J.g(d,$CLJS.XJ);if($CLJS.n($CLJS.n(c)?d:c)&&(d=$CLJS.i1(a,d),$CLJS.n(d))){d=$CLJS.U.h(d);a=$CLJS.U.h($CLJS.$0(a,c));a=$CLJS.ha.j?$CLJS.ha.j("%s__via__%s",d,a):$CLJS.ha.call(null,"%s__via__%s",d,a);break a}a=null}return $CLJS.n(a)?$CLJS.k1(a,$CLJS.U.h(b)):$CLJS.U.h(b)};
$CLJS.m1=function(a,b,c,d){var e=$CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h($CLJS.XJ),c);return $CLJS.Wf.j($CLJS.xf,$CLJS.Zj.l($CLJS.ef($CLJS.LY),$CLJS.ef($CLJS.Ei),$CLJS.ef($CLJS.Zj.g($CLJS.Yk,$CLJS.Ei)),$CLJS.H([$CLJS.cf.h(function(f){f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.Ei),l=$CLJS.J.g(f,$CLJS.LY);return $CLJS.R.l($CLJS.$0(a,l),$CLJS.C_,k,$CLJS.H([$CLJS.D_,$CLJS.eZ.h(f)]))}),$CLJS.ak.h(function(f){return $CLJS.Cd(e,$CLJS.XJ.h(f))}),$CLJS.eD(function(f){f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.XJ),l=$CLJS.J.g(f,
$CLJS.C_),m=$CLJS.J.g(f,$CLJS.D_);f=$CLJS.d_(a,k);return function v(u){return new $CLJS.ie(null,function(){for(;;){var x=$CLJS.y(u);if(x){if($CLJS.vd(x)){var A=$CLJS.ic(x),C=$CLJS.D(A),G=$CLJS.le(C);return function(){for(var X=0;;)if(X<C){var T=$CLJS.hd(A,X),da=$CLJS.R.l(T,$CLJS.WJ,l,$CLJS.H([$CLJS.z_,m,$CLJS.KJ,$CLJS.GY,$CLJS.uY,$CLJS.U.h(T)]));T=G;var oa=$CLJS.R,Ma=oa.j,sb=da;da=$CLJS.l1(a,da);da=d.h?d.h(da):d.call(null,da);oa=Ma.call(oa,sb,$CLJS.KY,da);T.add(oa);X+=1}else return!0}()?$CLJS.oe($CLJS.qe(G),
v($CLJS.jc(x))):$CLJS.oe($CLJS.qe(G),null)}var K=$CLJS.z(x),S=$CLJS.R.l(K,$CLJS.WJ,l,$CLJS.H([$CLJS.z_,m,$CLJS.KJ,$CLJS.GY,$CLJS.uY,$CLJS.U.h(K)]));return $CLJS.ae($CLJS.R.j(S,$CLJS.KY,function(){var X=$CLJS.l1(a,S);return d.h?d.h(X):d.call(null,X)}()),v($CLJS.Hc(x)))}return null}},null,null)}($CLJS.X_.v(a,b,f,new $CLJS.h(null,2,[$CLJS.H_,d,$CLJS.B_,!1],null)))})])),c)};$CLJS.n1=function(a,b){return $CLJS.d1.g(a,b)};$CLJS.o1=function(a){return $CLJS.fD("Question {0}",$CLJS.H([$CLJS.xh.l($CLJS.H([a]))]))};
$CLJS.p1=function(a,b){var c=$CLJS.QD.h(b);$CLJS.n(c)||(c=$CLJS.RY.h(b),$CLJS.n(c)||(c=$CLJS.QY.h(b),c=null!=c?$CLJS.W_.h($CLJS.n1(a,$CLJS.WX.h(c))):null));return $CLJS.n(c)?(c=$CLJS.Be($CLJS.sd(c)?$CLJS.BV.h(c):$CLJS.rd(c)?c:null),$CLJS.n(c)?$CLJS.bk.g($CLJS.Xs(Rpa,a,b),c):null):null};$CLJS.q1=new $CLJS.M("metabase.lib.card","force-broken-id-refs","metabase.lib.card/force-broken-id-refs",-868945458);$CLJS.r1=new $CLJS.M(null,"field-ref","field-ref",-1699303428);$CLJS.M_.m(null,$CLJS.fK,function(a,b,c){return $CLJS.zz($CLJS.kD,$CLJS.U)(c)});$CLJS.T_.m(null,$CLJS.fK,function(a,b,c){a=$CLJS.O(c);b=$CLJS.J.g(a,$CLJS.U);c=$CLJS.J.g(a,$CLJS.kD);return $CLJS.Ra(c)?$CLJS.R.j(a,$CLJS.kD,$CLJS.h1.g($CLJS.g1,b)):a});
$CLJS.X_.m(null,$CLJS.fK,function(a,b,c,d){c=$CLJS.O(c);var e=$CLJS.J.g(c,$CLJS.QD),f=$CLJS.J.g(c,$CLJS.RY);d=$CLJS.O(d);var k=$CLJS.J.g(d,$CLJS.B_),l=$CLJS.J.g(d,$CLJS.H_);return $CLJS.bf.g($CLJS.W_.v(a,b,c,d),$CLJS.n(k)?$CLJS.m1(a,b,$CLJS.bf.g(e,f),l):null)});$CLJS.P_.m(null,$CLJS.NU,function(a,b){var c=$CLJS.EV(a,b);c=$CLJS.O(c);c=$CLJS.J.g(c,$CLJS.NU);if($CLJS.n(c)){var d=$CLJS.f_(a,c);a=$CLJS.n(d)?$CLJS.l_.v(a,b,d,$CLJS.m_):null;return $CLJS.n(a)?a:$CLJS.o1(c)}return null});
var Rpa=function(){function a(d,e,f){var k=$CLJS.Sj.g($CLJS.Ez(f,$CLJS.eA),$CLJS.r1);f=$CLJS.rk.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.tA,$CLJS.Zi,$CLJS.Vs,$CLJS.IJ],null),function(){var l=$CLJS.Ei.h(k);if($CLJS.n(l))try{return $CLJS.$0(d,l)}catch(m){return null}else return null}(),k,new $CLJS.h(null,3,[$CLJS.Vs,$CLJS.IJ,$CLJS.KJ,$CLJS.OY,$CLJS.uY,$CLJS.zz($CLJS.uY,$CLJS.U)(k)],null)]));f=$CLJS.n(e)?$CLJS.R.j(f,$CLJS.YY,$CLJS.W0(e)):f;e=$CLJS.n($CLJS.n(!0)?$CLJS.Ra(e)||$CLJS.Ra($CLJS.CY.h($CLJS.f_(d,
$CLJS.W0(e)))):!0)?$CLJS.R.j(f,$CLJS.q1,!0):f;return $CLJS.Yj.g($CLJS.oj.h(k),$CLJS.Xh)?$CLJS.R.j(e,$CLJS.LY,null):e}function b(d,e){return c.j?c.j(d,null,e):c.call(null,d,null,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.V_.m(null,$CLJS.fK,function(a,b,c,d){b=$CLJS.O(d);var e=$CLJS.J.g(b,$CLJS.H_);return $CLJS.bk.g(function(f){var k=$CLJS.pB($CLJS.KY,$CLJS.uY,$CLJS.U)(f);return $CLJS.R.j(f,$CLJS.KY,e.h?e.h(k):e.call(null,k))},$CLJS.p1(a,c))});