import PropTypes from "prop-types";
import { Fragment, useCallback, useState } from "react";
import { t } from "ttag";

import FileRemovedModal from "metabase/browse/components/TableBrowser/Modal/FileDeleteModal";
import BrowserCrumbs from "metabase/components/BrowserCrumbs";
import EntityItem from "metabase/components/EntityItem";
import Database from "metabase/entities/databases";
import { color } from "metabase/lib/colors";
import { isSyncInProgress } from "metabase/lib/syncing";
import * as Urls from "metabase/lib/urls";
import { Icon } from "metabase/ui";
import {
  isVirtualCardId,
  SAVED_QUESTIONS_VIRTUAL_DB_ID,
} from "metabase-lib/metadata/utils/saved-questions";

import { trackTableClick } from "../../analytics";
import { BrowseHeaderContent } from "../BrowseHeader.styled";

import {
  TableAction,
  TableActionLink,
  TableCard,
  TableGrid,
  TableGridItem,
  TableLink,
} from "./TableBrowser.styled";

const checkPrefix = (str, startStr) => {
  return str.startsWith(startStr);
};

const propTypes = {
  database: PropTypes.object,
  tables: PropTypes.array.isRequired,
  getTableUrl: PropTypes.func.isRequired,
  metadata: PropTypes.object,
  dbId: PropTypes.number,
  schemaName: PropTypes.string,
  xraysEnabled: PropTypes.bool,
  showSchemaInHeader: PropTypes.bool,
  user: PropTypes.object,
};

const TableBrowser = ({
  database,
  tables,
  getTableUrl,
  metadata,
  dbId,
  schemaName,
  xraysEnabled,
  showSchemaInHeader = true,
  user,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [tableId, setTableId] = useState(undefined);

  const handleRemoveModal = removingTableId => {
    setTableId(removingTableId);
    setOpenModal(true);
  };

  const handleModalClose = useCallback(() => {
    setOpenModal(false);
    setTableId(undefined);
  }, []);

  return (
    <>
      <BrowseHeaderContent>
        {openModal && (
          <FileRemovedModal
            handleModalClose={handleModalClose}
            redirectPage={`browse/${dbId}/schema/${schemaName}/${tableId}`}
          />
        )}

        <BrowserCrumbs
          crumbs={[
            { title: t`Databases`, to: "/browse/databases" },
            getDatabaseCrumbs(dbId),
            showSchemaInHeader && { title: schemaName },
          ]}
        />
      </BrowseHeaderContent>
      <TableGrid>
        {tables.map(table => (
          <TableGridItem key={table.id}>
            <TableCard hoverable={!isSyncInProgress(table)}>
              <TableLink
                to={
                  !isSyncInProgress(table) ? getTableUrl(table, metadata) : ""
                }
                onClick={() => trackTableClick(table.id)}
              >
                <TableBrowserItem
                  database={database}
                  table={table}
                  dbId={dbId}
                  xraysEnabled={xraysEnabled}
                  openRemovedModal={handleRemoveModal}
                  showRemoveIcon={checkPrefix(table.name, `${user.id}_`)}
                />
              </TableLink>
            </TableCard>
          </TableGridItem>
        ))}
      </TableGrid>
    </>
  );
};

TableBrowser.propTypes = propTypes;

const itemPropTypes = {
  database: PropTypes.object,
  table: PropTypes.object.isRequired,
  dbId: PropTypes.number,
  xraysEnabled: PropTypes.bool,
  openRemovedModal: PropTypes.func,
  showRemoveIcon: PropTypes.bool,
};

const TableBrowserItem = ({
  database,
  table,
  dbId,
  xraysEnabled,
  openRemovedModal,
  showRemoveIcon,
}) => {
  const isVirtual = isVirtualCardId(table.id);
  const isLoading = isSyncInProgress(table);

  return (
    <EntityItem
      item={table}
      name={table.display_name || table.name}
      iconName="table"
      iconColor={color("accent2")}
      loading={isLoading}
      disabled={isLoading}
      buttons={
        !isLoading &&
        !isVirtual && (
          <TableBrowserItemButtons
            tableId={table.id}
            dbId={dbId}
            xraysEnabled={xraysEnabled}
            openRemovedModal={openRemovedModal}
            showRemoveIcon={showRemoveIcon}
          />
        )
      }
    />
  );
};

TableBrowserItem.propTypes = itemPropTypes;

const itemButtonsPropTypes = {
  tableId: PropTypes.number,
  dbId: PropTypes.number,
  xraysEnabled: PropTypes.bool,
  PropTypes: PropTypes.string,
  openRemovedModal: PropTypes.func,
  showRemoveIcon: PropTypes.bool,
};

const TableBrowserItemButtons = ({
  tableId,
  dbId,
  xraysEnabled,
  openRemovedModal,
  showRemoveIcon,
}) => {
  return (
    <Fragment>
      {xraysEnabled && (
        <TableActionLink to={`/auto/dashboard/table/${tableId}`}>
          <Icon
            name="bolt_filled"
            tooltip={t`X-ray this table`}
            color={color("warning")}
          />
        </TableActionLink>
      )}
      <TableActionLink to={`/reference/databases/${dbId}/tables/${tableId}`}>
        <Icon
          name="reference"
          tooltip={t`Learn about this table`}
          color={color("text-medium")}
        />
      </TableActionLink>
      {showRemoveIcon && (
        <TableAction>
          <Icon
            onClick={e => {
              e.preventDefault();
              openRemovedModal(tableId);
            }}
            name="trash"
            size={16}
            tooltip={t`Remove this table`}
            color={color("danger")}
          />
        </TableAction>
      )}
    </Fragment>
  );
};

TableBrowserItemButtons.propTypes = itemButtonsPropTypes;

const getDatabaseCrumbs = dbId => {
  if (dbId === SAVED_QUESTIONS_VIRTUAL_DB_ID) {
    return {
      title: t`Saved Questions`,
      to: Urls.browseDatabase({ id: SAVED_QUESTIONS_VIRTUAL_DB_ID }),
    };
  } else {
    return {
      title: <Database.Link id={dbId} />,
    };
  }
};

export default TableBrowser;
