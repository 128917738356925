import { useState, useEffect } from "react";
import { t } from "ttag";
import { ChartSettingInputNumeric } from "../ChartSettingInputNumeric";
import ChartSettingToggle from "../ChartSettingToggle";
import {
  Container,
  InputWrapper,
  LabelAutoSize,
  SelectInputWrapper,
  ToggleInputWrapper,
  Wrapper,
} from "./CardBackgroundImageDimensions.styled";

import CardBackgroundImageUnitSelect, {
  BackgrounImageUnit,
} from "./UnitSelect";

interface ChartSettingInputProps {
  value: string;
  onChange: (value: string) => void;
  id?: string;
  onChangeSettings: () => void;
  enabledAuto?: boolean;
}

const LabelAutoSizeComponent = () => <LabelAutoSize>{t`Auto`}</LabelAutoSize>;

interface Dimension {
  xVal: number | "auto";
  yVal: number | "auto";
  xUnit: BackgrounImageUnit;
  yUnit: BackgrounImageUnit;
}

function parseBackgroundSizeOrPositionString(input: string): Dimension {
  const values = input.split(" ");
  if (values.length !== 2) {
    return { xVal: "auto", yVal: "auto", xUnit: "%", yUnit: "%" };
  }
  const xUnit = values[0].includes("px")
    ? "px"
    : values[0].includes("%")
    ? "%"
    : "%";
  const yUnit = values[1].includes("px")
    ? "px"
    : values[1].includes("%")
    ? "%"
    : "%";

  const xVal = values[0] === "auto" ? "auto" : parseFloat(values[0]);
  const yVal = values[1] === "auto" ? "auto" : parseFloat(values[1]);
  return { xVal, yVal, xUnit, yUnit };
}

const CardBackgroundImageDimensions = ({
  value = "",
  onChange,
  enabledAuto = false,
  ...props
}: ChartSettingInputProps) => {
  const { xVal, yVal, xUnit, yUnit } =
    parseBackgroundSizeOrPositionString(value);

  const [xValue, setXValue] = useState<number>(
    typeof xVal === "number" ? xVal : 50,
  );
  const [yValue, setYValue] = useState<number>(
    typeof yVal === "number" ? yVal : 50,
  );
  const [isAutoX, setIsAutoX] = useState(
    enabledAuto && xVal === "auto" ? true : false,
  );
  const [isAutoY, setIsAutoY] = useState(
    enabledAuto && yVal === "auto" ? true : false,
  );

  const [horizontalUnit, setHorizontalUnit] =
    useState<BackgrounImageUnit>(xUnit);
  const [verticalUnit, setVerticalUnit] = useState<BackgrounImageUnit>(yUnit);

  useEffect(() => {
    const parsedValue = value.split(" ");
    if (parsedValue.length === 2) {
      const x = parsedValue[0];
      const y = parsedValue[1];
      if (!isNaN(parseInt(x))) {
        setXValue(parseInt(x));
      }
      if (!isNaN(parseInt(y))) {
        setYValue(parseInt(y));
      }
    }
  }, [value]);

  const toggleX = () => {
    setIsAutoX(prev => !prev);
  };

  const toggleY = () => {
    setIsAutoY(prev => !prev);
  };

  useEffect(() => {
    if (!isAutoX && !isAutoY) {
      onChange(`${xValue}${horizontalUnit} ${yValue}${verticalUnit}`);
    } else if (!isAutoX && isAutoY) {
      onChange(`${xValue}${horizontalUnit} auto`);
    } else if (!isAutoY && isAutoX) {
      onChange(`auto ${yValue}${verticalUnit}`);
    } else {
      onChange(`auto auto`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xValue, yValue, isAutoX, isAutoY, horizontalUnit, verticalUnit]);

  return (
    <Container>
      <Wrapper>
        <div>{t`Horizontal`}:</div>
        <InputWrapper>
          {isAutoX ? (
            <LabelAutoSizeComponent />
          ) : (
            <ChartSettingInputNumeric
              {...props}
              data-testid={`${props.id}-x`}
              value={xValue}
              onChange={(newValue: number | undefined) =>
                setXValue(newValue || 0)
              }
            />
          )}
        </InputWrapper>
        <SelectInputWrapper>
          <CardBackgroundImageUnitSelect
            id={`${props.id}-x-dimension`}
            value={horizontalUnit}
            onChange={setHorizontalUnit}
          />
        </SelectInputWrapper>
        {enabledAuto && (
          <ToggleInputWrapper>
            <div>
              <LabelAutoSizeComponent />
            </div>
            <ChartSettingToggle
              id={`${props.id}-togglex`}
              value={isAutoX}
              onChange={toggleX}
            />
          </ToggleInputWrapper>
        )}
      </Wrapper>
      <Wrapper>
        <div>{t`Vertical`}:</div>
        <InputWrapper>
          {isAutoY ? (
            <LabelAutoSizeComponent />
          ) : (
            <ChartSettingInputNumeric
              {...props}
              data-testid={`${props.id}-y`}
              value={yValue}
              onChange={(newValue: number | undefined) =>
                setYValue(newValue || 0)
              }
            />
          )}
        </InputWrapper>
        <SelectInputWrapper>
          <CardBackgroundImageUnitSelect
            id={`${props.id}-y-dimension`}
            value={verticalUnit}
            onChange={setVerticalUnit}
          />
        </SelectInputWrapper>
        {enabledAuto && (
          <ToggleInputWrapper>
            <div>
              <LabelAutoSizeComponent />
            </div>
            <ChartSettingToggle
              id={`${props.id}-toggley`}
              value={isAutoY}
              onChange={toggleY}
            />
          </ToggleInputWrapper>
        )}
      </Wrapper>
    </Container>
  );
};

// eslint-disable-next-line import/no-default-export
export default CardBackgroundImageDimensions;
