import React from "react";
import PropTypes from "prop-types";
import TableRemover from "metabase/browse/components/TableBrowser/Modal";

const propTypes = {
  onShowToast: PropTypes.func,
};

const TableBrowser = ({onShowToast}) => {
  return (
    <div>
      <TableRemover onShowToast={onShowToast} />
    </div>
  );
};

TableBrowser.propTypes = propTypes;

export default TableBrowser;
