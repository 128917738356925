import cx from "classnames";
import type { ChangeEvent } from "react";
import { t } from "ttag";

import { Option } from "metabase/core/components/Select";

import { SelectWithHighlightingIcon } from "../ChartSettingSelect.styled";

export type BackgrounImageUnit = "%" | "px";
interface UnitSelectProps {
  value: BackgrounImageUnit;
  onChange: (value: BackgrounImageUnit) => void;
  id: string;
}

const CardBackgroundImageUnitSelect = ({
  value,
  onChange,
  id,
}: UnitSelectProps) => {
  const options = [
    { name: t`px`, value: "px" },
    { name: t`%`, value: "%" },
  ];

  return (
    <SelectWithHighlightingIcon
      className={cx("block")}
      disabled={
        options.length === 0 ||
        (options.length === 1 && options[0].value === value)
      }
      value={value}
      onChange={(e: { target: { value: ChangeEvent<HTMLSelectElement> } }) =>
        onChange(e.target.value as unknown as BackgrounImageUnit)
      }
      buttonProps={{
        id: id,
        style: { minWidth: "100%" },
      }}
    >
      {options.map(option => (
        <Option key={option.value} name={option.name} value={option.value}>
          {option.name}
        </Option>
      ))}
    </SelectWithHighlightingIcon>
  );
};

// eslint-disable-next-line import/no-default-export
export default CardBackgroundImageUnitSelect;
