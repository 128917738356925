import { getAccentColors } from "metabase/lib/colors/groups";
import ColorSelector from "metabase/core/components/ColorSelector";
import { getSettings } from "metabase/selectors/settings";
import { useSelector } from "metabase/lib/redux";

interface Props {
  value: string;
  onChange: (val: string | null) => void;
}

function ChartSettingTextColorPickerWithStoreSettingsBackgroundSync({
  value,
  onChange,
}: Props) {
  const settings = useSelector(getSettings);
  const defaultDashboardTextColor =
    settings?.["dashboard-default-visualization-settings"]?.[
      "dashboard-default-text-color"
    ];
  return (
    <ColorSelector
      value={value || defaultDashboardTextColor}
      colors={getAccentColors()}
      onChange={onChange}
    />
  );
}

// eslint-disable-next-line import/no-default-export
export default ChartSettingTextColorPickerWithStoreSettingsBackgroundSync;
