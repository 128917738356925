/* eslint-disable react/prop-types */
import React from "react";

import * as MetabaseAnalytics from "metabase/lib/analytics";
import { isNumeric, isString } from "metabase-lib/types/utils/isa";

import { RuleEditor } from "./RuleEditor";
import { RuleListing } from "./RuleListing";

import { COLORS, COLOR_RANGES } from "./colors";

export const DEFAULTS_BY_TYPE = {
  single: {
    option: null,
    type: "single",
    operator: "=",
    value: "",
    color: COLORS[0],
  },
  range: {
    option: null,
    type: "range",
    colors: COLOR_RANGES[0],
    min_type: null,
    max_type: null,
    min_value: 0,
    max_value: 100,
  },
};

// predicate for columns that can be formatted
export const isFormattable = field => isNumeric(field) || isString(field);

export default class ChartSettingsFormatting extends React.Component {
  state = {
    editingRule: null,
    editingRuleIsNew: null,
  };

  render() {
    const { value, onChange, options } = this.props;
    const { editingRule, editingRuleIsNew } = this.state;

    if (editingRule !== null && value[editingRule]) {
      return (
        <RuleEditor
          rule={value[editingRule]}
          columns={this.props.columns}
          options={options}
          isNew={editingRuleIsNew}
          onChange={rule =>
            onChange([
              ...value.slice(0, editingRule),
              rule,
              ...value.slice(editingRule + 1),
            ])
          }
          onRemove={() => {
            onChange([
              ...value.slice(0, editingRule),
              ...value.slice(editingRule + 1),
            ]);
            this.setState({ editingRule: null, editingRuleIsNew: null });
          }}
          onDone={() => {
            this.setState({ editingRule: null, editingRuleIsNew: null });
          }}
        />
      );
    } else {
      return (
        <RuleListing
          rules={value}
          options={options}
          onEdit={index => {
            this.setState({ editingRule: index, editingRuleIsNew: false });
          }}
          onAdd={() => {
            onChange([
              {
                ...DEFAULTS_BY_TYPE["single"],
                // if there's a single column use that by default
                option: options.length === 1 ? options[0].name : null,
              },
              ...value,
            ]);
            this.setState({ editingRule: 0, editingRuleIsNew: true });
          }}
          onRemove={index => {
            onChange([...value.slice(0, index), ...value.slice(index + 1)]);
            MetabaseAnalytics.trackStructEvent(
              "Chart Settings",
              "Table Formatting",
              "Remove Rule",
            );
          }}
          onMove={(from, to) => {
            const newValue = [...value];
            newValue.splice(to, 0, newValue.splice(from, 1)[0]);
            onChange(newValue);
            MetabaseAnalytics.trackStructEvent(
              "Chart Settings",
              "Table Formatting",
              "Move Rule",
            );
          }}
        />
      );
    }
  }
}
