interface Props {
  [key: string]: any;
}

function PluginPlaceholder(_props: Props) {
  return null;
}

// eslint-disable-next-line import/no-default-export -- deprecated usage
export default PluginPlaceholder;
