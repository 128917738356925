import { useCallback, useState } from "react";

import {
  OptionIconContainer,
  OptionList,
  OptionRoot,
  OptionText,
} from "metabase/query_builder/components/view/sidebars/ChartTypeSidebar.styled";
import { Icon } from "metabase/ui";
import visualizations from "metabase/visualizations";
import type {
  WidgetsNames} from "metabase/visualizations/lib/settings/widgetsSettings";
import {
  WIDGETS_SETTINGS
} from "metabase/visualizations/lib/settings/widgetsSettings";
import type { Visualization } from "metabase/visualizations/types";

import type { DashboardInfoSidebarTabProps } from "./DashboardInfoSidebar";
import { ContentSection } from "./DashboardInfoSidebar.styled";
import { WidgetSettings } from "./WidgetSettings";


interface ChartTypeOptionProps {
  isSelected: boolean;
  isSensible: boolean;
  onClick: (e: React.MouseEvent) => void;
  visualization: Visualization;
}

const WidgetTypeOption = ({
  visualization,
  isSelected,
  isSensible,
  onClick,
}: ChartTypeOptionProps) => (
  <OptionRoot
    isSelected={isSelected}
    data-testid={`${visualization.uiName}-container`}
    role="option"
    aria-selected={isSelected}
    style={{ width: "33%" }}
  >
    <OptionIconContainer
      onClick={onClick}
      data-is-sensible={isSensible}
      data-testid={`${visualization.uiName}-button`}
    >
      <Icon name={visualization.iconName} size={20} />
    </OptionIconContainer>
    <OptionText>{visualization.uiName}</OptionText>
  </OptionRoot>
);

export const DashboardVisualizationSettings = ({
  dashboard,
}: DashboardInfoSidebarTabProps) => {
  const [selectedWidget, setSelectedWidget] = useState<null | WidgetsNames>(
    null,
  );

  const resetSelectedWidget = useCallback(() => {
    setSelectedWidget(null);
  }, []);

  const visualizationWidgets = Array.from(visualizations).map(
    ([vizType]) => (vizType as WidgetsNames),
  );

  return (
    <ContentSection style={{ padding: 0 }}>
      {!selectedWidget && (
        <OptionList data-testid="display-options-sensible">
          {visualizationWidgets.map(type => {
            const visualization = visualizations.get(type);
            if (!visualization) {
              return null;
            }
            const isVisualizationHaveDashboardSettings =
              !!WIDGETS_SETTINGS[type as WidgetsNames];

            return (
              isVisualizationHaveDashboardSettings && (
                <WidgetTypeOption
                  key={type}
                  visualization={visualization}
                  isSelected={false}
                  isSensible
                  onClick={() => {
                    setSelectedWidget(type);
                  }}
                />
              )
            );
          })}
        </OptionList>
      )}
      {selectedWidget && (
        <WidgetSettings
          isNeedHeader
          resetSelectedWidget={resetSelectedWidget}
          selectedWidget={selectedWidget}
        />
      )}
    </ContentSection>
  );
};
