import ColorSelector from "metabase/core/components/ColorSelector";
import { getAccentColors } from "metabase/lib/colors/groups";

interface SettingInputProps {
  setting: {
    key: string;
    value: string | null;
  };
  onChange: (value: string | number | null) => void;
}

const DashboardTextColorWidget = ({
  setting,
  onChange,
}: SettingInputProps) => {
  return (
    <ColorSelector
      value={setting.value || "white"}
      colors={getAccentColors()}
      onChange={onChange}
      id={setting.key}
    />
  );
};
// eslint-disable-next-line import/no-default-export
export default DashboardTextColorWidget;
