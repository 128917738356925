import type { HTMLAttributes, Ref } from "react";
import { forwardRef, useCallback, useMemo, useState } from "react";
import { t } from "ttag";
import _ from "underscore";

import ColorPill from "metabase/core/components/ColorPill";
import { getColorScale } from "metabase/lib/colors/scales";

import Toggle from "../Toggle";

import {
  PopoverColorList,
  PopoverColorRangeList,
  PopoverDivider,
  PopoverRoot,
} from "./ColorRangePopover.styled";
import ColorRangeToggle from "./ColorRangeToggle";

export interface ColorRangeContentProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "onChange"> {
  initialValue: string[];
  colors: string[];
  colorRanges?: string[][];
  colorMapping?: Record<string, string[]>;
  isQuantile?: boolean;
  onChange?: (newValue: string[]) => void;
  onClose?: () => void;
}

const ColorSelectorContent = forwardRef(function ColorRangeSelector(
  {
    initialValue,
    colors,
    colorRanges = [],
    colorMapping: customColorMapping,
    isQuantile,
    onChange,
    onClose,
    ...props
  }: ColorRangeContentProps,
  ref: Ref<HTMLDivElement>,
) {
  const [isCustomGradient, setIsCustomGradient] = useState(
    initialValue.length === 3,
  );
  const colorMapping = useMemo(() => {
    return customColorMapping ?? getDefaultColorMapping(colors);
  }, [colors, customColorMapping]);

  const [isInverted, setIsInverted] = useState(() =>
    getDefaultIsInverted(initialValue, colorMapping),
  );

  const [color, setColor] = useState(
    () => getDefaultColor(initialValue, colors, colorMapping) || "#509EE3",
  );

  const scale = useMemo(() => {
    return getColorScale(
      [0, 4],
      getColorRange(color, colorMapping, isInverted),
      isQuantile,
    );
  }, [color, colorMapping, isInverted, isQuantile]);

  const customValue = useMemo(() => {
    const defaultGradient = _.range(0, 5).map(section => scale(section));
    const maxIndex = defaultGradient.length - 1;

    return initialValue.length === 3
      ? initialValue
      : [
          defaultGradient[0],
          defaultGradient[Math.floor(maxIndex / 2)],
          defaultGradient[maxIndex],
        ];
  }, [initialValue, scale]);

  const [value, setValue] = useState(() =>
    isCustomGradient
      ? customValue
      : getColorRange(color, colorMapping, isInverted),
  );

  const [activeSection, setActiveSection] = useState(0);

  const handleColorSelect = useCallback(
    (newColor: string) => {
      const newValue = isCustomGradient
        ? [...value]
        : getColorRange(newColor, colorMapping, isInverted);
      if (isCustomGradient) {
        newValue[activeSection] = newColor;
      }
      setColor(newColor);
      setValue(newValue);
      onChange?.(newValue);
    },
    [
      activeSection,
      colorMapping,
      isCustomGradient,
      isInverted,
      onChange,
      value,
    ],
  );

  const handleColorRangeSelect = useCallback(
    (newColorRange: string[]) => {
      const newValue = isInverted
        ? [...newColorRange].reverse()
        : newColorRange;

      setColor("");
      setValue(newValue);
      onChange?.(newValue);
    },
    [isInverted, onChange],
  );

  const handleToggleInvertedClick = useCallback(() => {
    const newValue = isCustomGradient
      ? [...value].reverse()
      : getColorRange(color, colorMapping, isInverted);
    setIsInverted(!isInverted);
    setValue(newValue);
    onChange?.(newValue);
  }, [color, colorMapping, isCustomGradient, isInverted, onChange, value]);

  return (
    <PopoverRoot {...props} ref={ref}>
      <PopoverColorList>
        {colors.map((value, index) => (
          <ColorPill
            key={index}
            color={value}
            isSelected={value === color}
            onSelect={handleColorSelect}
          />
        ))}
      </PopoverColorList>
      <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
        <h3 className="mt1 mb1">{t`Custom gradient`}</h3>
        <Toggle
          value={isCustomGradient}
          onChange={() => {
            setIsCustomGradient(value => !value);
            if (value) {
              const defaultValue = getColorRange(
                color,
                colorMapping,
                isInverted,
              );
              setValue(defaultValue);
              onChange?.(defaultValue);
            }
          }}
        />
      </div>
      <ColorRangeToggle
        value={value}
        isQuantile={isQuantile}
        onToggleClick={handleToggleInvertedClick}
        activeSection={activeSection}
        setActiveSection={setActiveSection}
        allowChooseColor={isCustomGradient}
      />
      {colorRanges.length > 0 && <PopoverDivider />}
      <PopoverColorRangeList>
        {colorRanges?.map((range, index) => (
          <ColorRangeToggle
            key={index}
            value={range}
            isQuantile={isQuantile}
            onToggleClick={handleToggleInvertedClick}
            onColorRangeSelect={handleColorRangeSelect}
          />
        ))}
      </PopoverColorRangeList>
    </PopoverRoot>
  );
});

const getColorRange = (
  color: string,
  colorMapping: Record<string, string[]>,
  isInverted = false,
) => {
  if (isInverted) {
    return [...colorMapping[color]].reverse();
  } else {
    return colorMapping[color];
  }
};

const getDefaultColor = (
  value: string[],
  colors: string[],
  colorMapping: Record<string, string[]>,
) => {
  return Object.entries(colorMapping).reduce((selection, [color, range]) => {
    if (_.isEqual(value, range)) {
      return color;
    } else if (_.isEqual(value, [...range].reverse())) {
      return color;
    } else {
      return selection;
    }
  }, "" as string);
};

const getDefaultColorMapping = (colors: string[]) => {
  return Object.fromEntries(colors.map(color => [color, ["white", color]]));
};

const getDefaultIsInverted = (
  value: string[],
  colorMapping: Record<string, string[]>,
) => {
  return Object.values(colorMapping).some(range => {
    return _.isEqual(value, [...range].reverse());
  });
};

// eslint-disable-next-line import/no-default-export -- deprecated usage
export default ColorSelectorContent;
