import { useState } from "react";
import { t, jt } from "ttag";

import ActionButton from "metabase/components/ActionButton";
import TippyPopover from "metabase/components/Popover/TippyPopover";
import ExternalLink from "metabase/core/components/ExternalLink";


// import { getModelCacheSchemaName } from "metabase/lib/data-modeling/utils";
import MetabaseSettings from "metabase/lib/settings";
import { MetabaseApi } from "metabase/services";
// import Database from "metabase-lib/lib/metadata/Database";
import type Database from "metabase-lib/metadata/Database";

import {
  ControlContainer,
  HoverableIcon,
  PopoverContent,
  FeatureTitle,
  FeatureDescriptionText,
  ErrorMessage,
} from "./EnableCommonCashDbStoreControl.styled";
//import {getModelCacheSchemaName} from "metabase-lib/metadata/utils/models";

interface Props {
  database: Database;
}

interface ErrorResponse {
  data?: {
    message?: string;
  };
}

function FeatureDescription({ schemaName }: { schemaName: string }) {
  const docsLink = (
    <ExternalLink
      key="model-caching-link"
      href={MetabaseSettings.docsUrl("data-modeling/models")}
    >{t`Learn more.`}</ExternalLink>
  );
  return (
    <PopoverContent>
      <FeatureTitle>{t`Enable common cash db  store`}</FeatureTitle>
      <FeatureDescriptionText>{jt`The users will be able to load their data from another db's into the database. To enable it, you need to grant this connection credential read and write permissions on the schema or grant create schema permissions. ${docsLink}`}</FeatureDescriptionText>
    </PopoverContent>
  );
}

function isLackPermissionsError(response: ErrorResponse) {
  return response?.data?.message?.startsWith("Lack permissions");
}

const EnableCommonCashDbStoreControl = ({ database }: Props) => {
  const [error, setError] = useState<string | null>(null);
  const [isEnabled, setIsEnabled] = useState(
    database.isCommonCashDbStoreEnabled(),
  );

  const databaseId = database.id;

  const normalText = isEnabled
    ? t`Disable common cash db store`
    : t`Enable common cash db store`;

  //const cacheSchemaName = getModelCacheSchemaName(databaseId);

  const handleCommonCashDbStoreChange = async () => {
    try {
      if (isEnabled) {
        await MetabaseApi.db_disable_common_cashdb_store({ dbId: databaseId });
      } else {
        await MetabaseApi.db_enable_common_cashdb_store({ dbId: databaseId });
      }
      setIsEnabled(prev => !prev);
    } catch (error) {
      const response = error as ErrorResponse;
      if (isLackPermissionsError(response)) {
        setError(
          t`For models to be cached, the user should have create table permission or create schema permission in this database.`,
        );
      } else {
        setError(response.data?.message || t`An error occurred`);
      }
      throw error;
    }
  };

  return (
    <div>
      <ControlContainer>
        <ActionButton
          className="Button"
          normalText={normalText}
          failedText={t`Failed`}
          successText={t`Done`}
          actionFn={handleCommonCashDbStoreChange}
        />
        <TippyPopover
          placement="right-end"
          content={<FeatureDescription schemaName={""} />}
        >
          <HoverableIcon name="info" />
        </TippyPopover>
      </ControlContainer>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default EnableCommonCashDbStoreControl;
