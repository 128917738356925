import { t } from "ttag";
import validate from "metabase/lib/validate";
import { LoginAttributesWidget } from "metabase/plugins/components/LoginAttributesWidget";

export default {
  admin: {
    fields: [
      {
        name: "name",
        title: t`Group name`,
        placeholder: t`Something like "Marketing"`,
        autoFocus: true,
        validate: validate.minLength(1),
      },
      {
        name: "group_attributes",
        type: LoginAttributesWidget,
      },
    ],
  },
};
