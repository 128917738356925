/* eslint-disable react/prop-types */
import React from "react";

import { t } from "ttag";

import _ from "underscore";

import Button from "metabase/core/components/Button";
import Select, { Option } from "metabase/core/components/Select";
import Radio from "metabase/core/components/Radio";
import ColorSelector from "metabase/core/components/ColorSelector";
import ColorRangeSelector from "metabase/core/components/ColorRangeSelector";

import NumericInput from "metabase/components/NumericInput";

import * as MetabaseAnalytics from "metabase/lib/analytics";
import { isNumeric } from "metabase-lib/types/utils/isa";

import { DEFAULTS_BY_TYPE } from "./ChartSettingsFormatting.jsx";
import { NUMBER_OPERATOR_NAMES, STRING_OPERATOR_NAMES } from "./operators.js";

import { COLORS, COLOR_RANGES } from "./colors.js";

const INPUT_CLASSNAME = "AdminSelect input mt1 full";

export const RuleEditor = ({
  rule,
  columns,
  options,
  isNew,
  onChange,
  onDone,
  onRemove,
}) => {
  const selectedOptions = [_.findWhere(columns, { name: rule.option })];

  // const isStringRule =
  //   selectedOptions.length > 0 && _.all(selectedOptions, isString);
  const isNumericRule =
    selectedOptions.length > 0 && _.all(selectedOptions, isNumeric);

  const hasOperand =
    rule.operator !== "is-null" && rule.operator !== "not-null";

  return (
    <div>
      <h3 className="mb1">{t`Which options should be affected?`}</h3>
      <Select
        value={rule.option}
        onChange={e =>
          onChange({
            ...rule,
            option: isNumericRule ? Number(e.target.value) : e.target.value,
          })
        }
        isInitiallyOpen={rule.options === null}
        placeholder="Choose a option"
      >
        {options.map(option => (
          <Option
            key={option.value}
            value={option.value}
            // disabled={
            //   (isStringRule && !isString(option)) ||
            //   (isNumericRule && !isNumeric(option))
            // }
          >
            {option.name}
          </Option>
        ))}
      </Select>
      {isNumericRule && (
        <div>
          <h3 className="mt3 mb1">{t`Formatting style`}</h3>
          <Radio
            value={rule.type}
            options={[
              { name: t`Single color`, value: "single" },
              { name: t`Color range`, value: "range" },
            ]}
            onChange={type =>
              onChange({ ...DEFAULTS_BY_TYPE[type], ...rule, type })
            }
            vertical
          />
        </div>
      )}
      {rule.type === "single" ? (
        <div>
          <h3 className="mt3 mb1">{t`When a bubble in this option…`}</h3>
          <Select
            value={rule.operator}
            onChange={e => onChange({ ...rule, operator: e.target.value })}
          >
            {Object.entries(
              isNumericRule ? NUMBER_OPERATOR_NAMES : STRING_OPERATOR_NAMES,
            ).map(([operator, operatorName]) => (
              <Option key={operatorName} value={operator}>
                {operatorName}
              </Option>
            ))}
          </Select>
          {hasOperand && isNumericRule ? (
            <NumericInput
              data-testid="conditional-formatting-value-input"
              className={INPUT_CLASSNAME}
              type="number"
              value={rule.value}
              onChange={value => onChange({ ...rule, value })}
              placeholder="0"
            />
          ) : hasOperand ? (
            <input
              data-testid="conditional-formatting-value-input"
              className={INPUT_CLASSNAME}
              value={rule.value}
              onChange={e => onChange({ ...rule, value: e.target.value })}
              placeholder={t`option value`}
            />
          ) : null}
          <h3 className="mt3 mb1">{t`…turn its background this color:`}</h3>
          <ColorSelector
            value={rule.color}
            colors={COLORS}
            onChange={color => onChange({ ...rule, color })}
          />
        </div>
      ) : rule.type === "range" ? (
        <div>
          <h3 className="mt3 mb1">{t`Colors`}</h3>
          <ColorRangeSelector
            value={rule.colors}
            onChange={colors => {
              MetabaseAnalytics.trackStructEvent(
                "Chart Settings",
                "Table Formatting",
                "Select Range  Colors",
                colors,
              );
              onChange({ ...rule, colors });
            }}
            colors={COLORS}
            colorRanges={COLOR_RANGES}
          />
          <h3 className="mt3 mb1">{t`Start the range at`}</h3>
          <Radio
            value={rule.min_type}
            onChange={min_type => onChange({ ...rule, min_type })}
            options={(rule.option !== null
              ? [{ name: t`Smallest value in this option`, value: null }]
              : [
                  { name: t`Smallest value in each option`, value: null },
                  {
                    name: t`Smallest value in all of these options`,
                    value: "all",
                  },
                ]
            ).concat([{ name: t`Custom value`, value: "custom" }])}
            vertical
          />
          {rule.min_type === "custom" && (
            <NumericInput
              className={INPUT_CLASSNAME}
              type="number"
              value={rule.min_value}
              onChange={min_value => onChange({ ...rule, min_value })}
            />
          )}
          <h3 className="mt3 mb1">{t`End the range at`}</h3>
          <Radio
            value={rule.max_type}
            onChange={max_type => onChange({ ...rule, max_type })}
            options={(rule.option !== null
              ? [{ name: t`Largest value in this option`, value: null }]
              : [
                  { name: t`Largest value in each option`, value: null },
                  {
                    name: t`Largest value in all of these options`,
                    value: "all",
                  },
                ]
            ).concat([{ name: t`Custom value`, value: "custom" }])}
            vertical
          />
          {rule.max_type === "custom" && (
            <NumericInput
              className={INPUT_CLASSNAME}
              type="number"
              value={rule.max_value}
              onChange={max_value => onChange({ ...rule, max_value })}
            />
          )}
        </div>
      ) : null}
      <div className="mt4">
        {!rule.option ? (
          <Button
            primary
            onClick={onRemove}
            data-metabase-event="Chart Settings;Scatter Formatting;"
          >
            {isNew ? t`Cancel` : t`Delete`}
          </Button>
        ) : (
          <Button
            primary
            onClick={onDone}
            data-metabase-event={`Chart Settings;Scatter Formatting;${
              isNew ? "Add Rule" : "Update Rule"
            };Rule Type ${rule.type} Color`}
          >
            {isNew ? t`Add rule` : t`Update rule`}
          </Button>
        )}
      </div>
    </div>
  );
};
