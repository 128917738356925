import type { ReactNode } from "react";
import React from "react";

import {
  LayoutBody,
  LayoutRoot,
} from "./FileUploadLayout.styled";

export interface HomeLayoutProps {
  children?: ReactNode;
}

const FileUploadLayout = ({
  children,
}: HomeLayoutProps): JSX.Element => {
  return (
    <LayoutRoot>
      <LayoutBody>{children}</LayoutBody>
    </LayoutRoot>
  );
};

export default FileUploadLayout;
