import cx from "classnames";
import type * as React from "react";
import type { ControlPosition, DraggableBounds } from "react-draggable";
import Draggable from "react-draggable";

import { Ellipsified } from "metabase/core/components/Ellipsified";
import type { VisualizationSettings } from "metabase-types/api";

import { PivotTableCell, ResizeHandle } from "./PivotTable.styled";
import { RowToggleIcon } from "./RowToggleIcon";
import {
  DEFAULT_CELL_PADDING,
  LEFT_HEADER_LEFT_SPACING,
  RESIZE_HANDLE_WIDTH,
} from "./constants";
import type { HeaderItem, BodyItem, PivotTableClicked } from "./types";

interface CellProps {
  value: React.ReactNode;
  style?: React.CSSProperties;
  icon?: React.ReactNode;
  backgroundColor?: string;
  isBody?: boolean;
  isBold?: boolean;
  isEmphasized?: boolean;
  isNightMode?: boolean;
  isBorderedHeader?: boolean;
  isTransparent?: boolean;
  hasTopBorder?: boolean;
  onClick?: ((e: React.SyntheticEvent) => void) | undefined;
  onResize?: (newWidth: number) => void;
}

interface CellProps {
  value: React.ReactNode;
  style?: React.CSSProperties;
  icon?: React.ReactNode;
  backgroundColor?: string;
  backgroundColorOnHover?: string;
  textColor?: string;
  isBody?: boolean;
  isBold?: boolean;
  isEmphasized?: boolean;
  isNightMode?: boolean;
  isBorderedHeader?: boolean;
  isTransparent?: boolean;
  hasTopBorder?: boolean;
  padding?: string;
  borderColor?: string;
  onClick?: ((e: React.SyntheticEvent) => void) | undefined;
  onResize?: (newWidth: number) => void;
  showTooltip?: boolean;
}

export function Cell({
  value,
  style,
  icon,
  backgroundColor,
  textColor,
  isBody = false,
  isBold,
  isEmphasized,
  isNightMode,
  isBorderedHeader,
  isTransparent,
  hasTopBorder,
  borderColor,
  onClick,
  onResize,
  showTooltip = true,
  padding,
  backgroundColorOnHover,
}: CellProps) {
  return (
    <PivotTableCell
      data-testid="pivot-table-cell"
      isNightMode={isNightMode}
      isBold={isBold}
      isEmphasized={isEmphasized}
      isBorderedHeader={isBorderedHeader}
      hasTopBorder={hasTopBorder}
      isTransparent={isTransparent}
      borderColor={borderColor}
      style={{
        ...style,
      }}
      onClick={onClick}
      textColor={textColor}
      backgroundColor={backgroundColor}
      backgroundColorOnHover={backgroundColorOnHover}
    >
      <>
      <div
          className={cx("flex align-center", { "justify-end": isBody })}
          style={{ padding: padding, overflow: "hidden" }}
        >
          {/* paddingRight is need to fix trim of itallic font */}
          <Ellipsified showTooltip={showTooltip} style={{ lineHeight: 1 }} padding={"0 0.08em 0.08em 0"}>
            {value}
          </Ellipsified>
          {icon && <div className="pl1">{icon}</div>}
        </div>
        {!!onResize && (
          <Draggable
            axis="x"
            enableUserSelectHack
            bounds={{ left: RESIZE_HANDLE_WIDTH } as DraggableBounds}
            position={
              {
                x: style?.width ?? 0,
                y: 0,
              } as ControlPosition
            }
            onStop={(e, { x }) => {
              onResize(x);
            }}
          >
            <ResizeHandle data-testid="pivot-table-resize-handle" />
          </Draggable>
        )}
      </>
    </PivotTableCell>
  );
}

type CellClickHandler = (
  clicked: PivotTableClicked,
  rowIndex?: number,
) => (e: React.SyntheticEvent) => void;

interface TopHeaderCellProps {
  style: React.CSSProperties;
  isNightMode: boolean;
  getCellClickHandler: CellClickHandler;
  settings: VisualizationSettings;
  onResize?: (newWidth: number) => void;
}

export const TopHeaderCell = ({
  item,
  style,
  isNightMode,
  getCellClickHandler,
  onResize,
  settings,
}: TopHeaderCellProps & { item: HeaderItem }) => {
  const { value, hasChildren, clicked, isSubtotal, maxDepthBelow, span } = item;
  const topHeaderCellBackgroundColor =
    settings["pivot_table.header_background_color"];
  const topHeaderTextColor = settings["pivot_table.header_text_color"];
  const isTopHeaderTransparent = settings["pivot_table.header_transparent"];
  const topHeaderBackgroundOnHover =
    settings["pivot_table.header_background_color_hover"];

  const topHeaderTextHorizontalAlignment =
    settings["pivot_table.header_horizontal_alignment"];
  const topHeaderTextVerticalAlignment =
    settings["pivot_table.title_vertical_alignment"];

  const topHeaderBorderColor = settings["pivot_table.grid_color"];

  return (
    <Cell
      style={{
        ...style,
        ...(isSubtotal && { fontWeight: "bold" }),
        display: "flex",
        alignItems: topHeaderTextVerticalAlignment,
        justifyContent: topHeaderTextHorizontalAlignment,
      }}
      borderColor={topHeaderBorderColor}
      value={value}
      isNightMode={isNightMode}
      isBorderedHeader={maxDepthBelow === 0}
      isEmphasized={hasChildren}
      onClick={getCellClickHandler(clicked)}
      onResize={span < 2 ? onResize : undefined}
      backgroundColor={topHeaderCellBackgroundColor}
      isTransparent={isTopHeaderTransparent}
      textColor={topHeaderTextColor}
      backgroundColorOnHover={topHeaderBackgroundOnHover}
    />
  );
};

type LeftHeaderCellProps = TopHeaderCellProps & {
  rowIndexes: string[];
  settings: VisualizationSettings;
  onUpdateVisualizationSettings: (settings: VisualizationSettings) => void;
  rowToggleIconSize?: number;
  cellData: {
    item: HeaderItem;
    backgroundColor?: string;
    textColor?: string;
    fontStyle?: {
      font_bold?: boolean;
      font_italic?: boolean;
    };
  };
};

export const LeftHeaderCell = ({
  style,
  isNightMode,
  getCellClickHandler,
  rowIndexes,
  settings,
  onUpdateVisualizationSettings,
  onResize,
  rowToggleIconSize = 8,
  cellData,
}: LeftHeaderCellProps) => {
  const {
    value,
    isSubtotal,
    hasSubtotal,
    depth,
    path,
    clicked,
    indexOfCorrespondingRowData,
  } = cellData.item;

  const cellIndentVertical = settings["pivot_table.cell_auto_indent"]
    ? DEFAULT_CELL_PADDING
    : settings["pivot_table.cell_indent_vertical"];
  const cellIndentHorizontal = settings["pivot_table.cell_auto_indent"]
    ? DEFAULT_CELL_PADDING
    : settings["pivot_table.cell_indent_left"];

  const leftHeaderBackgroundColor =
    settings["pivot_table.left_header_background_color"];

  const leftHeaderTextColor = settings["pivot_table.left_header_text_color"];
  const isTransparent = settings["pivot_table.body_transparent"];
  const leftHeaderBackgroundOnHover =
    settings["pivot_table.cell_background_color_hover"];

  const horizontalTextAlignment =
    settings["pivot_table.cell_horizontal_alignment"];
  const verticalTextAlignment = settings["pivot_table.cell_vetrical_alignment"];

  const leftHeaderBorderColor = settings["pivot_table.grid_color"];

  const settingsBackgroundColor = isTransparent
    ? "transparent"
    : leftHeaderBackgroundColor;

  return (
    <Cell
      style={{
        ...style,
        ...(depth === 0 ? { paddingLeft: LEFT_HEADER_LEFT_SPACING } : {}),
        display: "flex",
        alignItems: verticalTextAlignment,
        justifyContent: horizontalTextAlignment,
        paddingTop: cellIndentVertical,
        paddingLeft: cellIndentHorizontal,
        fontStyle: cellData?.fontStyle?.font_italic ? "italic" : "normal",
      }}
      borderColor={leftHeaderBorderColor}
      isNightMode={isNightMode}
      value={value}
      isEmphasized={isSubtotal}
      isBold={cellData?.fontStyle?.font_bold ? true : isSubtotal}
      onClick={getCellClickHandler(clicked, indexOfCorrespondingRowData)}
      onResize={onResize}
      backgroundColor={
        cellData.backgroundColor
          ? cellData.backgroundColor
          : settingsBackgroundColor
      }
      backgroundColorOnHover={leftHeaderBackgroundOnHover}
      textColor={cellData?.textColor ? cellData.textColor : leftHeaderTextColor}
      icon={
        (isSubtotal || hasSubtotal) && (
          <RowToggleIcon
            data-testid={`${cellData.item.rawValue}-toggle-button`}
            value={path}
            settings={settings}
            updateSettings={onUpdateVisualizationSettings}
            hideUnlessCollapsed={isSubtotal}
            rowIndex={rowIndexes} // used to get a list of "other" paths when open one item in a collapsed column
            size={rowToggleIconSize}
          />
        )
      }
    />
  );
};

interface BodyCellProps {
  style: React.CSSProperties;
  rowSection: BodyItem[];
  isNightMode: boolean;
  getCellClickHandler: CellClickHandler;
  cellWidths: number[];
  showTooltip?: boolean;
  clickedRow: number;
  rowIndex: number;
  settings: VisualizationSettings;
}

export const BodyCell = ({
  style,
  rowSection,
  isNightMode,
  getCellClickHandler,
  cellWidths,
  showTooltip = true,
  clickedRow,
  rowIndex,
  settings,
}: BodyCellProps) => {
  const clickedRowBackgroundColor = settings["pivot_table.row_clicked_color"];
  const cellIndentVertical = settings["pivot_table.cell_auto_indent"]
    ? DEFAULT_CELL_PADDING
    : settings["pivot_table.cell_indent_vertical"];
  const cellIndentHorizontal = settings["pivot_table.cell_auto_indent"]
    ? DEFAULT_CELL_PADDING
    : settings["pivot_table.cell_indent_left"];

  const isFontBold = settings["pivot_table.cell_font_bold"];
  const isHighlightClickedRow =
    settings["pivot_table.toggle_clicked_row_higlight"];

  const oddRowCellBackgroundColor =
    settings["pivot_table.cell_odd_background_color"];
  const evenRowCellBackgroundColor =
    settings["pivot_table.cell_even_background_color"];

  const bodyCellTextColor = settings["pivot_table.cell_text_color"];
  const isBodyCellTransparent = settings["pivot_table.body_transparent"];
  const bodyCellOnHoverColor =
    settings["pivot_table.cell_background_color_hover"];

  const horizontalTextAlignment =
    settings["pivot_table.cell_horizontal_alignment"];
  const verticalTextAlignment = settings["pivot_table.cell_vetrical_alignment"];

  const cellBorderColor = settings["pivot_table.grid_color"];

  const isOddRow = rowIndex % 2 === 0;

  return (
    <div style={style} className="flex">
      {rowSection.map(
        (
          { value, isSubtotal, clicked, backgroundColor, textColor, fontStyle },
          index,
        ) => {
          let settingsBackgroundColor;
          if (rowIndex === clickedRow && isHighlightClickedRow) {
            settingsBackgroundColor = clickedRowBackgroundColor;
          } else if (backgroundColor) {
            settingsBackgroundColor = backgroundColor;
          } else if (isBodyCellTransparent) {
            settingsBackgroundColor = "transparent";
          } else {
            settingsBackgroundColor = isOddRow
              ? oddRowCellBackgroundColor
              : evenRowCellBackgroundColor;
          }

          return (
            <Cell
              isNightMode={isNightMode}
              key={index}
              style={{
                flexBasis: cellWidths[index],
                display: "flex",
                alignItems: verticalTextAlignment,
                justifyContent: horizontalTextAlignment,
                paddingTop: cellIndentVertical,
                paddingLeft: cellIndentHorizontal,
                fontStyle: fontStyle?.["font_italic"] ? "italic" : "normal",
              }}
              borderColor={cellBorderColor}
              value={value}
              isEmphasized={isSubtotal}
              isBold={fontStyle?.["font_bold"] || isFontBold || isSubtotal}
              showTooltip={showTooltip}
            isBody
            onClick={getCellClickHandler(clicked, rowIndex)}
              backgroundColor={settingsBackgroundColor}
              textColor={textColor || bodyCellTextColor}
              backgroundColorOnHover={bodyCellOnHoverColor}
            />
          );
        },
      )}
    </div>
  );
};
