import React, { useCallback, useMemo } from "react";
import { t } from "ttag";
import * as Yup from "yup";

import type { UpdateLicenseData } from "metabase/auth/types";
import FormErrorMessage from "metabase/core/components/FormErrorMessage";
import FormInput from "metabase/core/components/FormInput";
import FormSubmitButton from "metabase/core/components/FormSubmitButton";
import { Form, FormProvider } from "metabase/forms";
import * as Errors from "metabase/lib/errors";

import { UpdateLicenseFormTitle } from "./UpdateLicenseForm.style";

const UPDATE_LICENSE_SCHEMA = Yup.object({
  licenseKey: Yup.string().required(Errors.required),
});

export interface UpdateLicenseForm {
  initialLicenseKey?: string;
  onSubmit: (license: string) => void;
}

const UpdateLicenseForm = ({
  initialLicenseKey = "",
  onSubmit,
}: UpdateLicenseForm): JSX.Element => {
  const initialValues = useMemo(
    () => ({ licenseKey: initialLicenseKey }),
    [initialLicenseKey],
  );

  const handleSubmit = useCallback(
    ({ licenseKey }: UpdateLicenseData) => onSubmit(licenseKey),
    [onSubmit],
  );

  return (
    <div>
      <UpdateLicenseFormTitle>{t`Activate new license`}</UpdateLicenseFormTitle>
      <FormProvider
        initialValues={initialValues}
        validationSchema={UPDATE_LICENSE_SCHEMA}
        onSubmit={handleSubmit}
      >
        <Form>
          <FormInput
            name="licenseKey"
            title={t`New license code`}
            placeholder={t`New license code`}
            autoFocus
          />
          <FormSubmitButton title={t`Apply`} primary fullWidth />
          <FormErrorMessage />
        </Form>
      </FormProvider>
    </div>
  );
};

export default UpdateLicenseForm;
