import { t } from "ttag";

import { color } from "metabase/lib/colors";
import type { WidgetSettings } from "metabase-types/api/widgets/widget";


type ProgressGraphSettingsKeys = "progress.goal" | "progress.color";

export type ProgressGraphSettings = WidgetSettings<ProgressGraphSettingsKeys>;

export const PROGRESS_GRAPH_SETTINGS: ProgressGraphSettings = {
  "progress.goal": {
    section: t`Display`,
    title: t`Goal`,
    widget: "number",
    default: 0,
  },
  "progress.color": {
    section: t`Display`,
    title: t`Color`,
    widget: "color",
    default: color("accent1"),
  },
};
