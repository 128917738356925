/* eslint-disable react/prop-types */
import type React from "react";

import { QuestionLoaderHOC } from "metabase/containers/QuestionLoader";
import ParameterTargetWidget from "metabase/parameters/components/ParameterTargetWidget";
import { getParameterMappingOptions } from "metabase/parameters/utils/mapping-options";

type TQuestionParameterTargetWidgetProps = {
  question: any;
};
const QuestionParameterTargetWidget: React.FC<
  TQuestionParameterTargetWidgetProps
> = props => {
  const { question } = props;

  const mappingOptions = question
    ? getParameterMappingOptions(question, null, question.card())
    : [];
  return <ParameterTargetWidget {...props} mappingOptions={mappingOptions} />;
};

export default QuestionLoaderHOC(QuestionParameterTargetWidget);
