/* eslint-disable react/prop-types */
import React from "react";
import { connect } from "react-redux";
import { goBack, push } from "react-router-redux";
import { t } from "ttag";

import * as Urls from "metabase/lib/urls";

import Groups from "metabase/entities/groups";

const NewGroupsModal = ({ onClose, onSaved, ...props }) => (
  <Groups.ModalForm
    {...props}
    title={t`New group`}
    onClose={onClose}
    onSaved={onSaved}
  />
);

export default connect(null, {
  onClose: goBack,
  onSaved: () => push(Urls.newGroupSuccess()),
})(NewGroupsModal);
