import { ContentSection } from "./DashboardInfoSidebar.styled";
import { WidgetSettings } from "./WidgetSettings";

export const DashboardCardSettings = () => {
  return (
    <ContentSection>
      <WidgetSettings selectedWidget="card" />
    </ContentSection>
  );
};
