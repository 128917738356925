import { connect } from "react-redux";
import TableBrowserRemoved from "metabase/browse/components/TableBrowser/TableBrowserRemoved";
import { addUndo } from "metabase/redux/undo";

const mapDispatchToProps = {
  onShowToast: addUndo,
};


export default connect((state, props) => ({}), mapDispatchToProps)(TableBrowserRemoved);
