import { createSelector } from "@reduxjs/toolkit";
import { t, jt } from "ttag";
import _ from "underscore";

import { SMTPConnectionForm } from "metabase/admin/settings/components/Email/SMTPConnectionForm";
import SettingCommaDelimitedInput from "metabase/admin/settings/components/widgets/SettingCommaDelimitedInput";
import { isPersonalCollectionOrChild } from "metabase/collections/utils";
import { DashboardSelector } from "metabase/components/DashboardSelector";
import ExternalLink from "metabase/core/components/ExternalLink";
import MetabaseSettings from "metabase/lib/settings";
import { PLUGIN_ADMIN_SETTINGS_UPDATES } from "metabase/plugins";
import { refreshCurrentUser } from "metabase/redux/user";
import { getUserIsAdmin } from "metabase/selectors/user";
import { PersistedModelsApi } from "metabase/services";

import {
  trackTrackingPermissionChanged,
  trackCustomHomepageDashboardEnabled,
} from "./analytics";
import { BccToggleWidget } from "./components/Email/BccToggleWidget";
import { SettingsEmailForm } from "./components/Email/SettingsEmailForm";
// import SettingsLicense from "./components/SettingsLicense";
// import SettingsUpdatesForm from "./components/SettingsUpdatesForm/SettingsUpdatesForm";
import { UploadSettings } from "./components/UploadSettings";
import CustomGeoJSONWidget from "./components/widgets/CustomGeoJSONWidget";
import DashboardBackgroundWidget from "./components/widgets/DashboardBackgroundWidget";
import DashboardTextColorWidget from "./components/widgets/DashboardTextColorWidget";
// import { EmbeddingSwitchWidget } from "./components/widgets/EmbeddingSwitchWidget";
import FormattingWidget from "./components/widgets/FormattingWidget";
import HttpsOnlyWidget from "./components/widgets/HttpsOnlyWidget";
import ModelCachingScheduleWidget from "./components/widgets/ModelCachingScheduleWidget";
import {
  PublicLinksDashboardListing,
  PublicLinksQuestionListing,
  PublicLinksActionListing,
} from "./components/widgets/PublicLinksListing";
import SectionDivider from "./components/widgets/SectionDivider";
// import SettingCommaDelimitedInput from "./components/widgets/SettingCommaDelimitedInput";
import SiteUrlWidget from "./components/widgets/SiteUrlWidget";
import { updateSetting } from "./settings";
import SetupCheckList from "./setup/components/SetupCheckList";
import SlackSettings from "./slack/containers/SlackSettings";
// import { FontStyleWidget } from "./components/FontStyleWidget";

// This allows plugins to update the settings sections
function updateSectionsWithPlugins(sections) {
  if (PLUGIN_ADMIN_SETTINGS_UPDATES.length > 0) {
    const reduced = PLUGIN_ADMIN_SETTINGS_UPDATES.reduce(
      (sections, update) => update(sections),
      sections,
    );

    // the update functions may change the key ordering inadvertently
    // see: https://github.com/aearly/icepick/issues/48
    // therefore, re-sort the reduced object according to the original key order
    const sortByOrder = (
      [, { order: order1 = Number.MAX_VALUE }],
      [, { order: order2 = Number.MAX_VALUE }],
    ) => order1 - order2;

    return Object.fromEntries(Object.entries(reduced).sort(sortByOrder));
  } else {
    return sections;
  }
}

export const ADMIN_SETTINGS_SECTIONS = {
  setup: {
    name: t`Setup`,
    order: 10,
    settings: [],
    component: SetupCheckList,
    adminOnly: true,
  },
  general: {
    name: t`General`,
    order: 20,
    settings: [
      {
        key: "site-name",
        display_name: t`Site Name`,
        type: "string",
      },
      {
        key: "site-url",
        display_name: t`Site URL`,
        type: "string",
        widget: SiteUrlWidget,
        warningMessage: t`Only change this if you know what you're doing!`,
      },
      {
        key: "custom-homepage",
        display_name: t`Custom Homepage`,
        type: "boolean",
        postUpdateActions: [refreshCurrentUser],
        onChanged: (oldVal, newVal, _settings, handleChangeSetting) => {
          if (!newVal && oldVal) {
            handleChangeSetting("custom-homepage-dashboard", null);
          }
        },
      },
      {
        key: "custom-homepage-dashboard",
        description: null,
        getHidden: ({ "custom-homepage": customHomepage }) => !customHomepage,
        widget: DashboardSelector,
        postUpdateActions: [
          () =>
            updateSetting({
              key: "dismissed-custom-dashboard-toast",
              value: true,
            }),
          refreshCurrentUser,
        ],
        getProps: setting => ({
          value: setting.value,
          collectionFilter: (collection, index, allCollections) =>
            !isPersonalCollectionOrChild(collection, allCollections),
        }),
        onChanged: (oldVal, newVal) => {
          if (newVal && !oldVal) {
            trackCustomHomepageDashboardEnabled("admin");
          }
        },
      },
      {
        key: "redirect-all-requests-to-https",
        display_name: t`Redirect to HTTPS`,
        type: "boolean",
        getHidden: ({ "site-url": url }) => !/^https:\/\//.test(url),
        widget: HttpsOnlyWidget,
      },
      {
        key: "admin-email",
        display_name: t`Email Address for Help Requests`,
        type: "string",
      },

      {
        key: "anon-tracking-enabled",
        display_name: t`Anonymous Tracking`,
        type: "boolean",
        onChanged: (oldValue, newValue) => {
          trackTrackingPermissionChanged(newValue);
        },
        onBeforeChanged: (oldValue, newValue) => {
          trackTrackingPermissionChanged(newValue);
        },
      },
      {
        key: "humanization-strategy",
        display_name: t`Friendly Table and Field Names`,
        type: "select",
        options: [
          {
            value: "simple",
            name: t`Replace underscores and dashes with spaces`,
          },
          { value: "none", name: t`Disabled` },
        ],
        defaultValue: "simple",
      },
      {
        key: "enable-nested-queries",
        display_name: t`Enable Nested Queries`,
        type: "boolean",
      },
      {
        key: "enable-xrays",
        display_name: t`Enable X-ray features`,
        type: "boolean",
      },
      {
        key: "grid-width",
        display_name: t`Dashboards grid width`,
        type: "number",
        validations: [["integer", t`That's not a valid width`]],
      },
      //   Dashboard default visualization settings
      {
        key: "dashboard-default-visualization-settings",
        settings: [
          {
            display_name: t`Dashboard default filters display mode`,
            key: "dashboard-default-filters-display-mode",
            type: "select",
            options: [
              {
                value: "all",
                name: t`Show all`,
              },
              {
                value: "active",
                name: t`Show active`,
              },
              { value: "none", name: t`Show none` },
            ],
            defaultValue: "active",
          },
          {
            key: "dashboard-default-text-color",
            display_name: t`Dashboard default text color`,
            type: "string",
            widget: DashboardTextColorWidget,
          },
          {
            key: "dashboard-default-background-color",
            display_name: t`Dashboard default background color`,
            type: "string",
            widget: DashboardBackgroundWidget,
          },
        ],
      },
    ],
  },
  //   whitelabel: {
  //     name: t`Appearance`,
  //     settings: [
  //       {
  //         key: "application-name",
  //         display_name: t`Application Name`,
  //         type: "string",
  //       },
  //       {
  //         key: "application-font",
  //         display_name: t`Font`,
  //         widget: FontStyleWidget,
  //       },
  //     ],
  //   },
  // TODO issues/36
  // https://gitlab.glarus-digital.ru/glarus-bi/metabase/-/issues/36
  // updates: {
  //   name: t`Updates`,
  //   order: 30,
  //   component: SettingsUpdatesForm,
  //   settings: [
  //     {
  //       key: "check-for-updates",
  //       display_name: t`Check for updates`,
  //       type: "boolean",
  //     },
  //   ],
  //   adminOnly: true,
  // },
  email: {
    name: t`Email`,
    order: 40,
    component: SettingsEmailForm,
    settings: [
      {
        key: "email-from-name",
        display_name: t`From Name`,
        placeholder: "Metabase",
        type: "string",
        required: false,
      },
      {
        key: "email-from-address",
        display_name: t`From Address`,
        placeholder: "metabase@yourcompany.com",
        type: "string",
        required: true,
        validations: [["email", t`That's not a valid email address`]],
      },
      {
        key: "email-reply-to",
        display_name: t`Reply-To Address`,
        placeholder: "metabase-replies@yourcompany.com",
        type: "string",
        required: false,
        widget: SettingCommaDelimitedInput,
        validations: [["email_list", t`That's not a valid email address`]],
      },
      {
        key: "bcc-enabled?",
        display_name: t`Add Recipients as CC or BCC`,
        description: t`Control the visibility of recipients.`,
        options: [
          { value: true, name: t`BCC - Hide recipients` },
          {
            value: false,
            name: t`CC - Disclose recipients`,
          },
        ],
        defaultValue: true,
        widget: BccToggleWidget,
      },
    ],
  },
  "email/smtp": {
    component: SMTPConnectionForm,
    settings: [
      {
        key: "email-smtp-host",
        display_name: t`SMTP Host`,
        placeholder: "smtp.yourservice.com",
        type: "string",
        required: true,
        autoFocus: true,
      },
      {
        key: "email-smtp-port",
        display_name: t`SMTP Port`,
        placeholder: "587",
        type: "number",
        required: true,
        validations: [["integer", t`That's not a valid port number`]],
      },
      {
        key: "email-smtp-security",
        display_name: t`SMTP Security`,
        description: null,
        type: "radio",
        options: { none: "None", ssl: "SSL", tls: "TLS", starttls: "STARTTLS" },
        defaultValue: "none",
      },
      {
        key: "email-smtp-username",
        display_name: t`SMTP Username`,
        description: null,
        placeholder: "nicetoseeyou",
        type: "string",
      },
      {
        key: "email-smtp-password",
        display_name: t`SMTP Password`,
        description: null,
        placeholder: "Shhh...",
        type: "password",
        getHidden: () => MetabaseSettings.isHosted(),
      },
      {
        key: "email-from-name",
        display_name: t`From Name`,
        placeholder: "Glarus BI",
        type: "string",
        required: false,
      },
      {
        key: "email-from-address",
        display_name: t`From Address`,
        placeholder: "glarusbi@yourcompany.com",
        type: "string",
        required: true,
        validations: [["email", t`That's not a valid email address`]],
      },
      {
        key: "email-reply-to",
        display_name: t`Reply-To Address`,
        placeholder: "glarusbi-replies@yourcompany.com",
        type: "string",
        required: false,
        widget: SettingCommaDelimitedInput,
        validations: [["email_list", t`That's not a valid email address`]],
      },
    ],
  },
  slack: {
    name: "Slack",
    order: 50,
    component: SlackSettings,
    settings: [],
  },
  authentication: {
    name: t`Authentication`,
    order: 60,
    settings: [], // added by plugins
    adminOnly: true,
  },
  maps: {
    name: t`Maps`,
    order: 70,
    settings: [
      {
        key: "map-tile-server-url",
        display_name: t`Map tile server URL`,
        note: t`Glarus BI uses OpenStreetMaps by default.`,
        type: "string",
      },
      {
        key: "custom-geojson",
        display_name: t`Custom Maps`,
        description: t`Add your own GeoJSON files to enable different region map visualizations`,
        widget: CustomGeoJSONWidget,
        noHeader: true,
      },
    ],
  },
  localization: {
    name: t`Localization`,
    order: 80,
    settings: [
      {
        display_name: t`Instance language`,
        key: "site-locale",
        type: "select",
        options: _.sortBy(
          MetabaseSettings.get("available-locales") || [],
          ([code, name]) => name,
        ).map(([code, name]) => ({ name, value: code })),
        defaultValue: "en",
        onChanged: (oldLocale, newLocale) => {
          if (oldLocale !== newLocale) {
            window.location.reload();
          }
        },
      },
      {
        key: "report-timezone",
        display_name: t`Report Timezone`,
        type: "select",
        options: [
          { name: t`Database Default`, value: "" },
          ...MetabaseSettings.get("available-timezones"),
        ],
        note: t`Not all databases support timezones, in which case this setting won't take effect.`,
        allowValueCollection: true,
        searchProp: "name",
        defaultValue: "",
      },
      {
        key: "start-of-week",
        display_name: t`First day of the week`,
        type: "select",
        options: [
          { value: "sunday", name: t`Sunday` },
          { value: "monday", name: t`Monday` },
          { value: "tuesday", name: t`Tuesday` },
          { value: "wednesday", name: t`Wednesday` },
          { value: "thursday", name: t`Thursday` },
          { value: "friday", name: t`Friday` },
          { value: "saturday", name: t`Saturday` },
        ],
        defaultValue: "sunday",
      },
      {
        display_name: t`Localization options`,
        description: "",
        key: "custom-formatting",
        widget: FormattingWidget,
      },
    ],
  },
  uploads: {
    name: t`Uploads`,
    order: 85,
    adminOnly: false,
    component: UploadSettings,
    settings: [
      {
        key: "uploads-enabled",
        display_name: t`Data Uploads`,
        description: t`Enable admins to upload data to new database tables from CSV files.`,
        type: "boolean",
      },
      {
        key: "uploads-database-id",
        getHidden: settings => !settings["uploads-enabled"],
        display_name: t`Database`,
        description: t`Identify a database where upload tables will be created.`,
        placeholder: t`Select a database`,
      },
      {
        key: "uploads-schema-name",
        display_name: t`Schema name`,
        description: t`Identify a database schema where data upload tables will be created.`,
        type: "string",
        placeholder: "uploads",
      },
      {
        key: "uploads-table-prefix",
        display_name: t`Table prefix`,
        description: t`Identify a table prefix for tables created by data uploads.`,
        placeholder: "uploaded_",
        type: "string",
        required: false,
      },
    ],
  },

  "public-sharing": {
    name: t`Public Sharing`,
    order: 90,
    settings: [
      {
        key: "enable-public-sharing",
        display_name: t`Enable Public Sharing`,
        description: t`Enable admins to create publicly viewable links (and embeddable iframes) for Questions and Dashboards.`,
        type: "boolean",
      },
      {
        key: "-public-sharing-dashboards",
        display_name: t`Shared Dashboards`,
        widget: PublicLinksDashboardListing,
        getHidden: (_, derivedSettings) =>
          !derivedSettings["enable-public-sharing"],
      },
      {
        key: "-public-sharing-questions",
        display_name: t`Shared Questions`,
        widget: PublicLinksQuestionListing,
        getHidden: (_, derivedSettings) =>
          !derivedSettings["enable-public-sharing"],
      },
      {
        key: "-public-sharing-actions",
        display_name: t`Shared Action Forms`,
        widget: PublicLinksActionListing,
        getHidden: (_, derivedSettings) =>
          !derivedSettings["enable-public-sharing"],
      },
    ],
  },
  //   "embedding-in-other-applications": {
  //     key: "enable-embedding",
  //     name: t`Embedding`,
  //     order: 100,
  //     settings: [
  //       {
  //         key: "enable-embedding",
  //         display_name: t`Embedding`,
  //         description: null,
  //         widget: EmbeddingSwitchWidget,
  //         onChanged: async (
  //           oldValue,
  //           newValue,
  //           settingsValues,
  //           onChangeSetting,
  //         ) => {
  //           // Generate a secret key if none already exists
  //           if (
  //             !oldValue &&
  //             newValue &&
  //             !settingsValues["embedding-secret-key"]
  //           ) {
  //             const result = await UtilApi.random_token();
  //             await onChangeSetting("embedding-secret-key", result.token);
  //           }
  //         },
  //       },
  //       {
  //         key: "-static-embedding",
  //         widget: StaticEmbeddingOptionCard,
  //       },
  //       {
  //         key: "-interactive-embedding",
  //         widget: InteractiveEmbeddingOptionCard,
  //       },
  //     ],
  //   },
  //   "embedding-in-other-applications/standalone": {
  //     settings: [
  //       {
  //         key: "-breadcrumb",
  //         widget: () => {
  //           return (
  //             <Breadcrumbs
  //               size="large"
  //               crumbs={[
  //                 [
  //                   t`Embedding`,
  //                   "/admin/settings/embedding-in-other-applications",
  //                 ],
  //                 [t`Static embedding`],
  //               ]}
  //             />
  //           );
  //         },
  //       },
  //       {
  //         key: "embedding-secret-key",
  //         display_name: t`Embedding secret key`,
  //         description: t`Standalone Embed Secret Key used to sign JSON Web Tokens for requests to /api/embed endpoints. This lets you create a secure environment limited to specific users or organizations.`,
  //         widget: SecretKeyWidget,
  //         getHidden: (_, derivedSettings) => !derivedSettings["enable-embedding"],
  //         props: {
  //           confirmation: {
  //             header: t`Regenerate embedding key?`,
  //             dialog: t`This will cause existing embeds to stop working until they are updated with the new key.`,
  //           },
  //         },
  //       },

  //       {
  //         key: "-embedded-resources",
  //         display_name: t`Manage embeds`,

  //         widget: EmbeddedResources,
  //         getHidden: (_, derivedSettings) => !derivedSettings["enable-embedding"],
  //       },
  //       {
  //         key: "-redirect-widget",
  //         widget: () => (
  //           <RedirectWidget to="/admin/settings/embedding-in-other-applications" />
  //         ),
  //         getHidden: (_, derivedSettings) => derivedSettings["enable-embedding"],
  //       },
  //     ],
  //   },
  //   "embedding-in-other-applications/full-app": {
  //     settings: [
  //       {
  //         key: "-breadcrumbs",
  //         widget: () => {
  //           return (
  //             <Breadcrumbs
  //               size="large"
  //               crumbs={[
  //                 [
  //                   t`Embedding`,
  //                   "/admin/settings/embedding-in-other-applications",
  //                 ],
  //                 [t`Interactive embedding`],
  //               ]}
  //             />
  //           );
  //         },
  //       },
  //       {
  //         key: "-redirect-widget",
  //         widget: () => (
  //           <RedirectWidget to="/admin/settings/embedding-in-other-applications" />
  //         ),
  //         getHidden: (_, derivedSettings) =>
  //           PLUGIN_EMBEDDING.isEnabled() && derivedSettings["enable-embedding"],
  //       },
  //     ],
  //   },
  // TODO issues/36
  // https://gitlab.glarus-digital.ru/glarus-bi/metabase/-/issues/36
  // license: {
  //   name: t`License`,
  //   order: 110,
  //   component: SettingsLicense,
  //   settings: [],
  // },
  caching: {
    name: t`Caching`,
    order: 120,
    settings: [
      {
        key: "enable-query-caching",
        display_name: t`Saved questions`,
        type: "boolean",
      },
      {
        key: "query-caching-min-ttl",
        display_name: t`Minimum Query Duration`,
        type: "number",
        getHidden: settings => !settings["enable-query-caching"],
        allowValueCollection: true,
      },
      {
        key: "query-caching-ttl-ratio",
        display_name: t`Cache Time-To-Live (TTL) multiplier`,
        type: "number",
        getHidden: settings => !settings["enable-query-caching"],
        allowValueCollection: true,
      },
      {
        key: "query-caching-max-kb",
        display_name: t`Max Cache Entry Size`,
        type: "number",
        getHidden: settings => !settings["enable-query-caching"],
        allowValueCollection: true,
      },
      {
        widget: SectionDivider,
      },
      {
        key: "persisted-models-enabled",
        display_name: t`Models`,
        description: jt`Enabling cache will create tables for your models in a dedicated schema and Glarus BI will refresh them on a schedule. Questions based on your models will query these tables. ${(
          <ExternalLink
            key="model-caching-link"
            href={MetabaseSettings.docsUrl("data-modeling/models")}
          >{t`Learn more`}</ExternalLink>
        )}.`,
        type: "boolean",
        disableDefaultUpdate: true,
        onChanged: async (wasEnabled, isEnabled) => {
          if (isEnabled) {
            await PersistedModelsApi.enablePersistence();
          } else {
            await PersistedModelsApi.disablePersistence();
          }
        },
      },
      {
        key: "persisted-model-refresh-cron-schedule",
        noHeader: true,
        type: "select",
        options: [
          {
            value: "0 0 0/1 * * ? *",
            name: t`Hour`,
          },
          {
            value: "0 0 0/2 * * ? *",
            name: t`2 hours`,
          },
          {
            value: "0 0 0/3 * * ? *",
            name: t`3 hours`,
          },
          {
            value: "0 0 0/6 * * ? *",
            name: t`6 hours`,
          },
          {
            value: "0 0 0/12 * * ? *",
            name: t`12 hours`,
          },
          {
            value: "0 0 0 ? * * *",
            name: t`24 hours`,
          },
          {
            value: "custom",
            name: t`Custom…`,
          },
        ],
        widget: ModelCachingScheduleWidget,
        disableDefaultUpdate: true,
        getHidden: settings => !settings["persisted-models-enabled"],
        onChanged: (previousValue, value) =>
          PersistedModelsApi.setRefreshSchedule({ cron: value }),
      },
    ],
  },
  metabot: {
    name: t`Metabot`,
    order: 130,
    getHidden: settings => !settings["is-metabot-enabled"],
    settings: [
      {
        key: "openai-api-key",
        display_name: t`OpenAI API Key`,
        description: null,
        type: "string",
        getHidden: (_, settings) => !settings["is-metabot-enabled"],
      },
      {
        key: "openai-organization",
        display_name: t`OpenAI Organization ID`,
        description: null,
        type: "string",
        getHidden: (_, settings) => !settings["is-metabot-enabled"],
      },
      {
        key: "openai-model",
        display_name: t`OpenAI Model`,
        description: null,
        type: "select",
        getProps: (_, settings) => {
          const models = settings["openai-available-models"] ?? [];

          return {
            options: models.map(model => ({ name: model.id, value: model.id })),
            disabled: !models.length,
          };
        },
        getHidden: (_, settings) => !settings["is-metabot-enabled"],
      },
    ],
  },
};

const getSectionsWithPlugins = _.once(() =>
  updateSectionsWithPlugins(ADMIN_SETTINGS_SECTIONS),
);

export const getSettings = createSelector(
  state => state.admin.settings.settings,
  state => state.admin.settings.warnings,
  (settings, warnings) =>
    settings.map(setting =>
      warnings[setting.key]
        ? { ...setting, warning: warnings[setting.key] }
        : setting,
    ),
);

// getSettings selector returns settings for admin setting page and values specified by
// environment variables set to "null". Actual applied setting values are coming from
// /api/session/properties API handler and getDerivedSettingValues returns them.
export const getDerivedSettingValues = state => state.settings?.values ?? {};

export const mapColorsToState = state => {
  return {
    defaultBackgroundColor:
      state.settings?.values?.["dashboard-default-visualization-settings"]?.[
        "dashboard-default-background-color"
      ],
    defaultTextColor:
      state.settings?.values?.["dashboard-default-visualization-settings"]?.[
        "dashboard-default-text-color"
      ],
  };
};

export const getSettingValues = createSelector(getSettings, settings => {
  const settingValues = {};
  for (const setting of settings) {
    settingValues[setting.key] = setting.value;
  }
  return settingValues;
});

export const getNewVersionAvailable = createSelector(getSettings, settings => {
  return MetabaseSettings.newVersionAvailable(settings);
});

export const getSections = createSelector(
  getSettings,
  getDerivedSettingValues,
  getUserIsAdmin,
  (settings, derivedSettingValues, isAdmin) => {
    if (!settings || _.isEmpty(settings)) {
      return {};
    }

    const sections = getSectionsWithPlugins();
    const settingsByKey = _.groupBy(settings, "key");
    const sectionsWithAPISettings = {};
    for (const [slug, section] of Object.entries(sections)) {
      const isHidden = section.getHidden?.(derivedSettingValues);

      if (isHidden || (section.adminOnly && !isAdmin)) {
        continue;
      }

      const settings = section.settings.map(function (setting) {
        const apiSetting =
          settingsByKey[setting.key] && settingsByKey[setting.key][0];

        if (apiSetting) {
          const value = setting.showActualValue
            ? derivedSettingValues[setting.key]
            : apiSetting.value;
          return {
            placeholder: apiSetting.default,
            ...apiSetting,
            ...setting,
            value,
          };
        } else {
          return setting;
        }
      });
      sectionsWithAPISettings[slug] = { ...section, settings };
    }
    return sectionsWithAPISettings;
  },
);

export const getActiveSectionName = (state, props) => props.params.splat;

export const getActiveSection = createSelector(
  getActiveSectionName,
  getSections,
  (section = "setup", sections) => {
    if (sections) {
      return sections[section];
    } else {
      return null;
    }
  },
);
