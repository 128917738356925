/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { Motion, spring } from "react-motion";
import { t } from "ttag";

import { isReducedMotionPreferred } from "metabase/lib/dom";
import Radio from "metabase/core/components/Radio/Radio";
import CheckBox from "metabase/core/components/CheckBox";
import Button from "metabase/core/components/Button";
import Card from "metabase/components/Card";

import EntityMenuTrigger from "metabase/components/EntityMenuTrigger";
import Popover from "metabase/components/Popover";

import { Container } from "./EntityMenu/EntityMenu.styled";

const MENU_SHIFT_Y = 10;

class DownloadMenu extends Component {
  state = {
    open: false,
    freezeMenu: false,
    documentType: "pdf",
  };

  constructor(props, context) {
    super(props, context);
    this.rootRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.loader === false && prevProps.loader === true) {
      this.setState({ open: false });
    }
  }

  toggleMenu = () => {
    if (this.state.freezeMenu) {
      return;
    }

    const open = !this.state.open;
    this.setState({ open, menuItemContent: null });
  };

  setFreezeMenu = freezeMenu => {
    this.setState({ freezeMenu });
  };

  replaceMenuWithItemContent = menuItemContent => {
    this.setState({ menuItemContent });
  };

  toggleDocumentType = () => {
    if (this.state.documentType === "pdf") {
      this.setState({ documentType: "ppt" });
    } else {
      this.setState({ documentType: "pdf" });
    }
  };

  render() {
    const preferReducedMotion = isReducedMotionPreferred();

    const {
      triggerIcon,
      triggerProps,
      className,
      tooltip,
      trigger,
      targetOffsetY,
      orientation,
      viewPPT,
      loader,
      handleRadioOrientation,
      handleViewPPT,
      handleDownload,
      paramsShow,
      changeParamsShow,
    } = this.props;
    const { open, documentType } = this.state;
    return (
      <Container className={className} open={open} ref={this.rootRef}>
        <EntityMenuTrigger
          trigger={trigger}
          icon={triggerIcon}
          onClick={this.toggleMenu}
          open={open}
          tooltip={tooltip}
          triggerProps={triggerProps}
        />
        <Popover
          target={this.rootRef.current}
          isOpen={open}
          onClose={this.toggleMenu}
          hasArrow={false}
          hasBackground={false}
          horizontalAttachments={["left", "right"]}
          targetOffsetY={targetOffsetY || 0}
          ignoreTrigger
        >
          {/* Note: @kdoh 10/12/17
           * React Motion has a flow type problem with children see
           * https://github.com/chenglou/react-motion/issues/375
           * TODO This can be removed if we upgrade to flow 0.53 and react-motion >= 0.5.1
           */}
          <Motion
            defaultStyle={{
              opacity: 0,
              translateY: 0,
            }}
            style={{
              opacity: open ? spring(1) : spring(0),
              translateY: open ? spring(MENU_SHIFT_Y) : spring(0),
            }}
          >
            {({ opacity, translateY }) => {
              const motionOpacity = preferReducedMotion
                ? opacity > 0.5
                  ? 1
                  : 0
                : opacity;
              const motionY = preferReducedMotion
                ? translateY > MENU_SHIFT_Y / 2
                  ? MENU_SHIFT_Y
                  : 0
                : translateY;
              return (
                <div
                  style={{
                    opacity: motionOpacity,
                    transform: `translateY(${motionY}px)`,
                  }}
                >
                  <Card>
                    <div>
                      <Button
                        className="ml2 mt2"
                        onClick={this.toggleDocumentType}
                        disabled={loader}
                        value={documentType}
                      >
                        {t`Change document type`}
                      </Button>
                    </div>
                    {documentType === "pdf" && (
                      <div className="p2">
                        <Radio
                          className="ml2 text-bold mr1"
                          value={orientation}
                          disabled={loader}
                          options={[
                            { name: t`Portrait`, value: "portrait" },
                            { name: t`Landscape`, value: "landscape" },
                          ]}
                          showButtons
                          onChange={handleRadioOrientation}
                        />
                        <Button
                          className="mr1 mt2"
                          onClick={() => handleDownload("pdf")}
                          disabled={loader}
                        >
                          {loader ? t`loading` : t`Download PDF`}
                        </Button>
                      </div>
                    )}
                    {documentType === "ppt" && (
                      <div className="p2">
                        <CheckBox
                          className="ml2 mb1 text-bold"
                          label={t`Params widget show`}
                          disabled={loader}
                          checked={paramsShow}
                          onChange={changeParamsShow}
                        />
                        <Radio
                          className="ml2 text-bold mr1"
                          value={viewPPT}
                          disabled={loader}
                          options={[
                            { name: t`Widget`, value: "widget" },
                            { name: t`Dashboard`, value: "dashboard" },
                          ]}
                          showButtons
                          onChange={handleViewPPT}
                        />
                        <Button
                          className="mr1 mt2"
                          onClick={() => handleDownload("ppt")}
                          disabled={loader}
                        >
                          {loader ? t`loading` : t`Download PPT`}
                        </Button>
                        <Button
                          className="mr1 mt2"
                          onClick={() => handleDownload("tabs")}
                          disabled={loader}
                        >
                          {loader ? t`loading` : t`Download all Tabs PPT`}
                        </Button>
                      </div>
                    )}
                  </Card>
                </div>
              );
            }}
          </Motion>
        </Popover>
      </Container>
    );
  }
}

export default DownloadMenu;
