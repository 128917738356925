import React, { useState, useRef, useEffect } from "react";
import { t } from "ttag";
import Button from "metabase/core/components/Button";
import { Title } from "metabase/visualizations/components/ChartSettingsWidget.styled";
import ColorPill from "../../ColorPill";
import {
  ContainerText,
  ControlsContainer,
  Input,
  Wrapper,
} from "./ColorSelectorForCustomInput.styled";

interface Props {
  value: string;
  onChange?: (newValue: string) => void;
  onClose?: () => void;
}

const ColorSelectorForCustomInput = ({ value, onChange, onClose }: Props) => {
  const [color, setColor] = useState<string>(value);
  const inputRef = useRef<null | HTMLInputElement>(null);
  const [isChanged, setIsChanged] = useState(false);

  const handleColorChange: React.ChangeEventHandler<
    HTMLInputElement
  > = event => {
    setColor(event.target.value);
  };

  const handleLabelClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleButtonApplyClick = () => {
    if (onChange) {
      onChange(color);
    }
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    setIsChanged(color !== value);
  }, [color, value]);

  return (
    <Wrapper>
      <ContainerText>
        <Title style={{ margin: 0 }}>{t`Custom color`}:</Title>
      </ContainerText>
      <Input
        type="color"
        value={color}
        onChange={handleColorChange}
        ref={inputRef}
      />
      <ControlsContainer>
        <ColorPill
          color={color}
          isSelected={true}
          onSelect={handleLabelClick}
        />
        {isChanged && (
          <Button
            name="apply"
            onClick={handleButtonApplyClick}
            primary={true}
            small={true}
          >
            {t`Save`}
          </Button>
        )}
      </ControlsContainer>
    </Wrapper>
  );
};

// eslint-disable-next-line import/no-default-export
export default ColorSelectorForCustomInput;
