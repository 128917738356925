var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.internal.time_common.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var NH,PH,RH,$H,cI,hI;$CLJS.MH=new $CLJS.M(null,"get-month","get-month",-369374731);NH=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.OH=new $CLJS.M(null,"convert-timezone","convert-timezone",-124803001);PH=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.QH=new $CLJS.M(null,"iso","iso",-1366207543);RH=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.SH=new $CLJS.M(null,"get-second","get-second",-2065946318);$CLJS.TH=new $CLJS.M(null,"get-year","get-year",-936011274);$CLJS.UH=new $CLJS.M(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.VH=new $CLJS.M("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.WH=new $CLJS.M(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.XH=new $CLJS.M(null,"us","us",746429226);$CLJS.YH=new $CLJS.M(null,"datetime","datetime",494675702);$CLJS.ZH=new $CLJS.M(null,"now","now",-1650525531);
$H=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.aI=new $CLJS.M(null,"instance","instance",-2121349050);$CLJS.bI=new $CLJS.M(null,"get-day","get-day",127568857);cI=new $CLJS.M("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.dI=new $CLJS.M(null,"get-minute","get-minute",1468086343);
$CLJS.eI=new $CLJS.M(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.fI=new $CLJS.M("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);$CLJS.gI=new $CLJS.M(null,"get-hour","get-hour",622714059);hI=new $CLJS.M("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.iI=new $CLJS.M(null,"get-quarter","get-quarter",1327778593);
$CLJS.jI=new $CLJS.M(null,"week-of-year-us","week-of-year-us",849423511);$CLJS.kI=new $CLJS.M(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.lI=new $CLJS.M(null,"target","target",253001721);$CLJS.vE($CLJS.YA,$CLJS.H([$CLJS.Zr,$CLJS.$B,$CLJS.pr,$CLJS.cD]));$CLJS.JD.m(null,$CLJS.VH,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.KD(a);return $CLJS.qd(a)?(a=$CLJS.ct.g(a,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.ii,null,$CLJS.aj,null],null),null)),$CLJS.E.g($CLJS.D(a),1)?$CLJS.z(a):a):a});$CLJS.oE($CLJS.VH,$CLJS.aE);
for(var mI=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aB,$CLJS.dB],null)),nI=null,oI=0,pI=0;;)if(pI<oI){var qI=nI.X(null,pI);$CLJS.vE(qI,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TD],null),$CLJS.pr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cD],null)]));$CLJS.oE(qI,$CLJS.VH);pI+=1}else{var rI=$CLJS.y(mI);if(rI){var sI=rI;if($CLJS.vd(sI)){var tI=$CLJS.ic(sI),xia=$CLJS.jc(sI),yia=tI,zia=$CLJS.D(tI);mI=xia;nI=yia;oI=zia}else{var uI=$CLJS.z(sI);$CLJS.vE(uI,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.TD],null),$CLJS.pr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cD],null)]));$CLJS.oE(uI,$CLJS.VH);mI=$CLJS.B(sI);nI=null;oI=0}pI=0}else break}
for(var vI=$CLJS.y(new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.TH,$CLJS.MH,$CLJS.bI,$CLJS.gI,$CLJS.dI,$CLJS.SH,$CLJS.iI],null)),wI=null,xI=0,yI=0;;)if(yI<xI){var Aia=wI.X(null,yI);$CLJS.vE(Aia,$CLJS.H([$CLJS.Zr,$CLJS.Vi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TD],null)],null)]));yI+=1}else{var zI=$CLJS.y(vI);if(zI){var AI=zI;if($CLJS.vd(AI)){var BI=$CLJS.ic(AI),Bia=$CLJS.jc(AI),Cia=BI,Dia=$CLJS.D(BI);vI=Bia;wI=Cia;xI=Dia}else{var Eia=$CLJS.z(AI);$CLJS.vE(Eia,$CLJS.H([$CLJS.Zr,
$CLJS.Vi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TD],null)],null)]));vI=$CLJS.B(AI);wI=null;xI=0}yI=0}else break}$CLJS.vE($CLJS.XA,$CLJS.H([$CLJS.Zr,$CLJS.Vi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WF],null)]));
for(var CI=$CLJS.y(new $CLJS.Rg(null,new $CLJS.h(null,8,[$CLJS.iI,null,$CLJS.dI,null,$CLJS.gI,null,$CLJS.eI,null,$CLJS.SH,null,$CLJS.MH,null,$CLJS.TH,null,$CLJS.bI,null],null),null)),DI=null,EI=0,FI=0;;)if(FI<EI){var Fia=DI.X(null,FI);$CLJS.vE(Fia,$CLJS.H([$CLJS.Zr,$CLJS.Vi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TD],null)],null)]));FI+=1}else{var GI=$CLJS.y(CI);if(GI){var HI=GI;if($CLJS.vd(HI)){var II=$CLJS.ic(HI),Gia=$CLJS.jc(HI),Hia=II,Iia=$CLJS.D(II);
CI=Gia;DI=Hia;EI=Iia}else{var Jia=$CLJS.z(HI);$CLJS.vE(Jia,$CLJS.H([$CLJS.Zr,$CLJS.Vi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TD],null)],null)]));CI=$CLJS.B(HI);DI=null;EI=0}FI=0}else break}$CLJS.Y(hI,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ar,$CLJS.QH,$CLJS.XH,$CLJS.aI],null));
$CLJS.xE($CLJS.$A,$CLJS.H([$CLJS.Zr,$CLJS.Vi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hI],null)],null)],null)],null)]));
$CLJS.Y($CLJS.fI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,$CLJS.pD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ar,new $CLJS.h(null,2,[$CLJS.ts,"valid timezone ID",$CLJS.Es,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.bj);return["invalid timezone ID: ",$CLJS.xh.l($CLJS.H([a]))].join("")}],null)],null),$CLJS.Cz($CLJS.Dd,$CLJS.LH.tz.names())),$CLJS.CH],null)],null));
$CLJS.xE($CLJS.OH,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lI,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yy,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fI],null)],null)],null)],
null)]));$CLJS.oE($CLJS.OH,$CLJS.VH);$CLJS.vE($CLJS.ZH,$CLJS.H([$CLJS.Zr,$CLJS.Ak]));$CLJS.Y(RH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.yD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xi,new $CLJS.h(null,1,[$CLJS.ts,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Me(function(b){return $CLJS.Dz(a,b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ii,$CLJS.aj],null))}],null)],null));
$CLJS.Y(NH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tA,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RH],null)],null)],null)],null));
$CLJS.HF.g($CLJS.UH,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Sq,new $CLJS.h(null,1,[$CLJS.ts,"valid :absolute-datetime clause"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.UH],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NH],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Uq,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Sq,new $CLJS.h(null,1,[$CLJS.ts,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.GH],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.IH],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.AH],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.Oh],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XF],null)],null)],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Sq,new $CLJS.h(null,1,[$CLJS.ts,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.Qu],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.KH],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.Oh],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TF],null)],null)],null)],null)],null)],null));
$CLJS.JD.m(null,$CLJS.UH,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);a=$CLJS.E.g(b,$CLJS.Qu)?$CLJS.E.g(a,$CLJS.Oh)?$CLJS.aj:$CLJS.n($CLJS.nE($CLJS.XF,a))?$CLJS.ii:$CLJS.aj:null;if($CLJS.n(a))return a;a="string"===typeof b?$CLJS.n($CLJS.dh($CLJS.wH,b))?$CLJS.ii:$CLJS.n($CLJS.dh($CLJS.xH,b))?$CLJS.ii:null:null;if($CLJS.n(a))return a;b=$CLJS.KD(b);b=$CLJS.qd(b)?$CLJS.Wf.j($CLJS.Sg,$CLJS.ef(function(c){return $CLJS.Dz(c,$CLJS.pj)}),b):b;return $CLJS.qd(b)&&
$CLJS.E.g($CLJS.D(b),1)?$CLJS.z(b):b});$CLJS.Y(cI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.Qu],null),$CLJS.pr],null));
$CLJS.xE($CLJS.ZA,$CLJS.H([$CLJS.Zr,$CLJS.aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cD],null)],null)],null)],null)]));
$CLJS.vE($CLJS.Fx,$CLJS.H([$CLJS.Zr,$CLJS.Ij,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bD],null)]));$CLJS.Y(PH,new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.ar,$CLJS.VF,$CLJS.yk,$CLJS.Hk,$CLJS.WH,$CLJS.jI,$CLJS.kI,$CLJS.Dk,$CLJS.zk,$CLJS.xk,$CLJS.Ck,$CLJS.SF],null));$CLJS.Y($H,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ar,$CLJS.QH,$CLJS.XH,$CLJS.aI],null));
$CLJS.xE($CLJS.bB,$CLJS.H([$CLJS.Zr,$CLJS.Vi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PH],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$H],null)],null)],
null)],null)]));