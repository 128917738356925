import { getColorScale, getSafeColor } from "metabase/lib/colors/scales";
import { alpha } from "metabase/lib/colors";

/**
 *
 * @param {object} chart chart object
 * @param {string} selector selector for get chart items (example ".bubble")
 * @param {object[]} settings format settings (example settings["scatter.bubble_formatting"])
 * @param {object} series data series
 */
const applyColorFormatting = (chart, selector, settings, series) => {
  settings = settingsWithColumnIndex(settings, series);
  settings = settingsCalculateMinMax(settings, series);

  chart.on("pretransition", chart => {
    const subs = chart.selectAll(".sub").selectAll(selector);

    subs.forEach(sub => {
      sub.forEach(item => {
        const color = getColor(item.__data__.key, settings);
        if (color !== undefined && color !== null) {
          item.setAttribute("fill", color);
        }
      });
    });
  });
};

const settingsWithColumnIndex = (settings, series) => {
  return settings
    .map(setting => ({
      ...setting,
      columnIndex: series[0].data.cols.findIndex(
        (col, index) => col.name === setting.option,
      ),
    }))
    .filter(setting => setting.columnIndex > -1);
};

const settingsCalculateMinMax = (settings, series) => {
  return settings.map(setting => {
    if (setting.type !== "range") {
      return setting;
    }
    return {
      ...setting,
      max_value:
        setting.max_type === "custom"
          ? setting.max_value
          : Math.max(
              ...series[0].data.rows.map(row => row[setting.columnIndex]),
            ),
      min_value:
        setting.min_type === "custom"
          ? setting.min_value
          : Math.min(
              ...series[0].data.rows.map(row => row[setting.columnIndex]),
            ),
    };
  });
};

const getColor = (keys, settings) =>
  settings
    .map(setting => {
      if (setting.type === "single") {
        return getSingleColor(keys[setting.columnIndex], setting);
      } else if (setting.type === "range") {
        return getRangeColor(keys[setting.columnIndex], setting);
      }
      console.log("Not single setting type!");
    })
    .find(color => color);

const getRangeColor = (value, setting) => {
  const min = Number(setting.min_value);
  const max = Number(setting.max_value);
  const scale = getColorScale(
    [min, max],
    setting.colors.map(c => alpha(c, 0.75)),
  ).clamp(true);
  return getSafeColor(scale(value));
};

const getSingleColor = (value, setting) => {
  switch (setting.operator) {
    case "=":
      return value === setting.value ? setting.color : null;
    case "!=":
      return value !== setting.value ? setting.color : null;
    case "<":
      return value < setting.value ? setting.color : null;
    case ">":
      return value > setting.value ? setting.color : null;
    case "<=":
      return value <= setting.value ? setting.color : null;
    case ">=":
      return value >= setting.value ? setting.color : null;
    case "is-null":
      return value === null ? setting.color : null;
    case "not-null":
      return value !== null ? setting.color : null;
    case "contains":
      return canCompareSubstrings(setting.value, value) &&
        value.indexOf(setting.value) >= 0
        ? setting.color
        : null;
    case "does-not-contain":
      return canCompareSubstrings(setting.value, value) &&
        value.indexOf(setting.value) < 0
        ? setting.color
        : null;
    case "starts-with":
      return canCompareSubstrings(setting.value, value) &&
        value.startsWith(setting.value)
        ? setting.color
        : null;
    case "ends-with":
      return canCompareSubstrings(setting.value, value) &&
        value.endsWith(setting.value)
        ? setting.color
        : null;

    default:
      console.log("Invalid setting operator!");
      return null;
  }
};

export const canCompareSubstrings = (a, b) =>
  typeof a === "string" && typeof b === "string" && !!a.length && !!b.length;

export const isEmptyString = val => typeof val === "string" && !val.length;

export { applyColorFormatting };
