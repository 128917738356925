import { t } from "ttag";

export const NUMBER_OPERATOR_NAMES = {
  "<": t`is less than`,
  ">": t`is greater than`,
  "<=": t`is less than or equal to`,
  ">=": t`is greater than or equal to`,
  "=": t`is equal to`,
  "!=": t`is not equal to`,
  "is-null": t`is null`,
  "not-null": t`is not null`,
};

export const STRING_OPERATOR_NAMES = {
  "=": t`is equal to`,
  "!=": t`is not equal to`,
  "is-null": t`is null`,
  "not-null": t`is not null`,
  contains: t`contains`,
  "does-not-contain": t`does not contain`,
  "starts-with": t`starts with`,
  "ends-with": t`ends with`,
};

export const ALL_OPERATOR_NAMES = {
  ...NUMBER_OPERATOR_NAMES,
  ...STRING_OPERATOR_NAMES,
};
