import React, { useCallback, useMemo, useState } from "react";
import { t } from "ttag";

import Button from "metabase/core/components/Button";
import FormField from "metabase/core/components/FormField";
import Radio from "metabase/core/components/Radio";
import { changeVizualizationSettings } from "metabase/dashboard/actions";
import { useDispatch, useSelector } from "metabase/lib/redux";
import SidebarHeader from "metabase/query_builder/components/SidebarHeader";
import { getSettings } from "metabase/selectors/settings";
import visualizations from "metabase/visualizations";
import { WIDGETS } from "metabase/visualizations/lib/settings";
import type { DashboardDefaultVisualizationSettingsKeys } from "metabase/visualizations/lib/settings/defaultSettings";
import type {
  VisualizationSettigsKeysOfAllWidgets,
  WidgetsNames} from "metabase/visualizations/lib/settings/widgetsSettings";
import {
  WIDGETS_SETTINGS
} from "metabase/visualizations/lib/settings/widgetsSettings";
import type { WidgetData} from "metabase-types/api/widgets/widget";
import { getWidgetData } from "metabase-types/api/widgets/widget";

import { useDashboardVisualizationSettings } from "./DashboardVisualizationSettingsContext";

interface FormWidgetProps {
  setting: WidgetData;
  dispatchChangeVisualizationSettings: (
    key: DashboardDefaultVisualizationSettingsKeys,
    value: unknown,
  ) => void;
}

const FormWidget = React.memo(function FormWidget({
  setting,
  dispatchChangeVisualizationSettings,
}: FormWidgetProps) {
  const { addSettings } = useDashboardVisualizationSettings();

  const [value, setValue] = useState(setting.value);
  const [isButtonApplyVisible, setIsButtonApplyVisible] = useState(false);

  const Widget =
    typeof setting.widget === "string"
      ? WIDGETS[setting.widget]
      : setting.widget;
  if (!Widget || setting.hidden) {
    return null;
  }

  const isHaveApplyButton = setting.isHaveApplyButton;

  const onChangeHandler =
    (key: DashboardDefaultVisualizationSettingsKeys) => (value: unknown) => {
      setValue(value);
      if (!isButtonApplyVisible) {
        setIsButtonApplyVisible(true);
      }
      !isHaveApplyButton && addSettings({ [key]: value });
    };

  return (
    <>
      <FormField
        key={setting.key}
        title={setting.title}
        orientation={setting.orientation || "vertical"}
        htmlFor={setting.key}
        style={
          isHaveApplyButton && isButtonApplyVisible ? { marginBottom: 10 } : {}
        }
      >
        <Widget
          id={setting.key}
          onChange={onChangeHandler(
            setting.key as VisualizationSettigsKeysOfAllWidgets,
          )}
          value={value}
          {...(setting.props || {})}
        />
      </FormField>
      {isHaveApplyButton && isButtonApplyVisible && (
        <Button
          name="apply"
          onClick={() => {
            addSettings({ [setting.key]: value });
            setIsButtonApplyVisible(false);
          }}
          primary={true}
          small={true}
          style={{ marginBottom: 20 }}
        >
          {t`Apply`}
        </Button>
      )}
    </>
  );
});

interface WidgetSettingsProps {
  selectedWidget: WidgetsNames;
  resetSelectedWidget?: () => void;
  headerTitle?: string;
  isNeedHeader?: boolean;
}

type SectionsData = Record<string, WidgetData[]>;

export const WidgetSettings = ({
  selectedWidget,
  resetSelectedWidget,
  headerTitle,
  isNeedHeader,
}: WidgetSettingsProps) => {
  const dispatch = useDispatch();

  const { settings } = useDashboardVisualizationSettings();

  const defaultDashboardSettings = useSelector(getSettings);

  const sectionsData = useMemo(() => {
    const widgetSettings = WIDGETS_SETTINGS[selectedWidget] || {};
    const settingsKeys = Object.keys(
      widgetSettings,
    ) as DashboardDefaultVisualizationSettingsKeys[];
    const sectionsData: SectionsData = {};

    settingsKeys.forEach(key => {
      const section = widgetSettings[key].section || t`Default`;
      const settingData = getWidgetData({
        key,
        setting: widgetSettings[key],
        contextSettings: settings,
        dashboardDefaultSettings:
          defaultDashboardSettings?.[
            "dashboard-default-visualization-settings"
          ] || {},
      });

      if (section) {
        if (!sectionsData[section]) {
          sectionsData[section] = [settingData];
        } else {
          sectionsData[section].push(settingData);
        }
      }
    });

    return sectionsData;
  }, [defaultDashboardSettings, selectedWidget, settings]);

  const sections = Object.keys(sectionsData);

  const [currentSection, setCurrentSection] = useState(sections[0]);

  const dispatchChangeVisualizationSettings = useCallback(
    (key: DashboardDefaultVisualizationSettingsKeys, value: unknown) => {
      dispatch(
        changeVizualizationSettings({
          [key]: value,
        }),
      );
    },
    [dispatch],
  );

  const selectedSection = sectionsData[currentSection] || [];

  const isNeedRenderSettingsTabs = sections.length > 1;

  return (
    <>
      {isNeedHeader && (
        <SidebarHeader
          className={`mt3 ${!isNeedRenderSettingsTabs ? "mb2" : ""}`}
          title={headerTitle || visualizations.get?.(selectedWidget)?.uiName}
          onBack={() => resetSelectedWidget?.()}
          onClose={undefined}
        />
      )}
      {isNeedRenderSettingsTabs && (
        <Radio
          value={currentSection}
          onChange={setCurrentSection}
          options={sections}
          optionNameFn={v => v}
          optionValueFn={v => v}
          optionKeyFn={v => v}
          variant="underlined"
          style={{ marginBottom: 20 }}
        />
      )}
      {selectedSection.map(setting => {
        return (
          <FormWidget
            key={setting.key}
            dispatchChangeVisualizationSettings={
              dispatchChangeVisualizationSettings
            }
            setting={setting}
          />
        );
      })}
    </>
  );
};
