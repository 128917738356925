/* eslint-disable react/prop-types */
import React from "react";
import { compose } from "redux";
import { t } from "ttag";

import Groups from "metabase/entities/groups";

const EditGroupModal = ({ group, onClose, ...props }) => (
  <Groups.ModalForm
    {...props}
    title={t`Edit group`}
    group={group}
    onSaved={onClose}
    onClose={onClose}
  />
);

export default compose(
  Groups.load({ id: (state, props) => props.params.groupId }),
)(EditGroupModal);
