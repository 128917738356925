import cx from "classnames";
import { useCallback, useMemo, isValidElement } from "react";

import { Ellipsified } from "metabase/core/components/Ellipsified";
import ExternalLink from "metabase/core/components/ExternalLink";
import { formatValue } from "metabase/lib/formatting";
import type { OptionsType } from "metabase/lib/formatting/types";
import {
  getTableCellClickedObject,
  getTableClickedObjectRowData,
  isColumnRightAligned,
} from "metabase/visualizations/lib/table";
import { getColumnExtent } from "metabase/visualizations/lib/utils";
import columnLineBreak from "metabase/visualizations/visualizations/Table";
import type { ClickObject } from "metabase-lib";
import { isID, isFK } from "metabase-lib/types/utils/isa";
import type {
  DatasetColumn,
  DatasetData,
  RowValue,
  RowValues,
  Series,
  VisualizationSettings,
} from "metabase-types/api";

import MiniBar from "../MiniBar";
import { CELL_DEFAULT_INDENT } from "../TableInteractive/TableInteractive";

import { CellRoot, CellContent } from "./TableCell.styled";

type GetCellDataOpts = {
  value: RowValue;
  clicked: ClickObject;
  extraData: Record<string, unknown>;
  cols: DatasetColumn[];
  rows: RowValues[];
  columnIndex: number;
  columnSettings: OptionsType;
  cellImageSize: any;
  cellHeight: any;
};

function getCellData({
  value,
  clicked,
  extraData,
  cols,
  rows,
  columnIndex,
  columnSettings,
  cellImageSize,
  cellHeight,
}: GetCellDataOpts) {
  if (value == null) {
    return "-";
  }
  if (columnSettings["show_mini_bar"]) {
    return (
      <MiniBar
        value={value}
        options={columnSettings}
        extent={getColumnExtent(cols, rows, columnIndex)}
      />
    );
  }
  return formatValue(value, {
    ...columnSettings,
    clicked: { ...clicked, extraData },
    type: "cell",
    jsx: true,
    rich: true,
    cellHeight,
    cellImageSize,
  });
}

interface TableCellProps {
  value: RowValue;
  data: DatasetData;
  series: Series;
  settings: VisualizationSettings;
  rowIndex: number;
  columnIndex: number;
  isPivoted: boolean;
  getCellBackgroundColor: (
    value: RowValue,
    rowIndex: number,
    columnName: string,
  ) => string | undefined;
  getExtraDataForClick: (clickObject: ClickObject) => Record<string, unknown>;
  checkIsVisualizationClickable: (clickObject: ClickObject) => boolean;
  onVisualizationClick?: (clickObject: ClickObject) => void;
  getCellTextColor: (
    value: any,
    rowIndex: number,
    columnName: string,
  ) => string | null;
  getCellFontStyle: (
    value: any,
    rowIndex: number,
    columnName: string,
  ) => string | null;
  lineBreak: any;
  style: any;
  cellSetClickedRowHandler: any;
  clickedRow: number;
  isTableWithVirtualization: boolean;
  cellHeight: number;
  isHaveBigCell: boolean;
}

export function TableCell({
  value,
  data,
  series,
  settings,
  rowIndex,
  columnIndex,
  isPivoted,
  getCellBackgroundColor,
  getCellTextColor,
  getCellFontStyle,
  getExtraDataForClick,
  checkIsVisualizationClickable,
  onVisualizationClick,
  lineBreak,
  style,
  cellSetClickedRowHandler,
  clickedRow,
  isTableWithVirtualization,
  cellHeight,
  isHaveBigCell,
}: TableCellProps) {
  const { rows, cols } = data;
  const column = cols[columnIndex];
  const columnSettings = settings.column(column);
  const isCellAutoHeiht = settings["table.cell_auto_height"];

  const cellIndentVertical = settings["table.cell_auto_indent"]
    ? CELL_DEFAULT_INDENT
    : settings["table.cell_indent_vertical"];
  const cellIndentHorizontal = settings["table.cell_auto_indent"]
    ? CELL_DEFAULT_INDENT
    : settings["table.cell_indent_left"];
  const cellFontSize = settings["table.cell_font_size"];

  const isHighlightClickedRow = settings["table.toggle_clicked_row_higlight"];
  const clickedRowColor = settings["table.row_clicked_color"];
  const backgroundColorOnHover = settings["table.cell_background_color_hover"];
  const isTransparent = settings["table.cell_transparent"];

  const cellHorizontalAligment = settings["table.cell_horizontal_alignment"];
  const cellVerticalAligment = settings["table.cell_vertical_alignment"];

  const cellBorderColor = settings["table.grid_color"];

  const cellImageSize = settings["table.cell_image_size"];

  const clickedRowData = useMemo(
    () =>
      getTableClickedObjectRowData(
        // @ts-expect-error -- visualizations/lib/table should be typed
        series,
        rowIndex,
        columnIndex,
        isPivoted,
        data,
      ),
    [data, series, rowIndex, columnIndex, isPivoted],
  );

  const clicked = useMemo(
    () =>
      getTableCellClickedObject(
        data,
        settings,
        rowIndex,
        columnIndex,
        isPivoted,
        clickedRowData,
      ),
    [data, settings, rowIndex, columnIndex, isPivoted, clickedRowData],
  );

  const extraData = useMemo(
    () => getExtraDataForClick?.(clicked) ?? {},
    [clicked, getExtraDataForClick],
  );

  const viewAs = columnSettings?.view_as;
  const isImageCell = viewAs === "image";

  const cellData = useMemo(() => {
    const comp = getCellData({
      value,
      clicked,
      extraData,
      cols,
      rows,
      columnIndex,
      columnSettings,
      cellHeight: cellHeight - cellIndentVertical * 2,
      cellImageSize,
    });
    const columLineBreak = lineBreak || "hidden";
    const isWrapLongRows = columLineBreak === "hidden" ? false : true;

    return (
      <Ellipsified
        style={{
          overflow: isWrapLongRows ? "visible" : "hidden",
          lineHeight: 1,
          whiteSpace: isWrapLongRows ? "normal" : "nowrap",
        }}
      >
        {comp}
      </Ellipsified>
    );
  }, [
    value,
    clicked,
    extraData,
    cols,
    rows,
    columnIndex,
    columnSettings,
    cellHeight,
    cellIndentVertical,
    cellImageSize,
    lineBreak,
  ]);

  const isLink = isValidElement(cellData) && cellData.type === ExternalLink;
  const isClickable = !isLink;

  const onClick = useCallback(
    (e: React.MouseEvent) => {
      if (checkIsVisualizationClickable(clicked)) {
        onVisualizationClick?.({
          ...clicked,
          element: e.currentTarget,
          extraData,
        });
      }
    },
    [checkIsVisualizationClickable, clicked, onVisualizationClick, extraData],
  );

  const cellRootOnClickHandler = useCallback(
    (_: React.MouseEvent) => {
      cellSetClickedRowHandler(rowIndex);
    },
    [cellSetClickedRowHandler, rowIndex],
  );

  const backgroundColor = useMemo(() => {
    const isOddRow = rowIndex % 2 === 0;

    const oddRowsBackgroundColor = settings["table.cell_odd_background_color"];
    const evenRowsBackgroundColor =
      settings["table.cell_even_background_color"];

    const backgroundColorConditional = getCellBackgroundColor?.(
      value,
      rowIndex,
      column.name,
    );

    if (rowIndex === clickedRow && isHighlightClickedRow) {
      return clickedRowColor;
    } else if (backgroundColorConditional) {
      return backgroundColorConditional;
    } else if (isTransparent) {
      return "transparent";
    } else {
      return isOddRow ? oddRowsBackgroundColor : evenRowsBackgroundColor;
    }
  }, [
    rowIndex,
    clickedRow,
    isHighlightClickedRow,
    isTransparent,
    clickedRowColor,
    getCellBackgroundColor,
    value,
    column,
    settings,
  ]);

  const isCellTableId = value != null && isID(column);
  const isCellTableFK = value != null && isFK(column);
  const isCellLink = isClickable && isID(column);

  const textColor = useMemo(() => {
    const linkColor = settings["table.cell_link_color"];
    const cellTextColor = settings["table.cell_text_color"];
    const conditionalTextColor = getCellTextColor?.(
      value,
      rowIndex,
      column.name,
    );

    const isLinkType =
      isCellTableId ||
      isCellTableFK ||
      isCellLink ||
      column.display_name === "Email";

    const settingsTextColor = isLinkType ? linkColor : cellTextColor;

    return conditionalTextColor || settingsTextColor;
  }, [
    settings,
    getCellTextColor,
    value,
    rowIndex,
    column,
    isCellTableId,
    isCellTableFK,
    isCellLink,
  ]);

  const classNames = useMemo(
    () =>
      cx("fullscreen-normal-text fullscreen-night-text", {
        "Table-ID": isCellTableId,
        "Table-FK": isCellTableFK,
        link: isCellLink,
      }),
    [isCellTableId, isCellTableFK, isCellLink],
  );

  const cellContentComponent = useMemo(() => {
    const conditionalFontStyle = getCellFontStyle?.(
      value,
      rowIndex,
      column.name,
    );

    const cellFontStyle = conditionalFontStyle?.["font_italic"]
      ? "italic"
      : settings["table.cell_font_italic"]
      ? "italic"
      : "normal";

    const cellFontWeight = conditionalFontStyle?.["font_bold"]
      ? "bold"
      : settings["table.cell_font_bold"]
      ? "bold"
      : "normal";

    const fontStyle = {
      fontSize: cellFontSize,
      fontStyle: cellFontStyle,
      fontWeight: cellFontWeight,
    };

    const calculatedHeigth = isCellTableId ? cellFontSize * 1.5 : cellFontSize;
    const heightForImage = settings["table.cell_auto_height"]
      ? calculatedHeigth
      : "100%";
    const height = isImageCell ? heightForImage : calculatedHeigth;

    return (
      <CellContent
        className="cellData"
        isClickable={isClickable}
        onClick={isClickable ? onClick : undefined}
        data-testid="cell-data"
        lineBreak={lineBreak}
        isOverflowVisible
        textColor={textColor}
        style={{
          ...fontStyle,
          ...(!isCellTableId ? { paddingRight: "0.08em" } : {}),
          ...style,
          color: textColor,
          height: height,
        }}
      >
        {cellData}
      </CellContent>
    );
  }, [
    cellData,
    cellFontSize,
    column.name,
    getCellFontStyle,
    isCellTableId,
    isClickable,
    isImageCell,
    lineBreak,
    onClick,
    rowIndex,
    settings,
    style,
    textColor,
    value,
  ]);

  return isTableWithVirtualization ? (
    <CellRoot
      className={classNames}
      backgroundColor={backgroundColor}
      onHoverBackgroundColor={backgroundColorOnHover}
      isRightAligned={isColumnRightAligned(column)}
      lineBreak={lineBreak}
      borderColor={cellBorderColor}
      style={{
        ...style,
        height: "100%",
        display: "flex",
        alignItems: cellVerticalAligment,
        paddingTop: isCellAutoHeiht ? 0 : cellIndentVertical,
        paddingLeft: cellIndentHorizontal,
        justifyContent: cellHorizontalAligment,
      }}
      onClick={cellRootOnClickHandler}
      htmlElementType={"div"}
    >
      {cellContentComponent}
    </CellRoot>
  ) : (
    <CellRoot
      className={classNames}
      backgroundColor={backgroundColor}
      onHoverBackgroundColor={backgroundColorOnHover}
      isRightAligned={isColumnRightAligned(column)}
      lineBreak={lineBreak}
      style={{
        ...style,
      }}
      onClick={cellRootOnClickHandler}
      htmlElementType={"td"}
    >
      <div
        style={{
          display: "flex",
          alignItems: cellVerticalAligment,
          paddingTop: isCellAutoHeiht ? 0 : cellIndentVertical,
          paddingLeft: cellIndentHorizontal,
          height: "100%",
          justifyContent: cellHorizontalAligment,
        }}
      >
        {cellContentComponent}
      </div>
    </CellRoot>
  );
}
