import styled from "@emotion/styled";

export const Input = styled.input`
  opacity: 0;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  -webkit-appearance: none;
  cursor: pointer;
  width: 0;
  height: 0;
  border: none;
  border-radius: 50%;
  background: none;
  box-sizing: border-box;

  &::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  &::-webkit-color-swatch {
    border: solid 3px #000;
    border-radius: 50%;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  padding: 0.75rem 0.75rem 0.25rem 0.75rem;
  align-items: center;
  position: relative;
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  width: 100%;
`;

export const ControlsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
`;

export const ContainerText = styled.div`
  flex-grow: 2;
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
`;
