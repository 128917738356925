import PropTypes from "prop-types";
import { memo } from "react";

import { Ellipsified } from "metabase/core/components/Ellipsified";

import {
  LegendItemDot,
  LegendItemLabel,
  LegendItemRemoveIcon,
  LegendItemRoot,
  LegendItemTitle,
} from "./LegendItem.styled";

const propTypes = {
  label: PropTypes.string,
  localIndex: PropTypes.number,
  itemIndex: PropTypes.number,
  color: PropTypes.string,
  textColor: PropTypes.string,
  isMuted: PropTypes.bool,
  isVertical: PropTypes.bool,
  isReversed: PropTypes.bool,
  onHoverChange: PropTypes.func,
  onSelectSeries: PropTypes.func,
  onRemoveSeries: PropTypes.func,
};

const LegendItem = ({
  label,
  localIndex,
  color,
  textColor,
  isMuted,
  isVertical,
  isReversed,
  onHoverChange,
  onSelectSeries,
  onRemoveSeries,
  itemIndex,
}) => {
  const handleItemClick = event => {
    onSelectSeries && onSelectSeries(event, itemIndex, isReversed);
  };

  const handleItemMouseEnter = event => {
    onHoverChange &&
      onHoverChange({ index: localIndex, element: event.currentTarget });
  };

  const handleItemMouseLeave = () => {
    onHoverChange && onHoverChange();
  };

  const handleRemoveClick = event => {
    onRemoveSeries && onRemoveSeries(event, localIndex);
  };

  return (
    <LegendItemRoot isVertical={isVertical} data-testid="legend-item">
      <LegendItemLabel
        isMuted={isMuted}
        onClick={onSelectSeries && handleItemClick}
        onMouseEnter={onHoverChange && handleItemMouseEnter}
        onMouseLeave={onHoverChange && handleItemMouseLeave}
      >
        <LegendItemDot color={color} />
        <LegendItemTitle className="fullscreen-normal-text fullscreen-night-text">
          <Ellipsified textColor={textColor}>{label}</Ellipsified>
        </LegendItemTitle>
      </LegendItemLabel>
      {onRemoveSeries && <LegendItemRemoveIcon onClick={handleRemoveClick} />}
    </LegendItemRoot>
  );
};

LegendItem.propTypes = propTypes;

export default memo(LegendItem);
