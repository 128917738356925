import React, { useCallback, useEffect } from "react";
import { t } from "ttag";
import { AuthButtonRoot } from "./OpenIDButton.styled";

interface OpenIDButtonProps {
  onLogin: (token: string, redirectUrl?: string) => void;
  enable: boolean;
  clientID: string | null;
  authorizationEndpoint: string | null;
  location: any;
}

const OpenIDButton = ({
  onLogin,
  enable,
  authorizationEndpoint,
  clientID,
  location,
}: OpenIDButtonProps) => {
  const handleLogin = useCallback(
    async (params: any) => {
      try {
        onLogin(params);
      } catch (error) {
        console.error(error);
      }
    },
    [onLogin],
  );

  useEffect(() => {
    const params = location.query;
    if (params.code) {
      params["redirect_uri"] = window.location.origin + window.location.pathname;
      handleLogin(params);
    }
  }, [handleLogin, location.query, onLogin]);

  return (
    <AuthButtonRoot>
      {enable && authorizationEndpoint && clientID && (
        <a
          href={`${authorizationEndpoint}?client_id=${clientID}&response_type=code&scope=openid%20profile%20email&redirect_uri=${
            window.location.origin + window.location.pathname
          }`}
          rel="noreferrer"
        >{t`Sign in with OpenID`}</a>
      )}
    </AuthButtonRoot>
  );
};

export default OpenIDButton;
