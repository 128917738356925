import styled from "@emotion/styled";
import { color } from "metabase/lib/colors";
import { breakpointMinExtraLarge } from "metabase/styled-components/theme";
import Radio from "metabase/core/components/Radio";

export const Title = styled.span`
  color: ${color("text-dark")};
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.5rem;

  ${breakpointMinExtraLarge} {
    font-size: 1.5rem;
  }
`;

export const SubTitle = styled.p`
  color: ${color("text-dark")};
  font-size: 1.1rem;
  font-weight: normal;
  line-height: 1.4rem;

  ${breakpointMinExtraLarge} {
    font-size: 1.5rem;
  }
`;

export const Container = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.5rem;
  margin: 2rem 0 0 0.5rem;
  display: flex;
  flex-direction: column;
`;

export const RadioContainer = styled.div`
  span {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.5rem;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;

export const ButtonUploadContainer = styled.div`
  margin-right: 10px;
  background-color: #f9fbfc;
`;
