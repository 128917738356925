import d3 from "d3";
import L from "leaflet";
import _ from "underscore";

import { isPK } from "metabase-lib/types/utils/isa";

import LeafletMap from "./LeafletMap";

const MARKER_ICON = L.icon({
  iconUrl: "app/assets/img/pin.png",
  iconSize: [28, 32],
  iconAnchor: [15, 24],
  popupAnchor: [0, -13],
});

export default class LeafletMarkerPinMap extends LeafletMap {
  componentDidMount() {
    super.componentDidMount();

    this.pinMarkerLayer = L.layerGroup([]).addTo(this.map);
    this.componentDidUpdate({ a: 1 }, { b: 2 });
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);
    if (prevProps.settings !== this.props.settings) {
      this.updateMarkers();
    }
  }

  updateMarkers() {
    const {
      settings,
      points,
      series: [
        {
          data: { cols, rows },
        },
      ],
    } = this.props;
    const isNeedRenderCircleMarker = settings["map.show_circle"];

    const metricCircleIndex =
      _.findIndex(cols, col => col.name === settings["map.metric_circle"]) || 0;
    const getRowCircleValue = row => row[metricCircleIndex] || 0;

    const valuesForMetric = rows.map(getRowCircleValue);

    const minRadius = Math.min(...valuesForMetric);
    const maxRadius = Math.max(...valuesForMetric);

    const minStepRadius = settings["map.min_circle_step_radius"] || 20000;
    const maxStepRadius = settings["map.max_circle_step_radius"] || 80000;

    const radiusScale = d3.scale
      .sqrt()
      .domain([minRadius, maxRadius])
      .range([minStepRadius, maxStepRadius]);

    try {
      const { pinMarkerLayer } = this;

      pinMarkerLayer.clearLayers();

      points.forEach((point, index) => {
        const markerRadius = radiusScale(valuesForMetric[index]);

        const marker = this._createMarker(
          index,
          isNeedRenderCircleMarker,
          markerRadius,
        );
        marker.setLatLng(point);
        pinMarkerLayer.addLayer(marker);
      });
    } catch (err) {
      console.error(err);
      this.props.onRenderError(err.message || err);
    }
  }

  _createMarker = (rowIndex, isNeedRenderCircleMarker, radius = 10) => {
    const {
      onHoverChange,
      onVisualizationClick,
      settings,
      series: [
        {
          data: { cols, rows },
        },
      ],
    } = this.props;

    const colorGetter = settings["map._circle_color_getter"];

    const fillColor = settings
      ? settings["map.color_circle"] || "blue"
      : "blue";
    const color = colorGetter && colorGetter(rows[rowIndex]);
    const circleColor = color ? color : fillColor;

    const marker = L.marker([0, 0], { icon: MARKER_ICON });
    const circle = this._createCircle(circleColor, radius);

    const mapElement = isNeedRenderCircleMarker ? circle : marker;

    if (onHoverChange) {
      mapElement.on("mousemove", e => {
        const hover = {
          dimensions: cols.map((col, colIndex) => ({
            value: rows[rowIndex][colIndex],
            column: col,
          })),
          element: isNeedRenderCircleMarker
            ? mapElement._path
            : mapElement._icon,
        };

        onHoverChange(hover);
      });

      mapElement.on("mouseout", () => {
        onHoverChange(null);
      });
    }

    if (onVisualizationClick) {
      mapElement.on("click", () => {
        // if there is a primary key then associate a pin with it
        const pkIndex = _.findIndex(cols, isPK);
        const hasPk = pkIndex >= 0;

        const data = cols.map((col, index) => ({
          col,
          value: rows[rowIndex][index],
        }));

        onVisualizationClick({
          value: hasPk ? rows[rowIndex][pkIndex] : null,
          column: hasPk ? cols[pkIndex] : null,
          element: isNeedRenderCircleMarker
            ? mapElement._path
            : mapElement._icon,
          origin: { row: rows[rowIndex], cols },
          settings,
          data,
        });
      });
    }

    return mapElement;
  };

  _createCircle(fillColor, radius = 10) {
    return L.circleMarker([0, 0], {
      radius: radius,
      color: fillColor,
      fillColor: fillColor,
      fillOpacity: 1,
    });
  }
}
