/* eslint-disable react/prop-types */
import React from "react";

import _ from "underscore";
import Button from "metabase/core/components/Button";

type TDefaultRenderInput = {
  value: string;
  onChange: (e: string)=> void;
  placeholder: string;
}

const DefaultRenderInput = ({ value, onChange, placeholder }:TDefaultRenderInput) => (
  <input
    className="input"
    value={value}
    placeholder={placeholder}
    onChange={e => onChange(e.target.value)}
  />
);

type TMapEditor = {
  value: string;
  onChange: (e: string)=> void;
  className?: string;
  style?: any;
  keyHeader?: any;
  valueHeader?: any;
  keyPlaceholder?: string;
  valuePlaceholder?: string;
  renderKeyInput?: any;
  renderValueInput?: any;
  divider?: any;
  canAdd?: boolean;
  canDelete?: boolean;
  addText?: string;
  swapKeyAndValue?: any;
}
const MapEditor = ({
                         value,
                         onChange,
                         className,
                         style,
                         keyHeader,
                         valueHeader,
                         keyPlaceholder = "Key",
                         valuePlaceholder = "Value",
                         renderKeyInput = DefaultRenderInput,
                         renderValueInput = DefaultRenderInput,
                         divider,
                         canAdd = true,
                         canDelete = true,
                         addText = "Add",
                         swapKeyAndValue,
                       }: TMapEditor) => {
  const mapping = value;
  const entries = Object.entries(mapping);
  return (
    <table className={className} style={style}>
      {keyHeader || valueHeader ? (
        <thead>
        <tr>
          <td>{!swapKeyAndValue ? keyHeader : valueHeader}</td>
          <td />
          <td>{!swapKeyAndValue ? valueHeader : keyHeader}</td>
        </tr>
        </thead>
      ) : null}
      <tbody>
      {entries.map(([key, value], index) => {
        const keyInput = renderKeyInput({
          value: key,
          placeholder: keyPlaceholder,
          onChange: newKey =>
            onChange(replaceMappingKey(mapping, key, newKey)),
        });
        const valueInput = renderValueInput({
          value: value,
          placeholder: valuePlaceholder,
          onChange: newValue =>
            onChange(replaceMappingValue(mapping, key, newValue)),
        });
        return (
          <tr key={index}>
            <td className="pb1">
              {!swapKeyAndValue ? keyInput : valueInput}
            </td>
            <td className="pb1 px1">{divider}</td>
            <td className="pb1">
              {!swapKeyAndValue ? valueInput : keyInput}
            </td>
            {canDelete && (
              <td>
                <Button
                  icon="close"
                  type="button" // prevent submit. should be the default but it's not
                  borderless
                  onClick={() => onChange(removeMapping(mapping, key))}
                />
              </td>
            )}
          </tr>
        );
      })}
      {!("" in mapping) &&
        _.every(mapping, value => value != null) &&
        canAdd && (
          <tr>
            <td colSpan={2}>
              <Button
                icon="add"
                type="button" // prevent submit. should be the default but it's not
                borderless
                className="text-brand p0 py1"
                onClick={() => onChange(addMapping(mapping))}
              >
                {addText}
              </Button>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

const addMapping = (mappings: any) => {
  return { ...mappings, "": null };
};

const removeMapping = (mappings: any, prevKey: string) => {
  mappings = { ...mappings };
  delete mappings[prevKey];
  return mappings;
};

const replaceMappingValue = (mappings: any, oldKey: string, newValue: string) => {
  return { ...mappings, [oldKey]: newValue };
};

const replaceMappingKey = (mappings: any, oldKey: string, newKey: string) => {
  const newMappings = {};
  for (const key in mappings) {
    newMappings[key === oldKey ? newKey : key] = mappings[key];
  }
  return newMappings;
};

export default MapEditor;
