import crossfilter from "crossfilter";
import d3 from "d3";
import dc from "dc";
import { assocIn, updateIn } from "icepick";
import { t } from "ttag";
import _ from "underscore";

import { lighten } from "metabase/lib/colors";
import { keyForSingleSeries } from "metabase/visualizations/lib/settings/series";
import * as Lib from "metabase-lib";
import Question from "metabase-lib/Question";
import { isNative } from "metabase-lib/queries/utils/card";

import lineAndBarOnRender from "./LineAreaBarPostRender";
import {
  applyChartTimeseriesXAxis,
  applyChartQuantitativeXAxis,
  applyChartOrdinalXAxis,
  applyChartYAxis,
  getYValueFormatter,
} from "./apply_axis";
import { setupTooltips } from "./apply_tooltips";
import { applyColorFormatting } from "./colorFormatting";
import fillMissingValuesInDatas from "./fill_data";
import { lineAddons } from "./graph/addons";
import { initBrush } from "./graph/brush";
import { stack, stackOffsetDiverging } from "./graph/stack";
import {
  forceSortedGroupsOfGroups,
  initChart, // TODO - probably better named something like `initChartParent`
  makeIndexMap,
  reduceGroup,
  isTimeseries,
  isQuantitative,
  isHistogram,
  isOrdinal,
  isHistogramBar,
  isStacked,
  isNormalized,
  getDatas,
  getFirstNonEmptySeries,
  getXValues,
  getXInterval,
  syntheticStackedBarsForWaterfallChart,
  xValueForWaterfallTotal,
  isDimensionTimeseries,
  isRemappedToString,
  isMultiCardSeries,
  hasClickBehavior,
  replaceNullValuesForOrdinal,
  shouldSplitYAxis,
} from "./renderer_utils";
import {
  getNormalizedStackedTrendDatas,
  getTrendDataPointsFromInsight,
} from "./trends";
import {
  computeSplit,
  computeMaxDecimalsForValues,
  getFriendlyName,
  colorShades,
} from "./utils";
import { NULL_DIMENSION_WARNING, unaggregatedDataWarning } from "./warnings";

const BAR_PADDING_RATIO = 0.2;
const DEFAULT_INTERPOLATION = "linear";

const enableBrush = (series, onChangeCardAndRun) =>
  !!(
    onChangeCardAndRun &&
    !isMultiCardSeries(series) &&
    !isNative(series[0].card) &&
    !isRemappedToString(series) &&
    !hasClickBehavior(series)
  );

/************************************************************ SETUP ************************************************************/

function checkSeriesIsValid({ series, maxSeries }) {
  if (getFirstNonEmptySeries(series).data.cols.length < 2) {
    throw new Error(t`This chart type requires at least 2 columns.`);
  }

  if (series.length > maxSeries) {
    throw new Error(
      t`This chart type doesn't support more than ${maxSeries} series of data.`,
    );
  }
}

function getXAxisProps(props, datas, warn) {
  const rawXValues = getXValues(props);
  const isHistogram = isHistogramBar(props);
  const xInterval = getXInterval(props, rawXValues, warn);
  const isWaterfallWithTotalColumn =
    props.chartType === "waterfall" && props.settings["waterfall.show_total"];

  let xValues = rawXValues;

  if (isHistogram) {
    // For histograms we add a fake x value one xInterval to the right
    // This compensates for the barshifting we do align ticks
    xValues = [...rawXValues, Math.max(...rawXValues) + xInterval];
  } else if (isWaterfallWithTotalColumn) {
    xValues = [...rawXValues, xValueForWaterfallTotal(props)];
  } else if (isOrdinal(props.settings)) {
    xValues = xValues.map(x => replaceNullValuesForOrdinal(x));
  }

  return {
    isHistogramBar: isHistogram,
    xDomain: d3.extent(xValues),
    xInterval,
    xValues,
  };
}

///------------------------------------------------------------ DIMENSIONS & GROUPS ------------------------------------------------------------///

function getDimensionsAndGroupsForScatterChart(datas) {
  const dataset = crossfilter(datas);
  const dimension = dataset.dimension(row => row);
  const groups = datas.map(data => {
    const dim = crossfilter(data).dimension(row => row);
    return [dim.group().reduceSum(d => d[1] ?? 1)];
  });

  return { dimension, groups };
}

/// Add '% ' in from of the names of the appropriate series. E.g. 'Sum' becomes '% Sum'
function addPercentSignsToDisplayNames(series) {
  return series.map(s =>
    updateIn(s, ["data", "cols", 1], col => ({
      ...col,
      display_name: "% " + getFriendlyName(col),
    })),
  );
}

// Store a "decimals" property on the column that is normalized
function addDecimalsToPercentColumn(series, decimals) {
  return series.map(s => assocIn(s, ["data", "cols", 1, "decimals"], decimals));
}

function getDimensionsAndGroupsAndUpdateSeriesDisplayNamesForStackedChart(
  props,
  datas,
  warn,
) {
  const dataset = crossfilter();

  const normalized = isNormalized(props.settings, datas);
  // get the sum of the metric for each dimension value in order to scale
  const scaleFactors = {};
  if (normalized) {
    for (const data of datas) {
      for (const [d, m] of data) {
        scaleFactors[d] = (scaleFactors[d] || 0) + m;
      }
    }

    const { _raw } = props.series;
    props.series = addPercentSignsToDisplayNames(props.series);

    const normalizedValues = datas.flatMap(data =>
      data.map(([d, m]) => m / scaleFactors[d]),
    );
    const decimals = computeMaxDecimalsForValues(normalizedValues, {
      style: "percent",
      maximumSignificantDigits: 2,
    });
    props.series = addDecimalsToPercentColumn(props.series, decimals);
    props.series._raw = _raw;
  }

  datas.map((data, i) =>
    dataset.add(
      data.map(d => ({
        [0]: d[0],
        [i + 1]: normalized ? d[1] / scaleFactors[d[0]] : d[1],
      })),
    ),
  );

  const dimension = dataset.dimension(d => d[0]);
  const groups = [
    datas.map((data, seriesIndex) => {
      // HACK: waterfall chart is a stacked bar chart that supports only one series
      // and the groups number does not match the series number due to the implementation
      const realSeriesIndex = props.chartType === "waterfall" ? 0 : seriesIndex;

      return reduceGroup(dimension.group(), seriesIndex + 1, () =>
        warn(
          unaggregatedDataWarning(props.series[realSeriesIndex].data.cols[0]),
        ),
      );
    }),
  ];

  return { dimension, groups };
}

function getDimensionsAndGroupsForOther({ series }, datas, warn) {
  const dataset = crossfilter();
  datas.map(data => dataset.add(data));

  const dimension = dataset.dimension(d => d[0]);
  const groups = datas.map((data, seriesIndex) => {
    // If the value is empty, pass a dummy array to crossfilter
    data = data.length > 0 ? data : [[null, null]];

    const dim = crossfilter(data).dimension(d => d[0]);

    return data[0]
      .slice(1)
      .map((_, metricIndex) =>
        reduceGroup(dim.group(), metricIndex + 1, () =>
          warn(unaggregatedDataWarning(series[seriesIndex].data.cols[0])),
        ),
      );
  });

  return { dimension, groups };
}

function getYExtentsForGroups(groups) {
  return groups.map(group => {
    const sums = new Map();
    for (const g of group) {
      for (const { key, value } of g.all()) {
        const prevValue = sums.get(key) || 0;
        sums.set(key, prevValue + value);
      }
    }
    return d3.extent(Array.from(sums.values()));
  });
}

/// Return an object containing the `dimension` and `groups` for the chart(s).
/// For normalized stacked charts, this also updates the dispaly names to add a percent in front of the name (e.g. 'Sum' becomes '% Sum')
/// This is only exported for testing.
export function getDimensionsAndGroupsAndUpdateSeriesDisplayNames(
  props,
  originalDatas,
  warn,
) {
  const { settings, chartType, series } = props;

  const thicknessIndexes = [];
  if (settings["graph.bar.thickness"]) {
    thicknessIndexes.push(
      series[0].data.cols.findLastIndex(
        item => item.name === settings["graph.bar.thickness"],
      ),
    );
  }
  if (settings["graph.dot.thickness"]) {
    thicknessIndexes.push(
      series[0].data.cols.findLastIndex(
        item => item.name === settings["graph.dot.thickness"],
      ),
    );
  }
  if (settings["graph.line.thickness"]) {
    thicknessIndexes.push(
      series[0].data.cols.findLastIndex(
        item => item.name === settings["graph.line.thickness"],
      ),
    );
  }

  const datas =
    chartType === "waterfall"
      ? syntheticStackedBarsForWaterfallChart(originalDatas, settings, series)
      : originalDatas;

  if (thicknessIndexes.length > 0) {
    thicknessIndexes.sort();
    thicknessIndexes.forEach((thicknessIndex, i) => {
      datas.forEach(data =>
        data.forEach(items => items.splice(thicknessIndex - i, 1)),
      );
    });
  }

  const isStackedBar = isStacked(settings, datas) || chartType === "waterfall";

  const { groups, dimension } =
    chartType === "scatter"
      ? getDimensionsAndGroupsForScatterChart(datas)
      : isStackedBar
      ? getDimensionsAndGroupsAndUpdateSeriesDisplayNamesForStackedChart(
          props,
          datas,
          warn,
        )
      : getDimensionsAndGroupsForOther(props, datas, warn);
  const yExtents = getYExtentsForGroups(groups);
  return { groups, dimension, yExtents };
}

///------------------------------------------------------------ Y AXIS PROPS ------------------------------------------------------------///

function getYAxisSplit(
  { settings, chartType, isScalarSeries, series },
  datas,
  yExtents,
) {
  const seriesAxis = series.map(single => settings.series(single)["axis"]);
  const left = [];
  const right = [];
  const auto = [];
  for (const [index, axis] of seriesAxis.entries()) {
    if (axis === "left") {
      left.push(index);
    } else if (axis === "right") {
      right.push(index);
    } else {
      auto.push(index);
    }
  }

  if (
    shouldSplitYAxis(
      { settings, chartType, isScalarSeries, series },
      datas,
      yExtents,
    )
  ) {
    // NOTE: this version computes the split after assigning fixed left/right
    // which causes other series to move around when changing the setting
    // return computeSplit(yExtents, left, right);

    // NOTE: this version computes a split with all axis unassigned, then moves
    // assigned ones to their correct axis
    const [autoLeft, autoRight] = computeSplit(yExtents);
    return [
      _.uniq([...left, ...autoLeft.filter(index => !seriesAxis[index])]),
      _.uniq([...right, ...autoRight.filter(index => !seriesAxis[index])]),
    ];
  } else {
    // assign all auto to the left
    return [[...left, ...auto], right];
  }
}

function getYAxisSplitLeftAndRight(series, yAxisSplit, yExtents) {
  return yAxisSplit.map(indexes => ({
    series: indexes.map(index => series[index]),
    extent: d3.extent([].concat(...indexes.map(index => yExtents[index]))),
  }));
}

function getIsSplitYAxis(left, right) {
  return right && right.series.length && left && left.series.length > 0;
}

function getYAxisProps(props, yExtents, datas) {
  const yAxisSplit = getYAxisSplit(props, datas, yExtents);

  const [yLeftSplit, yRightSplit] = getYAxisSplitLeftAndRight(
    props.series,
    yAxisSplit,
    yExtents,
  );

  return {
    yExtents,
    yAxisSplit,
    yExtent: d3.extent([].concat(...yExtents)),
    yLeftSplit,
    yRightSplit,
    isSplit: getIsSplitYAxis(yLeftSplit, yRightSplit),
  };
}

/// make the `onBrushChange()` and `onBrushEnd()` functions we'll use later, as well as an `isBrushing()` function to check
/// current status.
function makeBrushChangeFunctions({ series, onChangeCardAndRun, metadata }) {
  let _isBrushing = false;

  const isBrushing = () => _isBrushing;

  function onBrushChange() {
    _isBrushing = true;
  }

  function onBrushEnd(range) {
    _isBrushing = false;

    if (range) {
      const column = series[0].data.cols[0];
      const card = series[0].card;
      const question = new Question(card, metadata);
      const query = question.query();
      const stageIndex = -1;

      const [start, end] = range;

      if (isDimensionTimeseries(series)) {
        const nextQuery = Lib.updateTemporalFilter(
          query,
          stageIndex,
          column,
          new Date(start).toISOString(),
          new Date(end).toISOString(),
        );
        const updatedQuestion = question.setQuery(nextQuery);
        const nextCard = updatedQuestion.card();

        onChangeCardAndRun({
          nextCard,
          previousCard: card,
        });
      } else {
        const nextQuery = Lib.updateNumericFilter(
          query,
          stageIndex,
          column,
          start,
          end,
        );
        const updatedQuestion = question.setQuery(nextQuery);
        const nextCard = updatedQuestion.card();

        onChangeCardAndRun({
          nextCard,
          previousCard: card,
        });
      }
    }
  }

  return { isBrushing, onBrushChange, onBrushEnd };
}

/************************************************************ INDIVIDUAL CHART SETUP ************************************************************/

function getDcjsChart(cardType, parent) {
  switch (cardType) {
    case "line":
      return lineAddons(dc.lineChart(parent));
    case "area":
      return lineAddons(dc.lineChart(parent));
    case "bar":
    case "waterfall":
      return dc.barChart(parent);
    case "scatter":
      return dc.bubbleChart(parent);
    default:
      return dc.barChart(parent);
  }
}

function applyChartLineBarSettings(
  chart,
  settings,
  chartType,
  seriesSettings,
  forceCenterBar,
) {
  // LINE/AREA:
  // for chart types that have an 'interpolate' option (line/area charts), enable based on settings
  if (chart.interpolate) {
    chart.interpolate(
      seriesSettings["line.interpolate"] ||
        settings["line.interpolate"] ||
        DEFAULT_INTERPOLATION,
    );
  }

  // AREA:
  if (chart.renderArea) {
    chart.renderArea(chartType === "area");
  }

  // BAR:
  if (chart.barPadding) {
    chart
      .barPadding(BAR_PADDING_RATIO)
      .centerBar(
        forceCenterBar || settings["graph.x_axis.scale"] !== "ordinal",
      );
  }

  // AREA/BAR:
  if (settings["stackable.stack_type"] === "stacked") {
    chart.stackLayout(stack().offset(stackOffsetDiverging));
  }
}

const BUBBLE_SIZE_INDEX = 2;

const getBubbleSizeMaxDomain = datas => {
  const seriesData = datas.flat();
  const sizeValues = seriesData.map(data => data[BUBBLE_SIZE_INDEX]);
  return d3.max(sizeValues);
};

function configureScatterChart(chart, datas, index, _, settings) {
  chart.keyAccessor(d => d.key[0]).valueAccessor(d => d.key[1]);
  if (chart.radiusValueAccessor && datas[index][0]) {
    const hasBubbleRadiusValues = datas[index][0].length > BUBBLE_SIZE_INDEX;
    const bubbleSizeMaxDomain = settings["scatter.bubble"]
      ? getBubbleSizeMaxDomain(datas)
      : null;

    if (hasBubbleRadiusValues) {
      const BUBBLE_SCALE_FACTOR_MAX = 64;
      chart
        .radiusValueAccessor(d => d.key[2])
        .r(
          d3.scale
            .sqrt()
            .domain([0, bubbleSizeMaxDomain * BUBBLE_SCALE_FACTOR_MAX])
            .range([0, 1]),
        );
    } else {
      chart.radiusValueAccessor(d => 1);
      chart.MIN_RADIUS = 3;
    }
    chart.minRadiusWithLabel(Infinity);
  }
}

/// set the colors for a CHART based on the number of series and type of chart
/// see http://dc-js.github.io/dc.js/docs/html/dc.colorMixin.html
function setChartColor({ series, settings, chartType }, chart, groups, index) {
  const group = groups[index];
  const colorsByKey = settings["series_settings.colors"] || {};
  const key = keyForSingleSeries(series[index]);
  const color = colorsByKey[key] || "black";

  // multiple series
  if (groups.length > 1 || chartType === "scatter") {
    // multiple stacks
    if (group.length > 1) {
      // compute shades of the assigned color
      chart.ordinalColors(colorShades(color, group.length));
    } else {
      chart.colors(color);
    }
  } else {
    chart.ordinalColors(
      series.map(single => colorsByKey[keyForSingleSeries(single)]),
    );
  }

  if (chartType === "scatter") {
    if (
      settings["scatter.bubble_formatting"] &&
      settings["scatter.bubble_formatting"].length > 0
    ) {
      applyColorFormatting(
        chart,
        ".bubble",
        settings["scatter.bubble_formatting"],
        series,
      );
    }
  }

  if (chartType === "waterfall") {
    chart.on("pretransition", function (chart) {
      chart
        .selectAll("g.stack._0 rect.bar")
        .style("fill", "transparent")
        .style("pointer-events", "none");
      chart
        .selectAll("g.stack._3 rect.bar")
        .style("fill", settings["waterfall.total_color"]);
      chart
        .selectAll("g.stack._1 rect.bar")
        .style("fill", settings["waterfall.decrease_color"]);
      chart
        .selectAll("g.stack._2 rect.bar")
        .style("fill", settings["waterfall.increase_color"]);
    });
  }
}

// returns the series "display" type, either from the series settings or stack_display setting
function getSeriesDisplay(settings, single) {
  if (settings["stackable.stack_type"] != null) {
    return settings["stackable.stack_display"];
  } else {
    return settings.series(single).display;
  }
}

/// Return a sequence of little charts for each of the groups.
function getCharts(
  props,
  yAxisProps,
  parent,
  datas,
  groups,
  dimension,
  { onBrushChange, onBrushEnd },
) {
  const { settings, chartType, series, onChangeCardAndRun } = props;
  const { yAxisSplit } = yAxisProps;

  const displays = _.uniq(series.map(s => getSeriesDisplay(settings, s)));
  const isMixedBar = displays.includes("bar") && displays.length > 1;
  const isOrdinal = settings["graph.x_axis.scale"] === "ordinal";
  const isMixedOrdinalBar = isMixedBar && isOrdinal;

  if (isMixedOrdinalBar) {
    // HACK: ordinal + mix of line and bar results in uncentered points, shift by
    // half the width
    parent.on("renderlet.shift", () => {
      // ordinal, so we can get the first two points to determine spacing
      const scale = parent.x();
      const values = scale.domain();
      const spacing = scale(values[1]) - scale(values[0]);
      parent
        .svg()
        // shift bar/line and dots
        .selectAll(".stack, .dc-tooltip")
        .each(function () {
          this.setAttribute("transform", `translate(${spacing / 2}, 0)`);
        });
    });
  }

  return groups.map((group, index) => {
    const single = series[index];
    const seriesSettings = settings.series(single);
    const seriesChartType = getSeriesDisplay(settings, single) || chartType;

    const chart = getDcjsChart(seriesChartType, parent);

    if (enableBrush(series, onChangeCardAndRun)) {
      initBrush(parent, chart, onBrushChange, onBrushEnd);
    }

    // disable clicks
    chart.onClick = () => {};

    chart
      .dimension(dimension)
      .group(group[0])
      .transitionDuration(0)
      .useRightYAxis(yAxisSplit.length > 1 && yAxisSplit[1].includes(index));

    if (chartType === "scatter") {
      configureScatterChart(chart, datas, index, yAxisProps, props.settings);
    }

    if (chart.defined) {
      chart.defined(
        seriesSettings["line.missing"] === "none"
          ? d => d.y != null
          : d => true,
      );
    }

    setChartColor(props, chart, groups, index);

    for (let i = 1; i < group.length; i++) {
      chart.stack(group[i]);
    }

    applyChartLineBarSettings(
      chart,
      settings,
      seriesChartType,
      seriesSettings,
      isMixedOrdinalBar,
    );

    return chart;
  });
}

/************************************************************ OTHER SETUP ************************************************************/

/// Add a `goalChart` to the end of `charts`, and return an appropriate `onGoalHover` function as needed.
function addGoalChartAndGetOnGoalHover(
  { settings, onHoverChange },
  xDomain,
  parent,
  charts,
) {
  if (!settings["graph.show_goal"]) {
    return () => {};
  }

  const goalValue = settings["graph.goal_value"];
  const goalData = [
    [xDomain[0], goalValue],
    [xDomain[1], goalValue],
  ];
  const goalDimension = crossfilter(goalData).dimension(d => d[0]);

  // Take the last point rather than summing in case xDomain[0] === xDomain[1], e.x. when the chart
  // has just a single row / datapoint
  const goalGroup = goalDimension.group().reduce(
    (p, d) => d[1],
    (p, d) => p,
    () => 0,
  );
  const goalIndex = charts.length;

  const goalChart = dc
    .lineChart(parent)
    .dimension(goalDimension)
    .group(goalGroup)
    .on("renderlet", function (chart) {
      // remove "sub" class so the goal is not used in voronoi computation
      chart
        .select(".sub._" + goalIndex)
        .classed("sub", false)
        .classed("goal", true);
    });
  charts.push(goalChart);

  return element => {
    onHoverChange(
      element && {
        element,
        data: [{ key: settings["graph.goal_label"], value: goalValue }],
      },
    );
  };
}

function findSeriesIndexForColumnName(series, colName) {
  return (
    _.findIndex(series, ({ data: { cols } }) =>
      _.findWhere(cols, { name: colName }),
    ) || 0
  );
}

const TREND_LINE_POINT_SPACING = 25;

function getTrendDatasFromInsights(insights, { xDomain, settings, parent }) {
  const xCount = Math.round(parent.width() / TREND_LINE_POINT_SPACING);
  const trendDatas = insights.map(insight =>
    getTrendDataPointsFromInsight(insight, xDomain, xCount),
  );
  return !isNormalized(settings)
    ? trendDatas
    : getNormalizedStackedTrendDatas(trendDatas);
}

function addTrendlineChart(
  { series, settings, onHoverChange },
  { xDomain },
  { yAxisSplit },
  parent,
  charts,
) {
  if (!settings["graph.show_trendline"]) {
    return;
  }

  const rawSeries = series._raw || series;
  const insights = (rawSeries[0].data.insights || []).filter(insight => {
    const index = findSeriesIndexForColumnName(series, insight.col);
    const shouldShowSeries = index !== -1;
    const hasTrendLineData = insight.slope != null && insight.offset != null;
    return shouldShowSeries && hasTrendLineData;
  });

  const trendDatas = getTrendDatasFromInsights(insights, {
    xDomain,
    settings,
    parent,
  });

  for (const [insight, trendData] of _.zip(insights, trendDatas)) {
    const index = findSeriesIndexForColumnName(series, insight.col);
    const seriesSettings = settings.series(series[index]);
    const color = lighten(seriesSettings.color, 0.25);

    const trendDimension = crossfilter(trendData).dimension(d => d[0]);

    // Take the last point rather than summing in case xDomain[0] === xDomain[1], e.x. when the chart
    // has just a single row / datapoint
    const trendGroup = trendDimension.group().reduce(
      (p, d) => d[1],
      (p, d) => p,
      () => 0,
    );
    const trendIndex = charts.length;

    const trendChart = dc
      .lineChart(parent)
      .dimension(trendDimension)
      .group(trendGroup)
      .on("renderlet", function (chart) {
        // remove "sub" class so the trend is not used in voronoi computation
        chart
          .select(".sub._" + trendIndex)
          .classed("sub", false)
          .classed("trend", true);
      })
      .colors([color])
      .useRightYAxis(yAxisSplit.length > 1 && yAxisSplit[1].includes(index))
      .interpolate("cardinal");

    charts.push(trendChart);
  }
}

const isEmptyObject = obj => {
  return (
    obj &&
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  );
};
function addShowDifferenceChart(
  { series, settings, onHoverChange },
  { xDomain, xValues },
  { yAxisSplit },
  parent,
  charts,
) {
  const firstName = settings["graph.show_difference_graph_first"];
  const secondName = settings["graph.show_difference_graph_second"];

  if (!settings["graph.show_difference"] || !firstName || !secondName) {
    return;
  }

  let difColorValues = {};

  const getDifSeries = ([firstSeriesName, secondSeriesName], ser) => {
    return ser.reduce(
      (acc, cur) => {
        const item =
          cur.card.name === firstSeriesName ||
          cur.card._seriesKey === firstSeriesName
            ? { firstLine: cur.data }
            : cur.card.name === secondSeriesName ||
              cur.card._seriesKey === secondSeriesName
            ? { secondLine: cur.data }
            : null;
        return { ...acc, ...item };
      },
      { firstLine: {}, secondLine: {} },
    );
  };
  const { firstLine, secondLine } = getDifSeries(
    [firstName, secondName],
    series,
  );

  if (isEmptyObject(firstLine) || isEmptyObject(secondLine)) {
    return;
  }

  const difData = xValues.reduce((acc, cur, idx) => {
    const dif1 = firstLine.rows[idx][1];
    const dif2 = secondLine.rows[idx][1];

    difColorValues = { ...difColorValues, [cur]: dif1 > dif2 ? "1" : "2" };
    return dif1 > dif2
      ? [
          ...acc,
          { name: cur, position: "empty", value: dif2 },
          { name: cur, position: "main", value: dif1 - dif2 },
        ]
      : [
          ...acc,
          { name: cur, position: "main", value: dif2 - dif1 },
          { name: cur, position: "empty", value: dif1 },
        ];
  }, []);

  const difDim = crossfilter(difData).dimension(d => {
    return d.name;
  });

  const difGroup = difDim.group().reduce(
    function (p, v) {
      p[v.position] = (p[v.position] || 0) + v.value;
      return p;
    },
    function (p, v) {
      p[v.position] = p[v.position] - v.value;
      return p;
    },
    function () {
      return {};
    },
  );

  const diffIndex = charts.length;

  const differenceChart = dc
    .barChart(parent)
    .dimension(difDim)
    .centerBar(true)
    .elasticY(true)
    .colorAccessor(function (d) {
      return difColorValues[d.key];
    })
    .colors(d3.scale.ordinal().domain(["1", "2"]).range(["green", "red"])) // write right colors
    .group(difGroup, "empty", kv => {
      return kv.value.empty;
    })
    .stack(difGroup, "main", kv => {
      return kv.value.main;
    })
    .on("renderlet", function (chart) {
      // remove "sub" class so the goal is not used in voronoi computation
      chart
        .select(".sub._" + diffIndex)
        .classed("sub", false)
        .classed("diff", true)
        .style("visibility", "visible")
        .select(".stack._0")
        .style("visibility", "hidden"); // we draw double chart and first part make hidden.
    });
  charts.push(differenceChart);
}

function applyXAxisSettings(parent, series, xAxisProps, timelineEvents) {
  if (isTimeseries(parent.settings)) {
    applyChartTimeseriesXAxis(parent, series, xAxisProps, timelineEvents);
  } else if (isQuantitative(parent.settings)) {
    applyChartQuantitativeXAxis(parent, series, xAxisProps);
  } else {
    applyChartOrdinalXAxis(parent, series, xAxisProps);
  }
}

function applyYAxisSettings(parent, { yLeftSplit, yRightSplit }) {
  if (yLeftSplit && yLeftSplit.series.length > 0) {
    applyChartYAxis(parent, yLeftSplit.series, yLeftSplit.extent, "left");
  }
  if (yRightSplit && yRightSplit.series.length > 0) {
    applyChartYAxis(parent, yRightSplit.series, yRightSplit.extent, "right");
  }
}

// TODO - better name
function doGroupedBarStuff(parent) {
  parent.on("renderlet.grouped-bar", function (chart) {
    // HACK: dc.js doesn't support grouped bar charts so we need to manually resize/reposition them
    // https://github.com/dc-js/dc.js/issues/558
    const barCharts = chart
      .selectAll(".sub rect:first-child")[0]
      .map(node => node.parentNode.parentNode.parentNode);
    if (barCharts.length === 0) {
      return;
    }
    const bars = barCharts[0].querySelectorAll("rect");
    if (bars.length < 1) {
      return;
    }
    const oldBarWidth = parseFloat(bars[0].getAttribute("width"));
    const newBarWidthTotal = oldBarWidth / barCharts.length;
    const seriesPadding =
      newBarWidthTotal < 4 ? 0 : newBarWidthTotal < 8 ? 1 : 2;
    const newBarWidth = Math.max(1, newBarWidthTotal - seriesPadding);

    chart.selectAll("g.sub rect").attr("width", newBarWidth);
    barCharts.forEach((barChart, index) => {
      barChart.setAttribute(
        "transform",
        "translate(" + (newBarWidth + seriesPadding) * index + ", 0)",
      );
    });
  });
}

// TODO - better name
function doHistogramBarStuff(parent) {
  parent.on("renderlet.histogram-bar", function (chart) {
    // manually size bars to fill space, minus 1 pixel padding
    const barCharts = chart
      .selectAll(".sub rect:first-child")[0]
      .map(node => node.parentNode.parentNode.parentNode);
    if (barCharts.length === 0) {
      return;
    }
    const bars = barCharts[0].querySelectorAll("rect");
    if (bars.length < 2) {
      return;
    }
    const barWidth = parseFloat(bars[0].getAttribute("width"));
    const newBarWidth =
      parseFloat(bars[1].getAttribute("x")) -
      parseFloat(bars[0].getAttribute("x")) -
      1;
    if (newBarWidth > barWidth) {
      chart.selectAll("g.sub .bar").attr("width", newBarWidth);
    }

    // shift half of bar width so ticks line up with start of each bar
    for (const barChart of barCharts) {
      barChart.setAttribute("transform", `translate(${barWidth / 2}, 0)`);
    }
  });
}

/************************************************************ ZOOM SETUP ************************************************************/

function applyZoomSettings(chart, clipName, panY, scale) {
  // Need to dodge error when drag chart
  if (!chart.select(`g.voronoi.voronoi-drill`).empty()) {
    chart.select(`g.voronoi.voronoi-drill`).remove();
  }

  // Redraw grid lines
  rescaleXY(chart.select("g.grid-line.horizontal"), [scale, scale], 0, panY);

  // Redraw value labels
  rescaleWithOneTransform(chart.select("g.value-labels"), [1, scale], 0, panY);
  const valueLabels = chart.select("g.value-labels").selectAll("g");
  valueLabels.each(function () {
    rescaleXY(d3.select(this), [1, 1 / scale], 0, 0);
  });

  // Redraw charts
  for (let i = 0; i <= chart.selectAll("g.sub").size(); i++) {
    chart
      .select(`g.sub._${i}`)
      .attr("clip-path", `url(#clip_chart_${clipName})`);
    rescaleXY(
      chart.select(`g.sub._${i}`).select(`g.chart-body`),
      [1, scale],
      0,
      panY,
    );
  }
}

function rescaleXY(elem, scale, panX, panY) {
  const regex = /translate\((-?\d+\.?\d*),\s*(-?\d+\.?\d*)\)/;
  if (elem && !elem.empty()) {
    const elemAttr = elem.attr("transform");
    const elemMatch = elemAttr ? elemAttr.match(regex) : null;
    const transform = elemMatch
      ? `translate(${elemMatch[1]}, ${elemMatch[2]}) translate(${panX}, ${panY})`
      : "";
    elem.attr("transform", `${transform} scale(${scale[0]}, ${scale[1]})`);
  }
}

function rescaleWithOneTransform(elem, scale, panX, panY) {
  if (elem && !elem.empty()) {
    elem.attr(
      "transform",
      `translate(${panX}, ${panY}) scale(${scale[0]}, ${scale[1]})`,
    );
  }
}

function zoomSetup(chart, width, height, settings, debounceUpdateZoomSettings) {
  chart.yAxis().scale().clamp(false);
  chart.rightYAxis().scale().clamp(false);

  const clipName = chart.__dcFlag__;
  // Graph frame to clip outside lines
  chart
    .select("defs")
    .append("clipPath")
    .attr("id", `clip_chart_${clipName}`)
    .append("rect")
    .attr("x", 0)
    .attr("y", 0)
    .attr("width", width)
    .attr("height", height - chart.margins().bottom - 3);

  const clipWrapper = chart
    .select("g")
    .append("g")
    .attr("clip-path", `url(#clip_chart_${clipName})`);

  const valueLabels = chart
    .select("g")
    .select("g.value-labels")
    .node()?.parentNode;
  if (valueLabels) {
    clipWrapper.append(() => valueLabels);
  }

  const gridLines = chart.select("g").select("g.grid-line.horizontal").node();
  if (gridLines) {
    clipWrapper.append(() =>
      chart.select("g").select("g.grid-line.horizontal").node(),
    );
  }

  const rightAxisZoom = d3.behavior
    .zoom()
    .y(chart.rightYAxis().scale())
    .scaleExtent([1, 2]);

  const zoom = d3.behavior
    .zoom()
    .y(chart.yAxis().scale())
    .scaleExtent([1, 2])
    .scale(settings["graph.zoom_scale"])
    .translate([0, settings["graph.zoom_pan_y"]])
    .on("zoom", function () {
      // Pan Vector
      const panVector = d3.event.translate;

      // Scaling Multiplier
      const scaleMultiplier = d3.event.scale;

      if (chart && chart.select("rect.background").node()) {
        const graphHeight = Number(
          chart.select("rect.background").node().getAttribute("height"),
        );

        if (graphHeight * scaleMultiplier - graphHeight < panVector[1] * -1) {
          panVector[1] = -1 * (graphHeight * scaleMultiplier - graphHeight);
        }
      }

      const ty = Math.min(
        0,
        Math.max(panVector[1], height - height * scaleMultiplier),
      );

      zoom.translate([0, ty]);

      rightAxisZoom.scale(zoom.scale());
      rightAxisZoom.translate(zoom.translate());

      // Redraw axis
      chart.select("g.axis.y").call(chart.yAxis());
      chart.select("g.axis.yr").call(chart.rightYAxis());

      applyZoomSettings(chart, clipName, ty, scaleMultiplier);

      if (d3.event.sourceEvent !== null) {
        debounceUpdateZoomSettings(ty, scaleMultiplier);
      }
    });

  // Set up d3-zoom and callbacks.
  chart.select("svg").call(zoom, rightAxisZoom);

  // Trigger first render zoom settings apply
  zoom.event(chart.select("svg"));
}

const getMaxData = (part, index) => {
  return Math.max(...part.data.rows.map(row => row[index]));
};

const getFormatLineData = (part, index) => {
  const items = part.data.rows
    .map(row => row[index])
    .filter(item => item)
    .map(item => Number(item));
  const avg = items.reduce((acc, cur) => acc + cur, 0) / items.length;
  const sum = items.reduce((acc, cur) => acc + cur, 0);
  const min = Math.min(...items);
  const max = Math.max(...items);
  return { min, max, avg, sum };
};

const getLinesThickness = (defaultThickness, max, part, index) => {
  return part.data.rows.map(row =>
    Math.ceil(defaultThickness / (max / row[index])),
  );
};

function applyThickness(parent, series, settings, chartType) {
  parent.on("renderlet.histogram-bar", function (chart) {
    applyLineStyleThickness(chart, series, settings);
    if (settings["graph.bar.thickness"]) {
      applyBarThickness(chart, series, settings);
    }
    if (settings["graph.dot.thickness"]) {
      applyLineDotsThickness(chart, series, settings);
    }
    if (settings["graph.line.thickness"]) {
      applyLineThickness(chart, series, settings);
    }
  });
}

function applyBarThickness(chart, series, settings) {
  const barCharts = chart
    .selectAll(".sub rect:first-child")[0]
    .map(node => node.parentNode.parentNode.parentNode);

  if (barCharts.length === 0) {
    return;
  }

  for (let [seriesIndex, bars] of barCharts.entries()) {
    if (bars.querySelectorAll(".stack").length > 1) {
      return;
    }
    bars = bars.querySelectorAll("rect");

    if (bars.length < 2) {
      return;
    }

    const thicknessIndex =
      series[seriesIndex].data.cols.findLastIndex(
        item => item.name === settings["graph.bar.thickness"],
      ) || 0;

    const defaultThickness = parseFloat(bars[0].getAttribute("width"));
    const maxInChart = getMaxData(series[seriesIndex], thicknessIndex);
    const barWidths = getLinesThickness(
      defaultThickness,
      maxInChart,
      series[seriesIndex],
      thicknessIndex,
    );

    for (const [i, line] of bars.entries()) {
      line.setAttribute("width", barWidths[i]);
      line.setAttribute(
        "transform",
        `translate(${(defaultThickness - barWidths[i]) / 2}, 0)`,
      );
    }
  }
}

function applyLineDotsThickness(chart, series, settings) {
  const lineCharts = chart.selectAll(".sub")[0];

  if (lineCharts.length === 0) {
    return;
  }

  for (let [seriesIndex, dots] of lineCharts.entries()) {
    dots = dots.querySelectorAll(".dot");
    const MaxSize = 20;
    const thicknessIndex =
      series[seriesIndex].data.cols.findLastIndex(
        item => item.name === settings["graph.dot.thickness"],
      ) || 0;
    const maxInChart = getMaxData(series[seriesIndex], thicknessIndex);
    const dotSize = getLinesThickness(
      MaxSize,
      maxInChart,
      series[seriesIndex],
      thicknessIndex,
    );

    for (const [i, line] of dots.entries()) {
      line.setAttribute("style", `r: ${dotSize[i]} !important`);
      line.classList.add("dot-always-show");
    }
  }
}

function applyLineStyleThickness(chart, series, settings) {
  const lineCharts = chart.selectAll(".sub")[0];

  if (lineCharts.length === 0) {
    return;
  }

  const styleByKey = settings["series_settings"] || {};
  const ordinalThickness = series.map(
    single => styleByKey[keyForSingleSeries(single)],
  );

  lineCharts.forEach((lines, seriesIndex) => {
    lines = lines.querySelectorAll("path.line");
    lines.forEach(line => {
      const thickness = ordinalThickness[seriesIndex]?.["line.thickness"];
      if (thickness) {
        line.style["stroke-width"] = `${thickness}px`;
      }
    });
  });
}

function applyLineThickness(chart, series, settings) {
  const lineCharts = chart.selectAll(".sub")[0];
  const seriesThickness = new Array(series.length);

  if (lineCharts.length === 0) {
    return;
  }

  const defaultThickness = 10;

  lineCharts.forEach((lines, seriesIndex) => {
    const thicknessIndex =
      series[seriesIndex].data.cols.findLastIndex(
        item => item.name === settings["graph.line.thickness"],
      ) || 0;
    const { min, max, avg, sum } = getFormatLineData(
      series[seriesIndex],
      thicknessIndex,
    );
    seriesThickness[seriesIndex] = { min, max, avg, sum };
  });

  const groupsMax = Math.max(...seriesThickness.map(item => item.max));
  const groupsAvg = Math.max(...seriesThickness.map(item => item.avg));
  const groupsMin = Math.max(...seriesThickness.map(item => item.min));
  const groupsSum = Math.max(...seriesThickness.map(item => item.sum));

  const lineThickness = new Array(seriesThickness.length);
  lineCharts.forEach((lines, seriesIndex) => {
    switch (settings["graph.line.thickness_format"]) {
      case "max":
        lineThickness[seriesIndex] = Math.ceil(
          defaultThickness / (groupsMax / seriesThickness[seriesIndex].max),
        );
        break;
      case "avg":
        lineThickness[seriesIndex] = Math.ceil(
          defaultThickness / (groupsAvg / seriesThickness[seriesIndex].avg),
        );
        break;
      case "min":
        lineThickness[seriesIndex] = Math.ceil(
          defaultThickness / (groupsMin / seriesThickness[seriesIndex].min),
        );
        break;
      case "sum":
        lineThickness[seriesIndex] = Math.ceil(
          defaultThickness / (groupsSum / seriesThickness[seriesIndex].sum),
        );
        break;
      default:
        break;
    }
  });

  for (let i = 0; i <= d3.selectAll("g.sub").size(); i++) {
    d3.selectAll(`g.sub._${i} path.line`).style(
      "stroke-width",
      `${lineThickness[i]}px`,
    );
  }
}
/************************************************************ PUTTING IT ALL TOGETHER ************************************************************/

export default function lineAreaBar(element, props) {
  const {
    isScalarSeries,
    settings,
    series,
    timelineEvents,
    selectedTimelineEventIds,
    onRender,
    onHoverChange,
    onOpenTimelines,
    onSelectTimelineEvents,
    onDeselectTimelineEvents,
    showed,
  } = props;

  const warnings = {};
  // `text` is displayed to users, but we deduplicate based on `key`
  // Call `warn` for each row-level issue, but only the first of each type is displayed.
  const warn = ({ key, text }) => {
    warnings[key] = warnings[key] || text;
  };

  checkSeriesIsValid(props);

  // force histogram to be ordinal axis with zero-filled missing points
  settings["graph.x_axis._scale_original"] = settings["graph.x_axis.scale"];
  if (isHistogram(settings)) {
    // FIXME: need to handle this on series settings now
    settings["line.missing"] = "zero";
    settings["graph.x_axis.scale"] = "ordinal";
  }

  let datas = getDatas(props, warn);
  let xAxisProps = getXAxisProps(props, datas, warn);

  datas = fillMissingValuesInDatas(props, xAxisProps, datas);
  xAxisProps = getXAxisProps(props, datas, warn);

  if (isScalarSeries) {
    xAxisProps.xValues = datas.map(data => data[0][0]);
  } // TODO - what is this for?

  const { dimension, groups, yExtents } =
    getDimensionsAndGroupsAndUpdateSeriesDisplayNames(props, datas, warn);

  const yAxisProps = getYAxisProps(props, yExtents, datas);

  // Don't apply to linear or timeseries X-axis since the points are always plotted in order
  if (!isTimeseries(settings) && !isQuantitative(settings)) {
    forceSortedGroupsOfGroups(groups, makeIndexMap(xAxisProps.xValues));
  }

  const parent = dc.compositeChart(element);
  initChart(parent, element);

  // add these convienence aliases so we don't have to pass a bunch of things around
  parent.props = props;
  parent.settings = settings;
  parent.series = props.series;

  const brushChangeFunctions = makeBrushChangeFunctions(props);

  const charts = getCharts(
    props,
    yAxisProps,
    parent,
    datas,
    groups,
    dimension,
    brushChangeFunctions,
  );
  const onGoalHover = addGoalChartAndGetOnGoalHover(
    props,
    xAxisProps.xDomain,
    parent,
    charts,
  );
  addTrendlineChart(props, xAxisProps, yAxisProps, parent, charts);

  addShowDifferenceChart(props, xAxisProps, yAxisProps, parent, charts);

  parent.compose(charts);

  if (groups.length > 1 && !props.isScalarSeries) {
    doGroupedBarStuff(parent);
  } else if (isHistogramBar(props)) {
    doHistogramBarStuff(parent);
  }

  applyThickness(parent, series, settings, props);

  // HACK: compositeChart + ordinal X axis shenanigans. See https://github.com/dc-js/dc.js/issues/678 and https://github.com/dc-js/dc.js/issues/662
  if (!isHistogram(props.settings)) {
    const hasBar =
      _.any(series, single => getSeriesDisplay(settings, single) === "bar") ||
      props.chartType === "waterfall";
    parent._rangeBandPadding(hasBar ? BAR_PADDING_RATIO : 1);
  }

  applyXAxisSettings(parent, props.series, xAxisProps, timelineEvents);

  applyYAxisSettings(parent, yAxisProps);

  setupTooltips(props, datas, parent, brushChangeFunctions);

  parent.render();

  datas.map(data => {
    data.map(d => {
      if (isFinite(d._waterfallValue)) {
        d[1] = d._waterfallValue;
      }
    });
  });

  // apply any on-rendering functions (this code lives in `LineAreaBarPostRenderer`)
  lineAndBarOnRender(parent, {
    datas,
    timelineEvents,
    selectedTimelineEventIds,
    isSplitAxis: yAxisProps.isSplit,
    yAxisSplit: yAxisProps.yAxisSplit,
    xDomain: xAxisProps.xDomain,
    xInterval: xAxisProps.xInterval,
    isStacked: isStacked(parent.settings, datas),
    isTimeseries: isTimeseries(parent.settings),
    hasDrills: typeof props.onChangeCardAndRun === "function",
    formatYValue: getYValueFormatter(parent, series, yAxisProps.yExtent),
    onGoalHover,
    onHoverChange,
    onOpenTimelines,
    onSelectTimelineEvents,
    onDeselectTimelineEvents,
    showed,
  });

  // only ordinal axis can display "null" values
  if (isOrdinal(parent.settings)) {
    delete warnings[NULL_DIMENSION_WARNING];
  }

  if (onRender) {
    onRender({
      yAxisSplit: yAxisProps.yAxisSplit,
      warnings: Object.values(warnings),
    });
  }

  if (
    props.chartType === "line" ||
    props.chartType === "combo" ||
    props.chartType === "bar" ||
    props.chartType === "area" ||
    props.chartType === "waterfall" ||
    props.chartType === "scatter"
  ) {
    const debounceUpdateZoomSettings = _.debounce((panY, scale) => {
      props.onUpdateVisualizationSettings({
        "graph.zoom_pan_y": panY,
        "graph.zoom_scale": scale,
      });
    }, 500);

    zoomSetup(
      parent,
      props.width,
      props.height,
      props.settings,
      debounceUpdateZoomSettings,
    );
  }

  // return an unregister function
  return () => {
    dc.chartRegistry.deregister(parent);
  };
}

export const lineRenderer = (element, props) =>
  lineAreaBar(element, { ...props, chartType: "line" });
export const areaRenderer = (element, props) =>
  lineAreaBar(element, { ...props, chartType: "area" });
export const barRenderer = (element, props) =>
  lineAreaBar(element, { ...props, chartType: "bar" });
export const waterfallRenderer = (element, props) =>
  lineAreaBar(element, { ...props, chartType: "waterfall" });
export const comboRenderer = (element, props) =>
  lineAreaBar(element, { ...props, chartType: "combo" });
export const scatterRenderer = (element, props) =>
  lineAreaBar(element, { ...props, chartType: "scatter" });
