import type React from "react";
import { useMemo, useState } from "react";
import { t } from "ttag";

import { Timeline } from "metabase/common/components/Timeline";
import { getTimelineEvents } from "metabase/common/components/Timeline/utils";
import { useRevisionListQuery } from "metabase/common/hooks/use-revision-list-query";
import Radio from "metabase/core/components/Radio";
import { revertToRevision } from "metabase/dashboard/actions";
import { useDispatch, useSelector } from "metabase/lib/redux";
import { getUser } from "metabase/selectors/user";
import type { Dashboard } from "metabase-types/api";


import { DashboardCardSettings } from "./DashboardCardSettings";
import { DashboardCommonSettings } from "./DashboardCommonSettings";
import { HistoryHeader, ContentSection } from "./DashboardInfoSidebar.styled";
import { DashboardVisualizationSettings } from "./DashboardVisualizationSettings";
import { DashboardVisualizationSettingsProvider } from "./DashboardVisualizationSettingsContext";

type DashboardAttributeType = string | number | null | boolean;

interface SetDashboardAttribute {
  (name: string, value: DashboardAttributeType): void;
}
export interface DashboardInfoSidebarProps {
  dashboard: Dashboard;
  setDashboardAttribute: SetDashboardAttribute;
}

export interface DashboardInfoSidebarTabProps {
  dashboard: Dashboard;
  setDashboardAttribute?: SetDashboardAttribute;
}

type Tab = Record<
  string,
  {
    title: string;
    component: React.FC<DashboardInfoSidebarTabProps>;
  }
>;

const TABS: Tab = {
  common: {
    title: t`Common settings`,
    component: DashboardCommonSettings,
  },
  card: {
    title: t`Card settings`,
    component: DashboardCardSettings,
  },
  visualization: {
    title: t`Widgets settings`,
    component: DashboardVisualizationSettings,
  },
};

export function DashboardInfoSidebar({
  dashboard,
  setDashboardAttribute,
}: DashboardInfoSidebarProps) {
  const { data: revisions } = useRevisionListQuery({
    query: { model_type: "dashboard", model_id: dashboard.id },
  });

  const currentUser = useSelector(getUser);
  const dispatch = useDispatch();

  const canWrite = dashboard.can_write;

  const sections = Object.keys(TABS);
  const [currentSection, setCurrentSection] = useState(sections[0]);
  const renderedTab = useMemo(() => {
    const Component = TABS[currentSection].component;
    return (
      <Component
        dashboard={dashboard}
        setDashboardAttribute={setDashboardAttribute}
      />
    );
  }, [currentSection, dashboard, setDashboardAttribute]);

  return (
    <DashboardVisualizationSettingsProvider dashboard={dashboard}>
      <ContentSection style={{ padding: 0 }}>
        <Radio
          value={currentSection}
          onChange={section => {
            setCurrentSection(section);
          }}
          options={sections}
          optionNameFn={v => TABS[v].title}
          optionValueFn={v => v}
          optionKeyFn={v => v}
          variant="underlined"
        />
      </ContentSection>
      {renderedTab}
      <ContentSection>
        <HistoryHeader>{t`History`}</HistoryHeader>
        <Timeline
          events={getTimelineEvents({ revisions, currentUser })}
          data-testid="dashboard-history-list"
          revert={revision => dispatch(revertToRevision(revision))}
          canWrite={canWrite}
        />
      </ContentSection>
    </DashboardVisualizationSettingsProvider>
  );
}
