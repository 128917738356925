import d3 from "d3";
/* eslint-disable @typescript-eslint/ban-ts-comment */
import _ from "underscore";
import { stack, stackOffsetDiverging, stackOffsetExpand } from "d3-shape";
import type { Series as D3Series } from "d3-shape";

import { formatNullable } from "metabase/lib/formatting/nullable";
import type { SeriesInfo } from "metabase/visualizations/shared/types/data";
import type { ContinuousScaleType } from "metabase/visualizations/shared/types/scale";
import type { VisualizationSettings } from "metabase-types/api";

import type { BarData, Series, SeriesData, StackOffset } from "../types";

export const StackOffsetFn = {
  diverging: stackOffsetDiverging,
  expand: stackOffsetExpand,
} as const;

const getCellBackgroundColor = (
  settings: VisualizationSettings,
  value: number | string,
  rowIndex: number,
  columnName: string,
) => {
  try {
    return settings["table._cell_color_getter"](value, rowIndex, columnName);
  } catch (e) {
    console.error(e);
  }
};

export const calculateNonStackedBars = <TDatum>(
  data: TDatum[],
  multipleSeries: Series<TDatum, SeriesInfo>[],
  seriesColors: Record<string, string>,
  xScaleType: ContinuousScaleType,
  settings: VisualizationSettings,
  formattingFilters: { name: string; idx: number }[],
): SeriesData<TDatum>[] => {
  const defaultXValue = xScaleType === "log" ? 1 : 0;
  const columFormatting = settings["table.column_formatting"];
  const showHeightDimension =
    settings["graph.show_heights_dimensions"] &&
    settings["stackable.stack_type"] !== null &&
    settings["graph.heights_dimensions"];
  const labelTextSettingName = settings["row.chart.label_dimension"];
  const labelTextSetting = formattingFilters.filter(
    formattingFilter => formattingFilter.name === labelTextSettingName,
  );
  let columnFlattened: string[] = [];
  if (showHeightDimension) {
    // @ts-ignore
    columnFlattened = columFormatting.flatMap((col: any) => col.columns);
  }

  return multipleSeries.map((series, seriesIndex) => {
    const bars: BarData<TDatum, SeriesInfo>[] = data.map(
      (datum, datumIndex) => {
        const yValue = formatNullable(series.yAccessor(datum));
        const xValue = series.xAccessor(datum);
        const isNegative = xValue != null && xValue < 0;

        const xStartValue = isNegative ? xValue : defaultXValue;
        const xEndValue = isNegative ? defaultXValue : xValue;

        let formattingColor: string[] = [];
        // @ts-ignore
        if (showHeightDimension && datum?.rawRows?.length > seriesIndex) {
          formattingColor = columnFlattened
            .map(column => {
              return (
                formattingFilters
                  .filter(filter => filter.name === column)
                  // @ts-ignore
                  .map(filter =>
                    getCellBackgroundColor(
                      settings,
                      // @ts-ignore
                      datum.rawRows[seriesIndex][filter.idx],
                      datumIndex,
                      column,
                    ),
                  )
              );
            })
            .flatMap(color => color);
        }
        let labelText: string = "";
        // @ts-ignore
        if (labelTextSettingName && datum?.rawRows?.length > seriesIndex) {
          const labelId = labelTextSetting[0].idx;
          // @ts-ignore
          labelText = datum.rawRows[seriesIndex][labelId];
        }

        return {
          isNegative,
          xStartValue,
          xEndValue,
          yValue,
          datum,
          datumIndex,
          series,
          seriesIndex,
          height: 0,
          labelText,
          color: formattingColor.length > 0 ? formattingColor[0] : "",
        };
      },
    );

    return {
      bars,
      color: seriesColors[series.seriesKey],
      key: series.seriesKey,
    };
  });
};

// For log scale starting value for stack is 1
// Stacked log charts does not make much sense but we support them, so I replicate the behavior of line/area/bar charts
const patchD3StackDataForLogScale = <TDatum>(
  stackedSeries: D3Series<TDatum, string>[],
) => {
  stackedSeries.forEach(series => {
    series.forEach(datum => {
      datum.forEach((value, index) => {
        if (value === 0) {
          datum[index] = 1;
        }
      });
    });
  });
};

export const calculateStackedBars = <TDatum>(
  data: TDatum[],
  multipleSeries: Series<TDatum, SeriesInfo>[],
  stackOffset: StackOffset,
  seriesColors: Record<string, string>,
  xScaleType: ContinuousScaleType,
  heightColumn: any,
  settings: VisualizationSettings,
  formattingFilters: { name: string; idx: number }[],
) => {
  const seriesByKey = multipleSeries.reduce<Record<string, Series<TDatum>>>(
    (acc, series) => {
      acc[series.seriesKey] = series;
      return acc;
    },
    {},
  );

  const columFormatting = settings["table.column_formatting"];
  const showHeightDimension =
    settings["graph.show_heights_dimensions"] &&
    settings["stackable.stack_type"] !== null &&
    settings["graph.heights_dimensions"];
  const labelTextSettingName = settings["row.chart.label_dimension"];
  const labelTextSetting = formattingFilters.filter(
    formattingFilter => formattingFilter.name === labelTextSettingName,
  );
  let columnFlattened: string[] = [];
  if (showHeightDimension) {
    // @ts-ignore
    columnFlattened = columFormatting.flatMap((col: any) => col.columns);
  }

  const d3Stack = stack<TDatum>()
    .keys(multipleSeries.map(s => s.seriesKey))
    .value((datum, seriesKey) => seriesByKey[seriesKey].xAccessor(datum) ?? 0)
    .offset(StackOffsetFn[stackOffset ?? "diverging"]);

  const stackedSeries = d3Stack(data);

  if (xScaleType === "log") {
    patchD3StackDataForLogScale(stackedSeries);
  }

  const getDatumExtent = _.memoize(
    (stackedSeries: D3Series<TDatum, string>[], datumIndex: number) => {
      return d3.extent(stackedSeries.flatMap(series => series[datumIndex]));
    },
    (_series, datumIndex) => datumIndex,
  );

  const seriesData: SeriesData<TDatum, SeriesInfo>[] = multipleSeries.map(
    (series, seriesIndex) => {
      const bars: BarData<TDatum>[] = data.map((datum, datumIndex) => {
        let height = 0;
        // @ts-ignore
        if (datum?.rawRows?.length > seriesIndex) {
          // @ts-ignore // aslo, look here, if u will corect row chart with Y-asix multiply value
          height = datum?.rawRows[seriesIndex][heightColumn?.index];
        }
        const [datumMin, datumMax] = getDatumExtent(stackedSeries, datumIndex);
        const stackedDatum = stackedSeries[seriesIndex][datumIndex];

        const [xStartValue, xEndValue] = stackedDatum;

        const yValue = formatNullable(series.yAccessor(stackedDatum.data));
        const isNegative = xStartValue < 0;
        const isBorderValue =
          (isNegative && xStartValue === datumMin) ||
          (!isNegative && xEndValue === datumMax);

        let formattingColor: string[] = [];
        let labelText: string = "";
        // @ts-ignore
        if (showHeightDimension && datum?.rawRows?.length > seriesIndex) {
          formattingColor = columnFlattened
            .map(column => {
              return (
                formattingFilters
                  .filter(filter => filter.name === column)
                  // @ts-ignore
                  .map(filter =>
                    getCellBackgroundColor(
                      settings,
                      // @ts-ignore
                      datum.rawRows[seriesIndex][filter.idx],
                      datumIndex,
                      column,
                    ),
                  )
              );
            })
            .flatMap(color => color);
        }
        // @ts-ignore
        if (labelTextSettingName && datum?.rawRows?.length > seriesIndex) {
          const labelId = labelTextSetting[0].idx;
          // @ts-ignore
          labelText = datum.rawRows[seriesIndex][labelId];
        }

        return {
          xStartValue,
          xEndValue,
          yValue,
          isNegative,
          isBorderValue,
          height,
          datum,
          datumIndex,
          series,
          seriesIndex,
          labelText,
          color: formattingColor.length > 0 ? formattingColor[0] : "",
        };
      });

      return {
        bars,
        key: series.seriesKey,
        color: seriesColors[series.seriesKey],
      };
    },
  );

  return seriesData;
};
