import { useCallback, useState } from "react";
import { t } from "ttag";

import Button from "metabase/core/components/Button";
import EditableText from "metabase/core/components/EditableText";
import FormField from "metabase/core/components/FormField";
import NumericInput from "metabase/core/components/NumericInput";
import Toggle from "metabase/core/components/Toggle";
import {
  updateDashboard,
  toggleAutoApplyFilters,
  changeGridWidth,
} from "metabase/dashboard/actions";
import { useUniqueId } from "metabase/hooks/use-unique-id";
import { useDispatch, useSelector } from "metabase/lib/redux";
import MetabaseSettings from "metabase/lib/settings";
import { PLUGIN_CACHING } from "metabase/plugins";
import { getSettings } from "metabase/selectors/settings";

import type { DashboardInfoSidebarTabProps } from "./DashboardInfoSidebar";
import {
  ContentSection,
  DescriptionHeader,
} from "./DashboardInfoSidebar.styled";
import { WidgetSettings } from "./WidgetSettings";

export const DashboardCommonSettings = ({
  dashboard,
  setDashboardAttribute = () => {
    return;
  },
}: DashboardInfoSidebarTabProps) => {
  const settings = useSelector(getSettings);
  const defaultGridWidth: number = settings["grid-width"];

  const [gridWidthError, setGridWidthError] = useState(false);
  const [gridWidth, setGridWidth] = useState(dashboard.grid_width);

  const dispatch = useDispatch();

  const showCaching =
    PLUGIN_CACHING.isEnabled() && MetabaseSettings.get("enable-query-caching");

  const handleDescriptionChange = useCallback(
    (description: string) => {
      setDashboardAttribute("description", description);
      dispatch(updateDashboard({ attributeNames: ["description"] }));
    },
    [dispatch, setDashboardAttribute],
  );

  const handleUpdateCacheTTL = useCallback(
    (cache_ttl: number | null) => {
      setDashboardAttribute("cache_ttl", cache_ttl);
      dispatch(updateDashboard({ attributeNames: ["cache_ttl"] }));
    },
    [dispatch, setDashboardAttribute],
  );

  const handleToggleAutoApplyFilters = useCallback(
    (isAutoApplyingFilters: boolean) => {
      dispatch(toggleAutoApplyFilters(isAutoApplyingFilters));
    },
    [dispatch],
  );

  const handleApplyGridWidth = useCallback(() => {
    if (gridWidth < 1 || gridWidth > 200) {
      setGridWidthError(true);
    } else {
      setGridWidthError(false);
      dispatch(changeGridWidth(gridWidth));
    }
  }, [dispatch, gridWidth]);

  const autoApplyFilterToggleId = useUniqueId();
  const gridWidthId = useUniqueId();
  const canWrite = dashboard.can_write;

  return (
    <>
      <ContentSection>
        <DescriptionHeader>{t`About`}</DescriptionHeader>
        <EditableText
          initialValue={dashboard.description}
          isDisabled={!canWrite}
          onChange={handleDescriptionChange}
          isOptional
          isMultiline
          isMarkdown
          placeholder={t`Add description`}
          key={`dashboard-description-${dashboard.description}`}
        />
      </ContentSection>
      <ContentSection>
        <FormField
          title={t`Auto-apply filters`}
          orientation="horizontal"
          htmlFor={autoApplyFilterToggleId}
        >
          <Toggle
            disabled={!canWrite}
            id={autoApplyFilterToggleId}
            value={dashboard.auto_apply_filters}
            onChange={handleToggleAutoApplyFilters}
          />
        </FormField>

        {dashboard.grid_width === null && (
          <h3 className="mb3">
            {t`Default grid width`}
            {` (${defaultGridWidth})`}
          </h3>
        )}
        <FormField
          title={t`Grid width`}
          orientation="horizontal"
          htmlFor={gridWidthId}
        >
          <NumericInput
            value={gridWidth}
            error={gridWidthError}
            onChange={value => {
              if (value) {
                setGridWidth(value);
              }
            }}
            id={gridWidthId}
          />
        </FormField>
        {gridWidth !== dashboard.grid_width && (
          <Button
            name="apply"
            onClick={() => {
              handleApplyGridWidth();
            }}
            primary={true}
            small={true}
          >
            {t`Save`}
          </Button>
        )}
      </ContentSection>
      {showCaching && (
        <ContentSection>
          <PLUGIN_CACHING.DashboardCacheSection
            dashboard={dashboard}
            onSave={handleUpdateCacheTTL}
          />
        </ContentSection>
      )}
      <ContentSection>
        <WidgetSettings selectedWidget="dashboard" />
      </ContentSection>
    </>
  );
};
