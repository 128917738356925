import { push } from "react-router-redux";
import {
  handleActions,
  createThunkAction,
  combineReducers,
} from "metabase/lib/redux";

import { SessionApi } from "metabase/services";

export const REFRESH_LICENSE = "metabase/license/REFRESH_LICENSE";

export const refreshLicense = createThunkAction(
  REFRESH_LICENSE, () =>
    async (dispatch, getState) => {
      const license = await SessionApi.get_license();
      const now = new Date();
      let expiration_date = new Date();
      if(license.expiration_date !== undefined) {
        const year = license.expiration_date.toString().substring(0,4);
        const month = license.expiration_date.toString().substring(5,7);
        const day = license.expiration_date.toString().substring(8,10);
        expiration_date = new Date(year, month-1, day);
      }
      
      if(now >= expiration_date || license.status!=='Active' ) { 
          await dispatch(push("/expired"));
        }
      return license;
    },
);

const license = handleActions(
  {
    [REFRESH_LICENSE]:{ next: (state, { payload }) => payload  },
    },
  null,
);

export default combineReducers({
  license,
});
