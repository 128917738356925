import { getIn } from "icepick";

import type { IconName } from "metabase/ui";
import { getColumnKey } from "metabase-lib/queries/utils/get-column-key";
import type {
  ClickBehaviorType,
  QuestionDashboardCard,
  DatasetColumn,
} from "metabase-types/api";

type ClickBehaviorOption = {
  value: ClickBehaviorType;
  icon: IconName;
};



export const clickBehaviorOptions: ClickBehaviorOption[] = [
  { value: "actionMenu", icon: "popover" },
  { value: "link", icon: "link" },
  { value: "crossfilter", icon: "filter" },
];

// export function getClickBehaviorOptionName(
//   value: ClickBehaviorType | "menu",
//   dashcard: DashboardOrderedCard,
// ) {
//   if (value === "menu") {
//     return hasActionsMenu(dashcard)
//       ? t`Open the Glarus BI drill-through menu`
//       : t`Do nothing`;
//   }
//   if (value === "link") {
//     return t`Go to a custom destination`;
//   }
//   if (value === "crossfilter") {
//     return t`Update a dashboard filter`;
//   }
//   if (value === "action") {
//     return t`Perform action`;
//   }
//   return t`Unknown`;
// }

export function getClickBehaviorForColumn(
  dashcard: QuestionDashboardCard,
  column: DatasetColumn,
) {
  return getIn(dashcard, [
    "visualization_settings",
    "column_settings",
    getColumnKey(column),
    "click_behavior",
  ]);
}
