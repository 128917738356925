var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.expression.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var X0,Y0,Opa,Ppa;$CLJS.U0=function(a,b){return $CLJS.D(a)<=$CLJS.D(b)&&$CLJS.Le(function(c){return $CLJS.Cd(b,c)},a)};$CLJS.V0=function(a,b){return $CLJS.Jz($CLJS.Oe(a),b)};$CLJS.W0=function(a){var b;a:for(b=a;;)if($CLJS.sd(b))b=$CLJS.Ei.h(b);else{b=$CLJS.Ad(b)?b:null;break a}if($CLJS.n(b))return b;throw Error($CLJS.fD("Not something with an ID: {0}",$CLJS.H([$CLJS.xh.l($CLJS.H([a]))])));};
X0=function(a){if(null!=a&&null!=a.sg)a=a.sg(a);else{var b=X0[$CLJS.va(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=X0._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.$a("MetadataProvider.database",a);}return a};Y0=function(a,b){if(null!=a&&null!=a.tg)a=a.tg(a,b);else{var c=Y0[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=Y0._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("MetadataProvider.field",a);}return a};$CLJS.Z0=function(a){return X0($CLJS.c_(a))};
$CLJS.$0=function(a,b){return Y0($CLJS.c_(a),b)};$CLJS.a1=function(a){var b=$CLJS.EV(a,0),c=$CLJS.O(b),d=$CLJS.J.g(c,$CLJS.eO),e=$CLJS.J.g(c,$CLJS.NU);return $CLJS.zd(function(){var f=$CLJS.Z0(a);$CLJS.n(f)?(f=$CLJS.O(f),f=$CLJS.J.g(f,$CLJS.Ei),f=$CLJS.E.g($CLJS.UO.h(a),f)):f=null;if($CLJS.n(f)){f=$CLJS.n(d)?$CLJS.d_(a,d):d;if($CLJS.n(f))return f;f=$CLJS.n(e)?$CLJS.f_(a,e):e;return $CLJS.n(f)?f:$CLJS.E.g($CLJS.Vs.h(c),$CLJS.aV)&&$CLJS.E.g($CLJS.aZ,$CLJS.bZ.h($CLJS.Z0(a)))}return f}())};
Opa=new $CLJS.M(null,"is-native","is-native",-1797717934);Ppa=new $CLJS.M(null,"is-editable","is-editable",269018020);$CLJS.R0.m(null,$CLJS.gV,function(a){return $CLJS.Q0(a,new $CLJS.h(null,2,[$CLJS.Oi,$CLJS.mh,$CLJS.oK,$CLJS.Qe($CLJS.bk,$CLJS.R0)],null))});$CLJS.T_.m(null,$CLJS.gV,function(){throw $CLJS.Uh("You can't calculate a metadata map for a query! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});$CLJS.V_.m(null,$CLJS.gV,function(a,b,c,d){return $CLJS.W_.v(a,b,$CLJS.EV(c,b),d)});$CLJS.M_.m(null,$CLJS.gV,function(a,b,c,d){return $CLJS.l_.v(a,b,$CLJS.EV(c,b),d)});
$CLJS.U_.m(null,$CLJS.gV,function(a,b,c){a=$CLJS.EV(c,0);a=$CLJS.E.g($CLJS.Vs.h(a),$CLJS.aV);return new $CLJS.h(null,2,[Opa,a,Ppa,$CLJS.a1(c)],null)});$CLJS.b1=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.Mi,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.query","can-run-method"),function(f){return $CLJS.Vs.h($CLJS.EV(f,0))},e,a,b,c,d)}();$CLJS.b1.m(null,$CLJS.Oh,function(){return!0});
$CLJS.c1=function(){function a(d,e,f){return new $CLJS.h(null,4,[$CLJS.Vs,$CLJS.gV,$CLJS.gD,$CLJS.c_(e),$CLJS.UO,d,$CLJS.oK,f],null)}function b(d,e){var f=$CLJS.Ei.h($CLJS.Z0(d));return c.j?c.j(f,d,e):c.call(null,f,d,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.d1=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Mi,$CLJS.fB],null),$CLJS.Mi,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.query","query-method"),function(f,k){return $CLJS.WA(k)},e,a,b,c,d)}();$CLJS.d1.m(null,$CLJS.pA,function(a,b){b=$CLJS.WX.h(b);return $CLJS.c1.g(a,$CLJS.oK.h(b))});
$CLJS.d1.m(null,$CLJS.gV,function(a,b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.SX);var d=$CLJS.c_(a),e=$CLJS.Sj.g($CLJS.R.j(c,$CLJS.gD,d),$CLJS.SX);a=$CLJS.oK.h(e);return $CLJS.n(b)?$CLJS.R.j(e,$CLJS.oK,$CLJS.Wf.j($CLJS.xf,$CLJS.cf.h(function(f){var k=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return function u(m,t){try{if($CLJS.ud(t)&&3===$CLJS.D(t))try{var v=$CLJS.F(t,0);if($CLJS.ce(v,$CLJS.iF))try{var x=$CLJS.F(t,1);if($CLJS.n($CLJS.dD($CLJS.sd,$CLJS.Oe($CLJS.zz($CLJS.tA,$CLJS.mi)))(x)))try{var A=$CLJS.F(t,
2);if($CLJS.n($CLJS.dD($CLJS.Yk,$CLJS.ql)(A))){var C=$CLJS.F(t,2),G=$CLJS.F(t,1),K=$CLJS.Bl($CLJS.$0(d,C),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.mi],null));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iF,$CLJS.rk.l($CLJS.H([K,G])),C],null)}throw $CLJS.Z;}catch(da){if(da instanceof Error){var S=da;if(S===$CLJS.Z)throw $CLJS.Z;throw S;}throw da;}else throw $CLJS.Z;}catch(da){if(da instanceof Error){S=da;if(S===$CLJS.Z)throw $CLJS.Z;throw S;}throw da;}else throw $CLJS.Z;}catch(da){if(da instanceof
Error)if(S=da,S===$CLJS.Z)try{if(v=$CLJS.F(t,0),$CLJS.ce(v,$CLJS.qA))try{x=$CLJS.F(t,1);if($CLJS.n($CLJS.dD($CLJS.sd,$CLJS.Oe($CLJS.zz($CLJS.tA,$CLJS.mi)))(x))){G=$CLJS.F(t,1);var X=$CLJS.F(t,2);K=function(){try{return $CLJS.V0(function(oa){return $CLJS.E.g($CLJS.Zi,oa)},$CLJS.Bl($CLJS.dd($CLJS.Gpa.j(e,k,X)),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.mi],null)))}catch(oa){return null}}();return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qA,$CLJS.rk.l($CLJS.H([K,G])),X],null)}throw $CLJS.Z;}catch(oa){if(oa instanceof
Error){var T=oa;if(T===$CLJS.Z)throw $CLJS.Z;throw T;}throw oa;}else throw $CLJS.Z;}catch(oa){if(oa instanceof Error){T=oa;if(T===$CLJS.Z)throw $CLJS.Z;throw T;}throw oa;}else throw S;else throw da;}else throw $CLJS.Z;}catch(da){if(da instanceof Error){S=da;if(S===$CLJS.Z)return $CLJS.XV(u,m,t);throw S;}throw da;}}($CLJS.xf,f)}),$CLJS.Al($CLJS.xr,a))):e});
$CLJS.d1.m(null,$CLJS.QJ,function(a,b){return $CLJS.c1.g(a,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.UU,$CLJS.eO,$CLJS.W0(b)],null)],null))});$CLJS.d1.m(null,$CLJS.fK,function(a,b){return $CLJS.c1.g(a,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.UU,$CLJS.NU,$CLJS.W0(b)],null)],null))});