import type { ContextVisualizationSettings } from "metabase/dashboard/components/DashboardInfoSidebar/DashboardVisualizationSettingsContext";
import type { WIDGETS } from "metabase/visualizations/lib/settings";
import type { DashboardDefaultVisualizationSettingsKeys } from "metabase/visualizations/lib/settings/defaultSettings";
import type {
  Series,
  Settings,
  VisualizationSettings,
} from "metabase-types/api";

interface WidgetSettingFunction<T = any> {
  (series: Series, vizSettings: VisualizationSettings): T;
}
export type WidgetSetting = {
  title: string;
  description?: string;
  widget: keyof typeof WIDGETS | React.ComponentType;
  default?: any;
  inline?: boolean;
  section?: string;
  readDependencies?: string[];
  hidden?: boolean;
  group?: string;
  index?: number;
  marginBottom?: string;
  useRawSeries?: boolean;
  dashboard?: boolean;
  enabledAuto?: boolean;
  orientation?: "vertical" | "horizontal";
  dashboardDefaultVisualizationSettingId?: keyof Settings["dashboard-default-visualization-settings"];
  props?: Record<string, unknown>;
  getDefault?: WidgetSettingFunction;
  getProps?: WidgetSettingFunction;
  isValid?: WidgetSettingFunction<boolean>;
  getHidden?: WidgetSettingFunction<boolean>;
  getHiddenOnDashboardPanel?: WidgetSettingFunction<boolean>;
};

export type WidgetData = {
  key: DashboardDefaultVisualizationSettingsKeys;
  title: string;
  widget: keyof typeof WIDGETS | React.ComponentType;
  props?: Record<string, unknown>;
  value: any;
  hidden?: boolean;
  isHaveApplyButton?: boolean;
  orientation?: "vertical" | "horizontal";
};

export type WidgetSettings<T extends string> = Record<T, WidgetSetting>;

interface GetWidgetDataProps {
  key: DashboardDefaultVisualizationSettingsKeys;
  setting: WidgetSetting;
  contextSettings: ContextVisualizationSettings;
  dashboardDefaultSettings: Partial<
    Record<keyof Settings["dashboard-default-visualization-settings"], unknown>
  >;
}

export function getWidgetData({
  key,
  setting,
  contextSettings,
  dashboardDefaultSettings,
}: GetWidgetDataProps): WidgetData {
  let defaultValue = null;
  if (setting.dashboardDefaultVisualizationSettingId) {
    defaultValue =
      dashboardDefaultSettings[setting.dashboardDefaultVisualizationSettingId];
  }
  const settingValue = contextSettings[key] || defaultValue || setting.default;

  const emptySeries = [{ card: {}, data: {} }] as Series;

  const isHidden = setting.getHidden?.(
    emptySeries,
    contextSettings as VisualizationSettings,
  );

  const isHaveApplyButton =
    setting.widget === "number" ||
    setting.widget === "backgrounImageDimensions";

  const props = setting.props || {};

  return {
    key: key,
    title: setting.title,
    widget: setting.widget,
    props: props,
    value: settingValue,
    hidden: isHidden,
    orientation: "vertical",
    isHaveApplyButton: isHaveApplyButton,
  };
}
