/* eslint-disable react/prop-types */
import { PointerSensor, useSensor } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import cx from "classnames";
import { useState, useMemo } from "react";
import { t, jt } from "ttag";
import _ from "underscore";

import InputBlurChange from "metabase/components/InputBlurChange";
import NumericInput from "metabase/components/NumericInput";
import Button from "metabase/core/components/Button";
import ColorRange from "metabase/core/components/ColorRange";
import ColorRangeSelector from "metabase/core/components/ColorRangeSelector";
import ColorSelector from "metabase/core/components/ColorSelector";
import Radio from "metabase/core/components/Radio";
import Select, { Option } from "metabase/core/components/Select";
import { Sortable, SortableList } from "metabase/core/components/Sortable";
import Toggle from "metabase/core/components/Toggle";
import * as MetabaseAnalytics from "metabase/lib/analytics";
import { color } from "metabase/lib/colors";
import {
  getAccentColors,
  getStatusColorRanges,
} from "metabase/lib/colors/groups";
import { isPivotGroupColumn } from "metabase/lib/data_grid";
import { Icon } from "metabase/ui";
import { isNumeric, isString } from "metabase-lib/types/utils/isa";

const COMMON_OPERATOR_NAMES = {
  "is-null": t`is null`,
  "not-null": t`is not null`,
};

const NUMBER_OPERATOR_NAMES = {
  "=": t`is equal to`,
  "!=": t`is not equal to`,
  "<": t`is less than`,
  ">": t`is greater than`,
  "<=": t`is less than or equal to`,
  ">=": t`is greater than or equal to`,
};

const STRING_OPERATOR_NAMES = {
  "=": t`is equal to`,
  "!=": t`is not equal to`,
  contains: t`contains`,
  "does-not-contain": t`does not contain`,
  "starts-with": t`starts with`,
  "ends-with": t`ends with`,
};

const BOOLEAN_OPERATIOR_NAMES = {
  "is-true": t`is true`,
  "is-false": t`is false`,
};

export const ALL_OPERATOR_NAMES = {
  ...NUMBER_OPERATOR_NAMES,
  ...STRING_OPERATOR_NAMES,
  ...BOOLEAN_OPERATIOR_NAMES,
  ...COMMON_OPERATOR_NAMES,
};

// TODO
const COLORS = getAccentColors({ dark: false });
const COLOR_RANGES = getStatusColorRanges();

const DEFAULTS_BY_TYPE = {
  single: {
    columns: [],
    targetColumn: "",
    format_relative_to_cell: false,
    type: "single",
    operator: "=",
    value: "",
    color: COLORS[0],
    target: "background",
    highlight_row: false,
    font_bold: false,
    font_italic: false,
  },
  range: {
    columns: [],
    type: "range",
    target: "background",
    colors: [color("white"), color("brand")],
    min_type: null,
    max_type: null,
    min_value: 0,
    max_value: 100,
  },
};

// predicate for columns that can be formatted
export const isFormattable = field => isNumeric(field) || isString(field);

const INPUT_CLASSNAME = "mt1 full";

const getValueForDescription = rule =>
  ["is-null", "not-null"].includes(rule.operator) ? "" : ` ${rule.value}`;

const isSelectColumnFromList = (targetColumns, columnsList) =>
  columnsList.some(column => targetColumns.includes(column));

export const ChartSettingsTableFormatting = props => {
  const [editingRule, setEditingRule] = useState();
  const [editingRuleIsNew, setEditingRuleIsNew] = useState();

  const {
    value,
    onChange,
    cols,
    allCols,
    canHighlightRow,
    columnSplit,
    hiddenFieldRefs,
    isMap,
  } = props;

  let pivotCols = [];

  if (columnSplit) {
    const columnsWithoutPivotGroup = allCols.filter(
      col => !isPivotGroupColumn(col),
    );

    const { rows: rowColumnIndexes } = _.mapObject(
      columnSplit,
      columnFieldRefs =>
        columnFieldRefs
          .map(field_ref =>
            columnsWithoutPivotGroup.findIndex(col => {
              const hide =
                hiddenFieldRefs.find(field_ref =>
                  _.isEqual(col.field_ref, field_ref),
                ) !== undefined;
              return hide ? false : _.isEqual(col.field_ref, field_ref);
            }),
          )
          .filter(index => index !== -1),
    );
    pivotCols = rowColumnIndexes.map(index => allCols[index]);
  }

  if (editingRule !== null && value[editingRule]) {
    return (
      <RuleEditor
        canHighlightRow={isMap ? false : canHighlightRow}
        rule={value[editingRule]}
        cols={cols}
        pivotTableLeftCols={pivotCols}
        isNew={editingRuleIsNew}
        isMap={isMap}
        onChange={rule => {
          onChange([
            ...value.slice(0, editingRule),
            rule,
            ...value.slice(editingRule + 1),
          ]);
        }}
        onRemove={() => {
          onChange([
            ...value.slice(0, editingRule),
            ...value.slice(editingRule + 1),
          ]);
          setEditingRule(null);
          setEditingRuleIsNew(null);
        }}
        onDone={() => {
          setEditingRule(null);
          setEditingRuleIsNew(null);
        }}
      />
    );
  } else {
    return (
      <RuleListing
        rules={value}
        cols={cols}
        onEdit={index => {
          setEditingRule(index);
          setEditingRuleIsNew(false);
        }}
        // This needs to be an async function so that onChange will complete (and value will be updated)
        // Before we set the state values for the next render
        onAdd={async () => {
          await onChange([
            {
              ...DEFAULTS_BY_TYPE["single"],
              // if there's a single column use that by default
              columns: cols.length === 1 ? [cols[0].name] : [],
              id: value.length,
            },
            ...value,
          ]);
          setEditingRuleIsNew(true);
          setEditingRule(0);
        }}
        onRemove={index => {
          onChange([...value.slice(0, index), ...value.slice(index + 1)]);
          MetabaseAnalytics.trackStructEvent(
            "Chart Settings",
            "Table Formatting",
            "Remove Rule",
          );
        }}
        onMove={(from, to) => {
          onChange(arrayMove(value, from, to));
          MetabaseAnalytics.trackStructEvent(
            "Chart Settings",
            "Table Formatting",
            "Move Rule",
          );
        }}
      />
    );
  }
};

const SortableRuleList = ({ rules, cols, onEdit, onRemove, onMove }) => {
  const rulesWithIDs = useMemo(
    () => rules.map((rule, index) => ({ ...rule, id: index.toString() })),
    [rules],
  );

  const getId = rule => rule.id.toString();

  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 15 },
  });

  const handleSortEnd = ({ id, newIndex }) => {
    const oldIndex = rulesWithIDs.findIndex(rule => getId(rule) === id);

    onMove(oldIndex, newIndex);
  };

  const handleRemove = id =>
    onRemove(rulesWithIDs.findIndex(rule => getId(rule) === id));

  const handleEdit = id =>
    onEdit(rulesWithIDs.findIndex(rule => getId(rule) === id));

  const renderItem = ({ item, id }) => (
    <Sortable id={id} draggingStyle={{ opacity: 0.5 }}>
      <RulePreview
        rule={item}
        cols={cols}
        onClick={() => handleEdit(id)}
        onRemove={() => handleRemove(id)}
      />
    </Sortable>
  );

  return (
    <div>
      <SortableList
        items={rulesWithIDs}
        getId={getId}
        renderItem={renderItem}
        sensors={[pointerSensor]}
        onSortEnd={handleSortEnd}
      />
    </div>
  );
};

const RuleListing = ({
  rules,
  cols,
  onEdit,
  onAdd,
  onRemove,
  onMove,
  isMap,
}) => (
  <div>
    <h3>{t`Conditional formatting`}</h3>
    <div className="mt2">
      {t`You can add rules to make the cells in this table change color if
    they meet certain conditions.`}
    </div>
    <div className="mt2">
      <Button borderless icon="add" onClick={onAdd}>
        {t`Add a rule`}
      </Button>
    </div>
    {rules.length > 0 ? (
      <div className="mt2">
        <h3>{t`Rules will be applied in this order`}</h3>
        <div className="mt2">{t`Click and drag to reorder.`}</div>
        <SortableRuleList
          isMap={isMap}
          rules={rules}
          cols={cols}
          onEdit={onEdit}
          onRemove={onRemove}
          onMove={onMove}
          distance={10}
        />
      </div>
    ) : null}
  </div>
);

const RulePreview = ({ rule, cols, onClick, onRemove, isMap }) => (
  <div
    className="my2 bordered rounded shadowed cursor-pointer bg-white"
    onClick={onClick}
  >
    <div className="p1 border-bottom relative bg-light">
      <div className="px1 flex align-center relative">
        <span className="h4 flex-auto text-dark text-wrap">
          {rule.columns.length > 0 ? (
            rule.columns
              .map(
                name =>
                  (_.findWhere(cols, { name }) || {}).display_name || name,
              )
              .join(", ")
          ) : rule.targetColumn ? (
            (_.findWhere(cols, { name: rule.targetColumn }) || {})
              .display_name || rule.targetColumn
          ) : (
            <span
              style={{ fontStyle: "oblique" }}
            >{t`No columns selected`}</span>
          )}
        </span>
        <Icon
          name="close"
          className="cursor-pointer text-light text-medium-hover"
          onClick={e => {
            e.stopPropagation();
            onRemove();
          }}
        />
      </div>
    </div>
    <div className="p2 flex align-center">
      <RuleBackground
        rule={rule}
        className={cx("mr2 flex-no-shrink rounded", {
          bordered: rule.type === "range",
        })}
        style={{ width: 40, height: 40 }}
      />
      <RuleDescription rule={rule} />
    </div>
  </div>
);

const RuleBackground = ({ rule, className, style }) =>
  rule.type === "range" ? (
    <ColorRange colors={rule.colors} className={className} style={style} />
  ) : rule.type === "single" ? (
    <SinglePreview color={rule.color} className={className} style={style} />
  ) : null;

const SinglePreview = ({ color, className, style, ...props }) => (
  <div
    className={className}
    style={{ ...style, background: color }}
    {...props}
  />
);

const RuleDescription = ({ rule }) => {
  return (
    <span key="1">
      {rule.typ === "range"
        ? t`Cells in this column will be tinted based on their values.`
        : rule.type === "single"
        ? jt`When a cell in these columns ${(
            <span className="text-bold" key="2">
              {ALL_OPERATOR_NAMES[rule.operator]}
              {getValueForDescription(rule)}
            </span>
          )} it will be tinted this color.`
        : null}
    </span>
  );
};

const RuleEditor = ({
  rule,
  cols,
  pivotTableLeftCols = [],
  isNew,
  onChange,
  onDone,
  onRemove,
  canHighlightRow = true,
  isMap,
}) => {
  const allCols = [...pivotTableLeftCols, ...cols];

  const selectedColumns = rule.columns.map(name =>
    _.findWhere(allCols, { name }),
  );

  const selectedLeftColumn = [rule.targetColumn].map(name =>
    _.findWhere(cols, { name }),
  );

  const isFormatRelativeToCol = isMap || rule.format_relative_to_cell;

  const isStringRule = isFormatRelativeToCol
    ? _.all(selectedLeftColumn, isString)
    : selectedColumns.length > 0 && _.all(selectedColumns, isString);

  const isNumericRule = isFormatRelativeToCol
    ? _.all(selectedLeftColumn, isNumeric)
    : selectedColumns.length > 0 && _.all(selectedColumns, isNumeric);

  const isTargetColumnIsNumeric = _.all(
    [_.findWhere(cols, { name: rule.targetColumn })],
    isNumeric,
  );

  const hasOperand =
    rule.operator !== "is-null" && rule.operator !== "not-null";

  const pivotTableLeftColsNames = pivotTableLeftCols.map(col => col.name);

  const isSelectPivotLeftColumn = isSelectColumnFromList(
    rule.columns,
    pivotTableLeftColsNames,
  );

  const isSelectRelativeWithoutTargetCol =
    isFormatRelativeToCol && !rule.targetColumn;

  const isNeedRenderColorStylesOptions =
    rule.target === "background" && isNumericRule;

  return (
    <div>
      {!isMap && (
        <>
          {" "}
          <h3 className="mb1">{t`Which columns should be affected?`}</h3>
          <Select
            value={rule.columns}
            onChange={e => {
              onChange({
                ...rule,
                ...(isSelectPivotLeftColumn ? { highlight_row: false } : {}),
                columns: e.target.value,
              });
            }}
            isInitiallyOpen={rule.columns.length === 0}
            placeholder="Choose a column"
            multiple
          >
            {allCols.map(col => (
              <Option
                key={col.name}
                value={col.name}
                disabled={
                  isFormatRelativeToCol
                    ? false
                    : (isStringRule && !isString(col)) ||
                      (isNumericRule && !isNumeric(col))
                }
              >
                {col.display_name}
              </Option>
            ))}
          </Select>
        </>
      )}
      {(rule.columns.length > 0 || isMap) && (
        <>
          {!isMap && (
            <div>
              <h3 className="mt3 mb1">{t`Formatting target`}</h3>
              <Radio
                value={rule.target}
                options={[
                  { name: t`Background color`, value: "background" },
                  { name: t`Text color`, value: "text" },
                  { name: t`Font style`, value: "font" },
                ]}
                onChange={target => onChange({ ...rule, target })}
                vertical
              />
            </div>
          )}
          {(isNeedRenderColorStylesOptions ||
            (isMap && isTargetColumnIsNumeric)) && (
            <div>
              <h3 className="mt3 mb1">{t`Formatting style`}</h3>
              <Radio
                value={rule.type}
                options={[
                  { name: t`Single color`, value: "single" },
                  { name: t`Color range`, value: "range" },
                ]}
                onChange={type =>
                  onChange({ ...DEFAULTS_BY_TYPE[type], ...rule, type })
                }
                vertical
              />
            </div>
          )}
          {rule.type === "single" ? (
            <div>
              {
                <>
                  {!isMap && (
                    <>
                      <h3 className="mt3 mb1">{t`Format relative to cell`}</h3>
                      <Toggle
                        value={isFormatRelativeToCol}
                        onChange={format_relative_to_cell =>
                          onChange({ ...rule, format_relative_to_cell })
                        }
                      />
                    </>
                  )}
                  {(isFormatRelativeToCol || isMap) && (
                    <SelectTargetColumn
                      cols={allCols}
                      onChange={onChange}
                      rule={rule}
                    />
                  )}
                </>
              }
              <h3 className="mt3 mb1">{t`Condition`}</h3>
              <Select
                disabled={isSelectRelativeWithoutTargetCol}
                value={rule.operator}
                onChange={e => onChange({ ...rule, operator: e.target.value })}
              >
                {Object.entries(
                  isNumericRule ? NUMBER_OPERATOR_NAMES : STRING_OPERATOR_NAMES,
                ).map(([operator, operatorName]) => (
                  <Option key={operatorName} value={operator}>
                    {operatorName}
                  </Option>
                ))}
              </Select>
              {hasOperand && isNumericRule ? (
                <NumericInput
                  disabled={isSelectRelativeWithoutTargetCol}
                  data-testid="conditional-formatting-value-input"
                  className={INPUT_CLASSNAME}
                  type="number"
                  value={rule.value}
                  onChange={value => onChange({ ...rule, value })}
                  placeholder="0"
                />
              ) : hasOperand ? (
                <InputBlurChange
                  disabled={isSelectRelativeWithoutTargetCol}
                  data-testid="conditional-formatting-value-input"
                  className={INPUT_CLASSNAME}
                  value={rule.value}
                  onBlurChange={e =>
                    onChange({ ...rule, value: e.target.value })
                  }
                  placeholder={t`Column value`}
                />
              ) : null}
              {rule.target !== "font" && (
                <>
                  <h3 className="mt3 mb1">
                    {rule.target === "background"
                      ? t`…turn its background this color:`
                      : t`…turn its text this color:`}
                  </h3>
                  <ColorSelector
                    value={rule.color}
                    colors={COLORS}
                    onChange={color => onChange({ ...rule, color })}
                  />
                </>
              )}
              {rule.target === "background" &&
                canHighlightRow &&
                !isSelectPivotLeftColumn && (
                  <>
                    <h3 className="mt3 mb1">{t`Highlight the whole row`}</h3>

                    <Toggle
                      value={rule.highlight_row}
                      onChange={highlight_row =>
                        onChange({ ...rule, highlight_row })
                      }
                    />
                  </>
                )}
              {rule.target === "font" && (
                <>
                  <h3 className="mt3 mb1">{t`Set font bold`}</h3>

                  <Toggle
                    value={rule.font_bold}
                    onChange={font_bold => onChange({ ...rule, font_bold })}
                  />

                  <h3 className="mt3 mb1">{t`Set font italic`}</h3>

                  <Toggle
                    value={rule.font_italic}
                    onChange={font_italic => onChange({ ...rule, font_italic })}
                  />
                </>
              )}
            </div>
          ) : rule.type === "range" ? (
            <div>
              {isMap && (
                <SelectTargetColumn
                  cols={allCols}
                  onChange={onChange}
                  rule={rule}
                />
              )}
              <h3 className="mt3 mb1">{t`Colors`}</h3>
              <ColorRangeSelector
                value={rule.colors}
                onChange={colors => {
                  MetabaseAnalytics.trackStructEvent(
                    "Chart Settings",
                    "Table Formatting",
                    "Select Range  Colors",
                    colors,
                  );
                  onChange({ ...rule, colors });
                }}
                colors={COLORS}
              />
              <h3 className="mt3 mb1">{t`Start the range at`}</h3>
              <Radio
                value={rule.min_type}
                onChange={min_type => onChange({ ...rule, min_type })}
                options={(rule.columns.length <= 1
                  ? [{ name: t`Smallest value in this column`, value: null }]
                  : [
                      { name: t`Smallest value in each column`, value: null },
                      {
                        name: t`Smallest value in all of these columns`,
                        value: "all",
                      },
                    ]
                ).concat([{ name: t`Custom value`, value: "custom" }])}
                vertical
              />
              {rule.min_type === "custom" && (
                <NumericInput
                  className={INPUT_CLASSNAME}
                  type="number"
                  value={rule.min_value}
                  onChange={min_value => onChange({ ...rule, min_value })}
                />
              )}
              <h3 className="mt3 mb1">{t`End the range at`}</h3>
              <Radio
                value={rule.max_type}
                onChange={max_type => onChange({ ...rule, max_type })}
                options={(rule.columns.length <= 1
                  ? [{ name: t`Largest value in this column`, value: null }]
                  : [
                      { name: t`Largest value in each column`, value: null },
                      {
                        name: t`Largest value in all of these columns`,
                        value: "all",
                      },
                    ]
                ).concat([{ name: t`Custom value`, value: "custom" }])}
                vertical
              />
              {rule.max_type === "custom" && (
                <NumericInput
                  className={INPUT_CLASSNAME}
                  type="number"
                  value={rule.max_value}
                  onChange={max_value => onChange({ ...rule, max_value })}
                />
              )}
            </div>
          ) : null}
          <div className="mt4">
            {rule.columns.length === 0 ? (
              <Button
                primary
                onClick={onRemove}
                data-metabase-event="Chart Settings;Table Formatting;"
              >
                {isNew ? t`Cancel` : t`Delete`}
              </Button>
            ) : (
              <Button
                primary
                onClick={onDone}
                data-metabase-event={`Chart Setttings;Table Formatting;${
                  isNew ? "Add Rule" : "Update Rule"
                };Rule Type ${rule.type} Color`}
              >
                {isNew ? t`Add rule` : t`Update rule`}
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

function SelectTargetColumn({ rule, cols, onChange }) {
  const isTargetColumnIsNumeric = _.all(
    [_.findWhere(cols, { name: rule.targetColumn })],
    isNumeric,
  );

  return (
    <>
      <h3 className="mt3 mb1">{t`When a cell in column…`}</h3>
      <Select
        value={rule.targetColumn}
        onChange={e =>
          onChange({
            ...rule,
            targetColumn: e.target.value,
            type: isTargetColumnIsNumeric ? "single" : rule.type,
          })
        }
        isInitiallyOpen={rule.columns.length === 0}
        placeholder="Choose a column"
      >
        {cols.map(col => (
          <Option key={col.name} value={col.name}>
            {col.display_name}
          </Option>
        ))}
      </Select>
    </>
  );
}
