import styled from "@emotion/styled";
import { color } from "metabase/lib/colors";

interface ChartBackgroundImageSettingsWrapperProps {
  zIndex: 0 | 2;
}

export const ChartBackgroundImageSettingsWrapper = styled.div<ChartBackgroundImageSettingsWrapperProps>`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: ${props => props.zIndex};
  pointer-events: none;
`;

interface ImageCardWrapperProps {
  width: number;
  height: number;
}

export const ImageCardWrapper = styled.div<ImageCardWrapperProps>`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 1px dashed ${color("bg-dark")};
  box-sizing: content-box;
`;

interface DragSectionProps {
  isInteractive: boolean;
}

export const DragSection = styled.div<DragSectionProps>`
  width: 100%;
  height: 100%;
  cursor: grab;
  background-color: transparent;
  position: absolute;
  box-sizing: content-box;
  pointer-events: ${props => (props.isInteractive ? "all" : "all")};
`;

export const ImageBlock = styled.div`
  width: 100%;
  height: 100%;
`;
