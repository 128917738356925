import React, { useCallback, useEffect, useState } from "react";
import { t } from "ttag";
import ActionButton from "metabase/components/ActionButton";
import {
  ButtonsContainer,
  ButtonUploadContainer,
  Container,
  RadioContainer,
  SubTitle,
  Title,
} from "metabase/components/uploadFile/fileUpload/components/FileUpload/FileUpload.styled";
import { MetabaseApi } from "metabase/services";
import FileUploadLayout from "metabase/components/uploadFile/fileUpload/containers/FileUploadLayout";
import Button from "metabase/core/components/Button/Button";
import FileUploadModal from "metabase/components/uploadFile/fileUpload/components/FileUploadModal";
import Radio from "metabase/core/components/Radio/Radio";

export interface FileUploadProps {
  onOpenNavbar: () => void;
  currentUser: any;
}

enum ELoadType {
  overwrite = "overwrite",
  supplement = "supplement",
  create = "create",
}

const FileUpload = ({
  onOpenNavbar,
  currentUser,
}: FileUploadProps): JSX.Element => {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [title, setTitle] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [redirectPage, setRedirectPage] = useState<string | undefined>(
    undefined,
  );
  const [errorText, setErrorText] = useState<string | undefined>(undefined);
  const [loadType, setLoadType] = useState<ELoadType>(ELoadType.create);

  useEffect(() => {
    if (errorText || redirectPage) {
      setIsModalOpen(true);
    }
  }, [errorText, redirectPage]);

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
    setRedirectPage(undefined);
    setErrorText(undefined);
    setFile(undefined);
  }, []);

  const handleChange = (event: any) => {
    //alert(event.target.files[0].size);
    if (event.target.files[0].size > 104857600) {
      alert(t`File size must be less then 100Mb`);
      event.target.value = "";
      setFile(event.target.value);
    } else {
      setFile(event.target.files[0]);
    }
  };

  const handleHiddenClick = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };
  const handleChangeType = (newType: ELoadType) => {
    setLoadType(newType);
  };

  const uploadFile = async (databaseId: number) => {
    if (file) {
      const url =
        "api/database/load_excel?filename=" +
        encodeURI(file.name.split(".")[0] + "&load_type=" + loadType);
      const req = new XMLHttpRequest();
      req.open("POST", url, false);
      req.setRequestHeader("Content-type", "application/octet-stream");
      req.setRequestHeader(
        "Content-Disposition",
        encodeURIComponent(file.name.split(".")[0]),
      );
      req.send(await file.arrayBuffer());
      if (req.status !== 200) {
        setErrorText(
          t`Request error` + "\n" + req.status + ": " + req.statusText,
        ); // пример вывода: 404: Not Found
      } else {
        //setTitle(t`Updating metadata`);
        // await MetabaseApi.db_sync_schema({ dbId: databaseId });
        const res = JSON.parse(req.responseText);
        //alert(req.responseText);
        if (res.error) {
          if (res.error === "Incorrect excel structure") {
            setErrorText(
              t`Error: Incorrect excel structure on tab: ` + res.errorTab,
            );
          } else if (res.error === "Data import error") {
            setErrorText(t`Error: Data import error on tab: ` + res.errorTab);
          } else if (res.error === "No one database has been selected as a target for excel files") {
            setErrorText(t`Error: No one database has been selected as a target for excel files`);
          } else if (res.error === "Missing headers for some data columns") {
            setErrorText(
              t`Error: Missing headers for some data columns on tab: ` +
                res.errorTab,
            );
          } else {
            setErrorText(t`Error: ` + res.error + " on tab: " + res.errorTab);
          }
        } else {
          setRedirectPage(
            "/browse/" + databaseId + "/schema/" + res.schemaName,
          );
        }
      }
    }
  };

  const handleClick = async () => {
    setTitle(null);

    const database = await MetabaseApi.db_excel_store();
    const databaseId = database.id;
    //let schemaName;

    //if (database.engine === "postrges") {
    //  schemaName = database.details["schema-filters-patterns"];
    //} else {
    //schemaName = database.details["dbname"]
    //  schemaName = "db1";
    //}

    if (!file) {
      alert(t`You must choose a file`);
    }
    setLoading(true);

    await uploadFile(databaseId);

    setTimeout(() => {
      setLoading(false);
    }, 200);
    //alert(t`Successful loading` + JSON.parse(req.responseText).shemaName); // responseText -- текст ответа.
  };

  return (
    <FileUploadLayout>
      <Title>{t`File upload page`}</Title>
      {file && (
        <SubTitle>
          <strong>{file.name} </strong> {t`ready for upload`}
        </SubTitle>
      )}
      <p>{t`Excel filename allows only alfanumeric symbols in the name`}</p>
      <p>{t`First row in excel must contains columns names`}</p>
      <p>{t`Attention! Before importing, make sure that all values match the type in which they are to be stored in the table. In case of a mismatch, the column type will be set to string.`}</p>
      <Container>
        {!loading && (
          <RadioContainer>
            <Radio
              className="ml2"
              variant="normal"
              vertical
              value={loadType}
              options={[
                { name: t`Overwrite`, value: ELoadType.overwrite },
                { name: t`Supplement`, value: ELoadType.supplement },
                {
                  name: t`Create new with suffix - current date`,
                  value: ELoadType.create,
                },
              ]}
              showButtons
              onChange={handleChangeType}
            />
          </RadioContainer>
        )}
        <ButtonsContainer>
          {!loading && (
            <>
              <input
                ref={hiddenFileInput}
                type="file"
                accept=".xlsx, .xls, .csv"
                onChange={handleChange}
                style={{ display: "none" }}
              />
              <ButtonUploadContainer>
                <Button
                  primary={!file}
                  small
                  onClick={handleHiddenClick}
                  style={{ height: "50px" }}
                >
                  {file ? t`Upload another file` : t`Upload excel file here`}
                </Button>
              </ButtonUploadContainer>
            </>
          )}
          {file && (
            <ActionButton
              primary={!!file}
              className="Button"
              normalText={t`Upload excel file `}
              failedText={t`Failed`}
              successText={t`Upload finished`}
              actionFn={handleClick}
            />
          )}
        </ButtonsContainer>
        {isModalOpen && (
          <FileUploadModal
            handleModalClose={handleModalClose}
            errorText={errorText}
            redirectPage={redirectPage}
          />
        )}
      </Container>
    </FileUploadLayout>
  );
};

export default FileUpload;
