import Dimension from "metabase-lib/Dimension";
import { getColumnKey } from "metabase-lib/queries/utils/get-column-key";
import { normalize } from "metabase-lib/queries/utils/normalize";
import type {
  DatasetColumn,
  DatasetData,
  FieldReference,
  TableColumnOrderSetting,
} from "metabase-types/api";

export const datasetContainsNoResults = (data: DatasetData) =>
  data.rows == null || data.rows.length === 0;

export function getColumnSettingKey({
  key,
  name,
  fieldRef,
}: TableColumnOrderSetting) {
  return key ?? getColumnKey({ name, field_ref: normalize(fieldRef) });
}

export function findColumnIndexesForColumnSettings(
  columns: DatasetColumn[],
  columnSettings: TableColumnOrderSetting[],
) {
  const columnIndexByKey = new Map(
    columns.map((column, index) => [getColumnKey(column), index]),
  );
  return columnSettings.map(
    columnSetting =>
      columnIndexByKey.get(getColumnSettingKey(columnSetting)) ?? -1,
  );
}

export function findColumnSettingIndexesForColumns(
  columns: DatasetColumn[],
  columnSettings: TableColumnOrderSetting[],
) {
  const columnSettingIndexByKey = new Map(
    columnSettings.map((columnSetting, index) => [
      getColumnSettingKey(columnSetting),
      index,
    ]),
  );
  return columns.map(
    column => columnSettingIndexByKey.get(getColumnKey(column)) ?? -1,
  );
}

export function normalizeFieldRef(fieldRef: FieldReference) {
  const dimension = Dimension.parseMBQL(fieldRef);
  return dimension && dimension.mbql();
}


//  Glarus
// export function syncTableColumnsToQuery(question: Question) {
//   let query = question.legacyQuery();
//   const columnSettings = question.settings()["table.columns"];
//   if (columnSettings && query instanceof StructuredQuery) {
//     // clear `fields` first
//
//
//     // do this before clearing join columns since the default is "none" thus joined columns will be removed
//     const columnDimensions = query.columnDimensions();
//     const columnNames = query.columnNames();
//
//     // clear join's `fields`
//     for (let i = query.joins().length - 1; i >= 0; i--) {
//       const join = query.joins()[i];
//       query = join.clearFields().parent();
//     }
//
//     for (const columnSetting of columnSettings) {
//       if (columnSetting.enabled) {
//         let fieldRef;
//         if (columnSetting.fieldRef) {
//           fieldRef = columnSetting.fieldRef;
//         } else if (columnSetting.name) {
//           const index = _.findIndex(columnNames, n => n === columnSetting.name);
//           if (index >= 0) {
//             fieldRef = columnDimensions[index].mbql();
//           }
//         }
//         if (fieldRef) {
//           const dimension = query.parseFieldReference(fieldRef);
//           const join = dimension.join && dimension.join();
//           if (!join) {
//             console.warn("missing join?", query, dimension);
//           }
//           // NOTE: this logic should probably be in StructuredQuery
//           if (
//             dimension instanceof FieldDimension &&
//             dimension.joinAlias() &&
//             join
//           ) {
//             query = join.addField(dimension.mbql()).parent();
//           } else {
//             query = query.addField(dimension.mbql());
//           }
//         } else {
//           console.warn("Unknown column", columnSetting);
//         }
//       }
//     }
//     // if removing `fields` wouldn't change the resulting columns, just remove it
//     const newColumnDimensions = query.columnDimensions();
//     if (
//       columnDimensions.length === newColumnDimensions.length &&
//       _.all(columnDimensions, (d, i) =>
//         d.isSameBaseDimension(newColumnDimensions[i]),
//       )
//     ) {
//       return query.clearFields().question();
//     } else {
//       return query.question();
//     }
//   }
//   return question;
// }
