import { t } from "ttag";
import { useEffect, useState } from "react";
import { SessionApi } from "metabase/services";
import {
  InfoBody,
  InfoIcon,
  InfoIconContainer,
  InfoMessage,
} from "metabase/auth/components/License/License.styled";
import { AuthLayout } from "../AuthLayout";
import UpdateLicenseForm from "../UpdateLicenseForm";

const License = () => {
  const [licenseRequest, setLicenseRequest] = useState("");
  useEffect(() => {
    let isMounted = true;
    const getLicense = async () => {
      const license = await SessionApi.get_license();
      return license;
    };

    const updateLicenseRequest = async () => {
      const license = await getLicense();
      const licenseRequest = license["license_request"];
      if (license.status === "Active") {
        window.location.replace("/");
      }
      if (isMounted) {
        setLicenseRequest(licenseRequest);
      }
    };

    updateLicenseRequest();

    return () => {
      isMounted = false;
    };
  }, []);

  const renewLicense = async (licenseKey: string) => {
    const license = await SessionApi.update_license({
      licenseKey: licenseKey.trim(),
    });
    return license;
  };

  const renewLicenseRequest = async (licenseKey: string) => {
    const license = await renewLicense(licenseKey);
    if (license["status"] === "Active") {
    // if (license === "OK") {
      window.location.replace("/");
    }
  };

  return (
    <AuthLayout>
      <InfoBody>
        <InfoIconContainer>
          <InfoIcon name="info" />
        </InfoIconContainer>
        <InfoMessage>
          {t`Your license has expired. Please contact GlarusBI support to renew your license.`}
        </InfoMessage>
        <InfoMessage>
          {t`License request code`}
          <br />
          <br />
          <b>{licenseRequest}</b>
        </InfoMessage>
        <UpdateLicenseForm onSubmit={renewLicenseRequest} />
      </InfoBody>
    </AuthLayout>
  );
};

// eslint-disable-next-line import/no-default-export
export default License;
