/* eslint-disable react/prop-types */
import Color from "color";
import d3 from "d3";
import { Component } from "react";
import ss from "simple-statistics";
import { t } from "ttag";
import _ from "underscore";

import LoadingSpinner from "metabase/components/LoadingSpinner";
import { formatValue } from "metabase/lib/formatting";
import MetabaseSettings from "metabase/lib/settings";
import { MinColumnsError } from "metabase/visualizations/lib/errors";
import {
  computeMinimalBounds,
  getCanonicalRowKey,
} from "metabase/visualizations/lib/mapping";
import {
  getDefaultSize,
  getMinSize,
} from "metabase/visualizations/shared/utils/sizes";
import { isMetric, isString } from "metabase-lib/types/utils/isa";

import ChartWithLegend from "./ChartWithLegend";
import LeafletChoropleth from "./LeafletChoropleth";
import LegacyChoropleth from "./LegacyChoropleth";

// TODO COLOR
const HEAT_MAP_COLORS = ["#C4E4FF", "#81C5FF", "#51AEFF", "#1E96FF", "#0061B5"];
const HEAT_MAP_ZERO_COLOR = "#CCC";
const HEAT_MAP_ZERO_RADIUS = null;

export function getColorplethColorScale(
  color,
  { lightness = 92, darken = 0.2, darkenLast = 0.3, saturate = 0.1 } = {},
) {
  const lightColor = Color(color).lightness(lightness).saturate(saturate);

  const darkColor = Color(color).darken(darken).saturate(saturate);

  const scale = d3.scale
    .linear()
    .domain([0, 1])
    .range([lightColor.string(), darkColor.string()]);

  const colors = d3.range(0, 1.25, 0.25).map(value => scale(value));

  if (darkenLast) {
    colors[colors.length - 1] = Color(color)
      .darken(darkenLast)
      .saturate(saturate)
      .string();
  }

  return colors;
}

const geoJsonCache = new Map();

function loadGeoJson(geoJsonPath, callback) {
  if (geoJsonCache.has(geoJsonPath)) {
    setTimeout(() => callback(geoJsonCache.get(geoJsonPath)), 0);
  } else {
    d3.json(geoJsonPath, json => {
      geoJsonCache.set(geoJsonPath, json);
      callback(json);
    });
  }
}

export function getLegendTitles(groups, columnSettings) {
  const formatMetric = (value, compact) =>
    formatValue(value, { ...columnSettings, compact });

  const compact = shouldUseCompactFormatting(groups, formatMetric);

  return groups.map((group, index) => {
    const min = formatMetric(group[0], compact);
    const max = formatMetric(group[group.length - 1], compact);
    return index === groups.length - 1
      ? `${min} +` // the last value in the list
      : min !== max
      ? `${min} - ${max}` // typical case
      : min; // special case to avoid zero-width ranges e.g. $88-$88
  });
}

// if the average formatted length is greater than this, we switch to compact formatting
const AVERAGE_LENGTH_CUTOFF = 5;

function shouldUseCompactFormatting(groups, formatMetric) {
  const minValues = groups.map(([x]) => x);
  const maxValues = groups.slice(0, -1).map(group => group[group.length - 1]);
  const allValues = minValues.concat(maxValues);
  const formattedValues = allValues.map(value => formatMetric(value, false));
  const averageLength =
    formattedValues.reduce((sum, { length }) => sum + length, 0) /
    formattedValues.length;
  return averageLength > AVERAGE_LENGTH_CUTOFF;
}

export default class ChoroplethMap extends Component {
  static propTypes = {};

  static minSize = getMinSize("map");
  static defaultSize = getDefaultSize("map");

  static isSensible({ cols }) {
    return cols.filter(isString).length > 0 && cols.filter(isMetric).length > 0;
  }

  static checkRenderable([
    {
      data: { cols, rows },
    },
  ]) {
    if (cols.length < 2) {
      throw new MinColumnsError(2, cols.length);
    }
  }

  constructor(props, context) {
    super(props, context);
    this.state = {
      geoJson: null,
      geoJsonPath: null,
      metersPerPx: 1000,
    };
  }

  UNSAFE_componentWillMount() {
    this.UNSAFE_componentWillReceiveProps(this.props);
  }

  _getDetails(props) {
    return MetabaseSettings.get("custom-geojson", {})[
      props.settings["map.region"]
    ];
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const details = this._getDetails(nextProps);
    if (details) {
      let geoJsonPath;
      if (details.builtin) {
        geoJsonPath = details.url;
      } else {
        geoJsonPath = "api/geojson/" + nextProps.settings["map.region"];
      }
      if (this.state.geoJsonPath !== geoJsonPath) {
        this.setState({
          geoJson: null,
          geoJsonPath: geoJsonPath,
        });
        loadGeoJson(geoJsonPath, geoJson => {
          this.setState({
            geoJson: geoJson,
            geoJsonPath: geoJsonPath,
            minimalBounds: computeMinimalBounds(geoJson.features),
          });
        });
      }
    }
  }

  render() {
    const details = this._getDetails(this.props);
    if (!details) {
      return <div>{t`unknown map`}</div>;
    }

    const {
      series,
      className,
      gridSize,
      hovered,
      onHoverChange,
      visualizationIsClickable,
      onVisualizationClick,
      settings,
    } = this.props;
    const { geoJson, minimalBounds } = this.state;

    // special case builtin maps to use legacy choropleth map
    let projection, projectionFrame;
    // projectionFrame is the lng/lat of the top left and bottom right corners
    if (settings["map.region"] === "us_states") {
      projection = d3.geo.albersUsa();
      projectionFrame = [
        [-135.0, 46.6],
        [-69.1, 21.7],
      ];
    } else if (settings["map.region"] === "world_countries") {
      projection = d3.geo.mercator();
      projectionFrame = [
        [-170, 78],
        [180, -60],
      ];
    } else {
      projection = null;
    }

    projection = null;

    const nameProperty = details.region_name;
    const keyProperty = details.region_key;

    if (!geoJson) {
      return (
        <div className={className + " flex layout-centered"}>
          <LoadingSpinner />
        </div>
      );
    }

    const [
      {
        data: { cols, rows },
      },
    ] = series;
    const dimensionIndex = _.findIndex(
      cols,
      col => col.name === settings["map.dimension"],
    );
    const metricIndex = _.findIndex(
      cols,
      col => col.name === settings["map.metric"],
    );

    const metricCircleIndex =
      _.findIndex(cols, col => col.name === settings["map.metric_circle"]) ||
      metricIndex;

    const getRowKey = row =>
      getCanonicalRowKey(row[dimensionIndex], settings["map.region"]);
    const getRowValue = row => row[metricIndex] || 0;
    const getRowCircleValue = row => row[metricCircleIndex] || 0;

    const getFeatureName = feature => String(feature.properties[nameProperty]);
    const getFeatureKey = (feature, { lowerCase = true } = {}) => {
      const key = String(feature.properties[keyProperty]);
      return lowerCase ? key.toLowerCase() : key;
    };

    const getFeatureValue = feature => {
      return valuesMap[getFeatureKey(feature)];
    };

    const getFeatureCircleValue = feature => {
      return valuesCircleMap[getFeatureKey(feature)];
    };

    const rowByFeatureKey = new Map(rows.map(row => [getRowKey(row), row]));
    const getFeatureClickObject = (row, feature) =>
      row == null
        ? // This branch lets you click on empty regions. We use in dashboard cross-filtering.
          {
            value: null,
            column: cols[metricIndex],
            dimensions: [],
            data: feature
              ? [
                  {
                    value: getFeatureKey(feature, { lowerCase: false }),
                    col: cols[dimensionIndex],
                  },
                ]
              : [],
            origin: { row, cols },
            settings,
          }
        : {
            value: row[metricIndex],
            column: cols[metricIndex],
            dimensions: [
              {
                value: row[dimensionIndex],
                column: cols[dimensionIndex],
              },
            ],
            data: row.map((value, index) => ({
              value:
                index === dimensionIndex
                  ? feature != null
                    ? getFeatureName(feature)
                    : row[dimensionIndex]
                  : value,
              // We set clickBehaviorValue to the raw data value for use in a filter via crossfiltering.
              // `value` above is used in the tool tips so it needs to use `getFeatureName`.
              clickBehaviorValue: value,
              col: cols[index],
            })),
            origin: { row, cols },
            settings,
          };

    const isClickable = onVisualizationClick != null;

    const onClickFeature =
      isClickable &&
      (click => {
        if (visualizationIsClickable(getFeatureClickObject(rows[0]))) {
          const featureKey = getFeatureKey(click.feature);
          const row = rowByFeatureKey.get(featureKey);
          if (onVisualizationClick) {
            onVisualizationClick({
              ...getFeatureClickObject(row, click.feature),
              event: click.event,
            });
          }
        }
      });
    const onHoverFeature =
      onHoverChange &&
      (hover => {
        const row = hover && rowByFeatureKey.get(getFeatureKey(hover.feature));
        if (row && onHoverChange) {
          onHoverChange({
            ...getFeatureClickObject(row, hover.feature),
            event: hover.event,
          });
        } else if (onHoverChange) {
          onHoverChange(null);
        }
      });

    const colorGetter = settings["map._circle_color_getter"];
    const circleColorGetter = feature => {
      const row = rowByFeatureKey.get(getFeatureKey(feature));
      return (colorGetter && colorGetter(row)) || null;
    };

    const valuesMap = {};
    const valuesCircleMap = {};

    for (const row of rows) {
      const key = getRowKey(row);
      const value = getRowValue(row);
      const valueCircle = getRowCircleValue(row);
      valuesCircleMap[key] = (valuesCircleMap[key] || 0) + valueCircle;
      valuesMap[key] = (valuesMap[key] || 0) + value;
    }
    const domainSet = new Set(Object.values(valuesMap));
    const domain = Array.from(domainSet);

    const domainCircleSet = new Set(Object.values(valuesCircleMap));
    const domainCircle = Array.from(domainCircleSet);

    const _heatMapColors = settings["map.colors"] || HEAT_MAP_COLORS;
    let heatMapColors = _heatMapColors.slice(-domain.length);

    const groups = ss.ckmeans(domain, heatMapColors.length);
    const groupBoundaries = groups.slice(1).map(cluster => cluster[0]);

    const colorScale = d3.scale
      .threshold()
      .domain(groupBoundaries)
      .range(heatMapColors);

    const arrOfRadius = Object.values(valuesCircleMap);
    const minRadius = Math.min(...arrOfRadius),
      maxRadius = Math.max(...arrOfRadius);

    const minStepRadius = settings["map.min_circle_step_radius"] || 20000;
    const maxStepRadius = settings["map.max_circle_step_radius"] || 80000;

    const radiusScale = d3.scale
      .sqrt()
      .domain([minRadius, maxRadius])
      .range([minStepRadius, maxStepRadius]);

    const columnSettings = settings.column(cols[metricIndex]);
    let legendTitles = getLegendTitles(groups, columnSettings);

    const getShowCircle = settings["map.show_circle"] || false;

    const getColorCircle = settings["map.color_circle"] || "blue";
    const getTitleCircle = settings["map.metric_circle"] || false;
    const getTitle = settings["map.metric"] || false;

    const titlesMain = cols.filter(col => col?.name === getTitle);
    const titlesCircle = cols.filter(col => col?.name === getTitleCircle);

    let titleMap = getTitle;
    let titleCircle = getTitleCircle;

    if (titlesMain.length > 0) {
      titleMap = titlesMain[0].display_name;
    }
    if (titlesCircle.length > 0) {
      titleCircle = titlesCircle[0].display_name;
    }

    let legendSizes = [
      0.1, // for empty title doth
      ...legendTitles.map(el => null),
    ];

    const legendSizesDefaultValues = [
      radiusScale(maxRadius),
      (radiusScale(maxRadius) + radiusScale(minRadius)) / 1.6,
      (radiusScale(maxRadius) + radiusScale(minRadius)) / 2,
      (radiusScale(maxRadius) + radiusScale(minRadius)) / 2.5,
      radiusScale(minRadius),
    ];

    const legendSizesDefault = legendSizesDefaultValues.map(el =>
      Math.abs((el / (this.state.metersPerPx - 800)) * 2),
    );

    if (getShowCircle && metricCircleIndex !== -1) {
      let groupsCircle = [];
      try {
        groupsCircle = ss.ckmeans(domainCircle, heatMapColors.length);
      } catch (error) {
        console.error(error);
      }
      const legendSubTitles = getLegendTitles(
        groupsCircle,
        columnSettings,
      ).reverse();

      heatMapColors = [
        "#2E353B",
        ...heatMapColors,
        getColorCircle,
        ...legendSizesDefault.map(el => getColorCircle),
      ];
      legendTitles = [
        titleMap,
        ...legendTitles,
        titleCircle,
        ...legendSubTitles,
      ];
      legendSizes = [...legendSizes, 0.1, ...legendSizesDefault];
    }

    const setStateMap = metersPerPx => {
      if (metersPerPx) {
        this.setState({ metersPerPx });
      }
    };

    const getColor = feature => {
      const value = getFeatureValue(feature);
      return value == null ? HEAT_MAP_ZERO_COLOR : colorScale(value);
    };

    const getRadius = feature => {
      const value = getFeatureCircleValue(feature);
      return value == null ? HEAT_MAP_ZERO_RADIUS : radiusScale(value);
    };

    let aspectRatio;
    if (projection) {
      const [[minX, minY], [maxX, maxY]] = projectionFrame.map(projection);
      aspectRatio = (maxX - minX) / (maxY - minY);
    } else {
      aspectRatio =
        (minimalBounds.getEast() - minimalBounds.getWest()) /
        (minimalBounds.getNorth() - minimalBounds.getSouth());
    }

    return (
      <ChartWithLegend
        className={className}
        aspectRatio={aspectRatio}
        legendTitles={legendTitles}
        legendColors={heatMapColors}
        legendSizes={legendSizes}
        gridSize={gridSize}
        hovered={hovered}
        onHoverChange={onHoverChange}
        isDashboard={this.props.isDashboard}
      >
        {projection ? (
          <LegacyChoropleth
            series={series}
            geoJson={geoJson}
            getColor={getColor}
            onHoverFeature={onHoverFeature}
            onClickFeature={onClickFeature}
            projection={projection}
            projectionFrame={projectionFrame}
            onRenderError={this.props.onRenderError}
          />
        ) : (
          <LeafletChoropleth
            circleColorGetter={circleColorGetter}
            series={series}
            geoJson={geoJson}
            getColor={getColor}
            getRadius={getRadius}
            onHoverFeature={onHoverFeature}
            onClickFeature={onClickFeature}
            minimalBounds={minimalBounds}
            onRenderError={this.props.onRenderError}
            setStateMap={setStateMap}
          />
        )}
      </ChartWithLegend>
    );
  }
}
