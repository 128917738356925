import { t } from "ttag";
import { updateIn } from "icepick";
import {
  PLUGIN_AUTH_PROVIDERS,
  PLUGIN_ADMIN_SETTINGS_UPDATES,
  PLUGIN_IS_PASSWORD_USER,
} from "metabase/plugins";
import MetabaseSettings from "metabase/lib/settings";
import {
  OpenIdAuthCard,
  OpenIdAuthForm,
} from "metabase/admin/settings/auth/containers/OpenIdAuth";

PLUGIN_AUTH_PROVIDERS.push(providers => {
  const openIDProvider = {
    name: "openID",
    // circular dependencies
    Button: require("metabase/auth/containers/OpenIDButton").default,
  };

  return MetabaseSettings.isOpenIDAuthEnabled()
    ? [openIDProvider, ...providers]
    : providers;
});

PLUGIN_ADMIN_SETTINGS_UPDATES.push(
  sections =>
    updateIn(sections, ["authentication", "settings"], settings => [
      ...settings,
      {
        key: "openid-auth-enabled",
        description: null,
        noHeader: true,
        placeholders: null,
        widget: OpenIdAuthCard,
      },
    ]),
  sections => ({
    ...sections,
    "authentication/openid": {
      component: OpenIdAuthForm,
      settings: [
        {
          key: "openid-auth-enabled",
          display_name: t`OpenID Authentication`,
          type: "boolean",
          required: true,
          autoFocus: true,
        },
        {
          key: "openid-auth-server",
          display_name: t`OpenID Authentication Server`,
          placeholder: null,
          type: "string",
          required: true,
          autoFocus: true,
        },
        {
          key: "openid-auth-client-id",
          display_name: t`OpenID client ID`,
          placeholder: null,
          type: "string",
          required: true,
          autoFocus: true,
        },
      ],
    },
  }),
);

PLUGIN_IS_PASSWORD_USER.push(user => !user.openid_auth);
