import { useContext, useEffect, useRef, useState } from "react";
import Draggable, { DraggableEventHandler } from "react-draggable";
import { Resizable, ResizableProps } from "react-resizable";
import {
  getBackgroundImageSettings,
  getBackgroundImageSettingsForDragAndDrop,
} from "metabase/visualizations/shared/utils/backgroundImageSettings";
import { ChartSettingsContext } from "../../ChartSettings";
import {
  ChartBackgroundImageSettingsWrapper,
  ImageCardWrapper,
  DragSection,
  ImageBlock,
} from "./ChartBackgroundImageSettings.styled";
import "./reactResizableStyles.css";

function isNotEmpty(value: any): boolean {
  return value !== null && value !== undefined;
}
interface ChartSettingsContext {
  elementWithSvg: HTMLElement | null;
  setElementWithSvgRef: (ref: HTMLElement | null) => void;
  blockWithPointsId: string;
  blockWrapperId: string;
  blockWithBgImageId: string;
  setSelectedPoint: (point: { x: number; y: number } | null) => void;
  selectedPoint: { x: number; y: number };
}

type TSettings = Record<string, string | number | boolean>;

interface Props {
  settings: TSettings;
  handleChangeSettings: (settings: TSettings) => void;
}

type Point = {
  x: number;
  y: number;
};

const DRAG_ELEMENT_CLASS_NAME = "backgroun-image-drag-handle-section";

const DEFAULT_IMAGE_SIZE = 200;

export const ChartBackgroundImageSettings = ({
  settings,
  handleChangeSettings,
}: Props) => {
  const [backgroudImageSize, setBackgroudImageSize] = useState({
    width: DEFAULT_IMAGE_SIZE,
    height: DEFAULT_IMAGE_SIZE,
  });
  const [backgroundImagePosition, setBackgroundImagePosition] = useState({
    x: 0,
    y: 0,
  });

  const [wrapperBlockSize, setWrapperBlockSize] = useState({
    width: DEFAULT_IMAGE_SIZE,
    height: DEFAULT_IMAGE_SIZE,
  });
  const wrapperBlockRef = useRef<HTMLDivElement | null>(null);

  const [isImageReady, setIsImageReady] = useState(false);

  const settingsContext =
    useContext<ChartSettingsContext>(ChartSettingsContext);

  const [clickedPoint, setClickedPoint] = useState<Point | null>(null);

  const widthRelative =
    (settings["card.background_image_drag_and_drop_width"] as number) || 0;
  const heightRelative =
    (settings["card.background_image_drag_and_drop_height"] as number) || 0;

  const leftPositionRelative =
    (settings["card.background_image_drag_and_drop_position_left"] as number) ||
    0;
  const topPositionRelative =
    (settings["card.background_image_drag_and_drop_position_top"] as number) ||
    0;

  const imageStyle = getBackgroundImageSettings(settings) as TSettings;

  const backgroundImagePositionToSnapVerticalSettings =
    (settings["card.background_image_position_to_snap_vertical"] as number) ||
    0;
  const backgroundImagePositionToSnapVertical =
    backgroundImagePositionToSnapVerticalSettings > 100
      ? 100
      : backgroundImagePositionToSnapVerticalSettings < 0
      ? 0
      : backgroundImagePositionToSnapVerticalSettings;

  const backgroundImagePositionToSnapHorizontalSettings =
    (settings["card.background_image_position_to_snap_horizontal"] as number) ||
    0;
  const backgroundImagePositionToSnapHorizontal =
    backgroundImagePositionToSnapHorizontalSettings > 100
      ? 100
      : backgroundImagePositionToSnapHorizontalSettings < 0
      ? 0
      : backgroundImagePositionToSnapHorizontalSettings;

  const backgroundImageOverCardSettins = settings[
    "card.background_image_over_card"
  ] as boolean;
  const backgroundImageOverCard = backgroundImageOverCardSettins ?? true;
  const zIndex = backgroundImageOverCard ? 2 : 0;

  const backgroundImageSnapToPoint = settings[
    "card.background_image_snap_to_point"
  ] as boolean;
  const isDraggable = backgroundImageSnapToPoint ? false : true;

  const onResizeStop: ResizableProps["onResizeStop"] = (_, { size }) => {
    const element = settingsContext.elementWithSvg;
    if (element) {
      const mapSvg = element.querySelector("g");
      if (mapSvg) {
        const { width, height } = mapSvg.getBBox();
        handleChangeSettings({
          ["card.background_image_drag_and_drop_height"]: size.height / height,
          ["card.background_image_drag_and_drop_width"]: size.width / width,
        });
      }
    }
  };

  const onResize: ResizableProps["onResizeStop"] = (_, { size }) => {
    setBackgroudImageSize({ width: size.width, height: size.height });
  };

  const onDragStop: DraggableEventHandler = (_, data) => {
    const mapSvgWrapper = settingsContext.elementWithSvg;
    if (mapSvgWrapper) {
      const mapSvg = mapSvgWrapper.querySelector("g");
      if (mapSvg) {
        const { width, height } = mapSvg.getBBox();
        const svgRect = mapSvg.getBoundingClientRect();
        const blockWithDragArea = data.node.getBoundingClientRect();
        const leftDiff = blockWithDragArea.left - svgRect.left;
        const topDiff = blockWithDragArea.top - svgRect.top;

        handleChangeSettings({
          ["card.background_image_drag_and_drop_position_left"]:
            leftDiff / width,
          ["card.background_image_drag_and_drop_position_top"]:
            topDiff / height,
          ...(settings["card.background_image_drag_and_drop_height"] ===
            undefined &&
          settings["card.background_image_drag_and_drop_width"] === undefined
            ? {
                ["card.background_image_drag_and_drop_height"]:
                  backgroudImageSize.height / height,
                ["card.background_image_drag_and_drop_width"]:
                  backgroudImageSize.width / width,
              }
            : {}),
        });
      }
    }
    setBackgroundImagePosition({ x: data.x, y: data.y });
  };

  useEffect(() => {
    if (wrapperBlockRef && wrapperBlockRef.current) {
      setWrapperBlockSize({
        width: wrapperBlockRef.current.clientWidth,
        height: wrapperBlockRef.current.clientHeight,
      });
    }
  }, []);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      const mapSvgWrapper = settingsContext.elementWithSvg;

      const point = settingsContext.selectedPoint;
      if (!clickedPoint) {
        setClickedPoint(point);
      }
      if (mapSvgWrapper) {
        const mapSvg = mapSvgWrapper.querySelector("g");
        if (mapSvg) {
          const { width, height } = mapSvg.getBBox();

          if (widthRelative && heightRelative) {
            setBackgroudImageSize({
              width: widthRelative * width,
              height: heightRelative * height,
            });
          } else {
            const aspectRatio = img.width / img.height;
            const newHeight = DEFAULT_IMAGE_SIZE / aspectRatio;
            setBackgroudImageSize({
              width: DEFAULT_IMAGE_SIZE,
              height: newHeight,
            });
          }

          if (
            isNotEmpty(topPositionRelative) &&
            isNotEmpty(leftPositionRelative)
          ) {
            if (wrapperBlockRef && wrapperBlockRef.current) {
              const svgRect = mapSvg.getBoundingClientRect();
              const wrapperBlockRect =
                wrapperBlockRef.current.getBoundingClientRect();

              if (
                clickedPoint?.x !== point?.x ||
                clickedPoint?.y !== point?.y
              ) {
                setClickedPoint(point);
                setBackgroundImagePosition({
                  x: svgRect.left - wrapperBlockRect?.left + point.x,
                  y: svgRect.top - wrapperBlockRect?.top + point.y,
                });

                handleChangeSettings({
                  ["card.background_image_drag_and_drop_position_left"]:
                    (point.x -
                      (widthRelative *
                        width *
                        backgroundImagePositionToSnapHorizontal) /
                        100) /
                    width,
                  ["card.background_image_drag_and_drop_position_top"]:
                    (point.y -
                      (heightRelative *
                        height *
                        backgroundImagePositionToSnapVertical) /
                        100) /
                    height,
                });
                setIsImageReady(true);
              } else {
                setBackgroundImagePosition({
                  x:
                    leftPositionRelative * width +
                    svgRect.left -
                    wrapperBlockRect?.left,
                  y:
                    topPositionRelative * height +
                    svgRect.top -
                    wrapperBlockRect?.top,
                });
                setIsImageReady(true);
              }
            }
          }
        }
      } else {
        const aspectRatio = img.width / img.height;
        const newHeight = DEFAULT_IMAGE_SIZE / aspectRatio;
        setBackgroudImageSize({ width: DEFAULT_IMAGE_SIZE, height: newHeight });
      }
      setIsImageReady(true);
    };
    img.src =
      (getBackgroundImageSettingsForDragAndDrop(settings)
        .backgroundImage as string) || "";
  }, [
    backgroundImagePositionToSnapHorizontal,
    backgroundImagePositionToSnapVertical,
    clickedPoint,
    handleChangeSettings,
    heightRelative,
    leftPositionRelative,
    settings,
    settingsContext.elementWithSvg,
    settingsContext.selectedPoint,
    topPositionRelative,
    widthRelative,
  ]);

  return (
    <ChartBackgroundImageSettingsWrapper ref={wrapperBlockRef} zIndex={zIndex}>
      {isImageReady && (
        <Draggable
          position={backgroundImagePosition}
          bounds={"parent"}
          handle={`.${DRAG_ELEMENT_CLASS_NAME}`}
          onStop={onDragStop}
        >
          <Resizable
            height={backgroudImageSize.height}
            width={backgroudImageSize.width}
            onResizeStop={onResizeStop}
            onResize={onResize}
            maxConstraints={[wrapperBlockSize.width, wrapperBlockSize.height]}
          >
            <ImageCardWrapper
              width={backgroudImageSize.width}
              height={backgroudImageSize.height}
            >
              <DragSection
                className={DRAG_ELEMENT_CLASS_NAME}
                isInteractive={isDraggable}
              ></DragSection>
              <ImageBlock style={imageStyle}></ImageBlock>
            </ImageCardWrapper>
          </Resizable>
        </Draggable>
      )}
    </ChartBackgroundImageSettingsWrapper>
  );
};
