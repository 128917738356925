import type { WidgetSetting } from "metabase-types/api/widgets/widget";

import type {
  DashboardVisualizationSettings,
  DashcardVisualizationSettings} from "./defaultSettings";
import {
  DASHBOARD_DEFAULT_VISUALIZATION_SETTINGS,
  DASHCARD_VISUALIZATION_SETTINGS
} from "./defaultSettings";
import type { FunnelSettings } from "./funnelSettings";
import { FUNNEL_SETTINGS } from "./funnelSettings";
import type { GraphSettings } from "./graphSettings";
import { GRAPH_SETTINGS } from "./graphSettings";
import type { MapSettings } from "./mapSettings";
import { MAP_SETTINGS } from "./mapSettings";
import type { PieSettings } from "./pieSettings";
import { PIE_SETTINGS } from "./pieSettings";
import type {
  PivotTableSettings,
  PivotTableSettingValues} from "./pivotTableSettings";
import {
  PIVOT_TABLE_SETTINGS
} from "./pivotTableSettings";
import type {
  ProgressGraphSettings} from "./progressSettings";
import {
  PROGRESS_GRAPH_SETTINGS
} from "./progressSettings";
import type { ScalarSettings } from "./scalarSettings";
import { SCALAR_SETTINGS } from "./scalarSettings";
import type {
  TableSettings,
  TableSettingsValues} from "./tableSettings";
import {
  TABLE_SETTINGS
} from "./tableSettings";
import {
  WATERFALL_COMMON_SETTINGS,
  WATERFALL_SETTINGS,
} from "./waterfallChart";

export type WidgetsNamesAll =
  | "scalar"
  | "smartscalar"
  | "progress"
  | "gauge"
  | "table"
  | "text"
  | "link"
  | "line"
  | "area"
  | "bar"
  | "waterfall"
  | "combo"
  | "row"
  | "scatter"
  | "pie"
  | "map"
  | "funnel"
  | "object"
  | "pivot"
  | "action"
  | "heading";

export type Widgets = {
  pivot: PivotTableSettings;
  table: TableSettings;
  bar: GraphSettings;
  line: GraphSettings;
  row: PieSettings;
  area: GraphSettings;
  combo: GraphSettings;
  map: MapSettings;
  scatter: GraphSettings;
  waterfall: GraphSettings;
  pie: PieSettings;
  smartscalar: ScalarSettings;
  progress: ProgressGraphSettings;
  funnel: FunnelSettings;
  card: DashcardVisualizationSettings;
  dashboard: DashboardVisualizationSettings;
};

export const WIDGETS_SETTINGS_DATA = {
  ...GRAPH_SETTINGS,
  ...PIVOT_TABLE_SETTINGS,
  ...TABLE_SETTINGS,
  ...PIE_SETTINGS,
  ...MAP_SETTINGS,
  ...WATERFALL_COMMON_SETTINGS,
  ...SCALAR_SETTINGS,
  ...PROGRESS_GRAPH_SETTINGS,
  ...FUNNEL_SETTINGS,
  ...DASHCARD_VISUALIZATION_SETTINGS,
  ...DASHBOARD_DEFAULT_VISUALIZATION_SETTINGS,
};

export const WIDGETS_SETTINGS: {
  [K in keyof Widgets]: Record<string, WidgetSetting>;
} = {
  pivot: PIVOT_TABLE_SETTINGS,
  table: TABLE_SETTINGS,
  bar: GRAPH_SETTINGS,
  line: GRAPH_SETTINGS,
  row: PIE_SETTINGS,
  area: GRAPH_SETTINGS,
  combo: GRAPH_SETTINGS,
  map: MAP_SETTINGS,
  scatter: GRAPH_SETTINGS,
  waterfall: WATERFALL_SETTINGS,
  pie: PIE_SETTINGS,
  smartscalar: SCALAR_SETTINGS,
  progress: PROGRESS_GRAPH_SETTINGS,
  funnel: FUNNEL_SETTINGS,
  card: DASHCARD_VISUALIZATION_SETTINGS,
  dashboard: DASHBOARD_DEFAULT_VISUALIZATION_SETTINGS,
};

export type WidgetsNames = keyof Widgets;
type WidgetSettings = Widgets[keyof Widgets];

type KeysOfWidgetSettingsWithoutUndefined<T> = {
  [K in keyof T]: undefined extends T[K] ? never : K;
}[keyof T];

type KeysOfWidgetsSettings<T> = T extends Record<string, any>
  ? KeysOfWidgetSettingsWithoutUndefined<T>
  : never;

export type VisualizationSettigsKeysOfAllWidgets =
  KeysOfWidgetsSettings<WidgetSettings>;

export type WidgetsVisualizationSettings =
  | PivotTableSettingValues
  | TableSettingsValues;
