import { t } from "ttag";

import { color } from "metabase/lib/colors";
import type { WidgetSettings } from "metabase-types/api/widgets/widget";

import {
  GRAPH_AXIS_COMMON_SETTINGS,
  GRAPH_DATA_COMMON_SETTINGS,
  GRAPH_DISPLAY_VALUES_COMMON_SETTINGS,
} from "./graphSettings";

type WaterfallSettingsKeys =
  | "waterfall.increase_color"
  | "waterfall.decrease_color"
  | "waterfall.show_total"
  | "waterfall.total_color";

export type WaterfallSettings = WidgetSettings<WaterfallSettingsKeys>;

export const WATERFALL_COMMON_SETTINGS: WaterfallSettings = {
  "waterfall.increase_color": {
    section: t`Display`,
    title: t`Increase color`,
    widget: "color",
    getDefault: () => color("accent1"),
  },
  "waterfall.decrease_color": {
    section: t`Display`,
    title: t`Decrease color`,
    widget: "color",
    getDefault: () => color("accent3"),
  },
  "waterfall.show_total": {
    section: t`Display`,
    title: t`Show total`,
    widget: "toggle",
    default: true,
    inline: true,
  },
  "waterfall.total_color": {
    section: t`Display`,
    title: t`Total color`,
    widget: "color",
    default: color("text-dark"),
    getHidden: (series, vizSettings) =>
      vizSettings["waterfall.show_total"] !== true,
    readDependencies: ["waterfall.show_total"],
  },
};

export const WATERFALL_SETTINGS = {
  ...GRAPH_DISPLAY_VALUES_COMMON_SETTINGS,
  ...GRAPH_DATA_COMMON_SETTINGS,
  ...GRAPH_AXIS_COMMON_SETTINGS,
  ...WATERFALL_COMMON_SETTINGS,
};
